import { cloneDeep } from "lodash";

import {
  ChartAxisDataFields,
  ChartDataFields,
  IChartTypeInfo,
} from "../../../types/charts/chartType/chartType";
import { UnitOfMeasure } from "../../../types/common/appMode/appMode";
import { DefaultLayout } from "../../../types/modularity/layout";
import {
  SavedChartAxisDataFields,
  SavedDashboardChartConfiguration,
  SavedModulesConfiguration,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { UNIT_OF_MEASURE } from "../../../constants/appHeader/unitOfMeasure";
import {
  CHART_AXIS,
  CHART_GROUP_BY_TYPE,
  CHART_TYPES,
} from "../../../constants/charts/charts";
import { PRODUCTION_DATA_FIELDS_LIST } from "../../../constants/charts/productionPlotDataFields";
import {
  SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_RECORDS_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_RECORDS_Y_AXIS_ATTRIBUTES,
} from "../../../constants/charts/scatterPlotDataFields";
import { PRODUCTION_PLOT, TYPE_CURVE } from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useChartStore from "../../../store/chart/chartStore";
import useAppModeStore from "../../../store/common/appModeStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { getTopDataField } from "../../../utils/charts/sorting";

import useScatterPlotChartReset from "../../charts/useScatterPlotChartReset";
import useLoadMapConfigs from "./useLoadMapConfigs";

const useLoadConfigs = () => {
  const updateChartData = useChartStore((state) => state.updateChartData);
  const loadSavedSearchDashboardData = useChartStore(
    (state) => state.loadSavedSearchDashboardData
  );
  const revertDashboardDataAttributeDefaultValue = useChartStore(
    (state) => state.revertDashboardDataAttributeDefaultValue
  );

  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );

  const updateModules = useModularityStore((state) => state.updateModules);
  const updateChartDefaultLayout = useModularityStore(
    (state) => state.updateChartDefaultLayout
  );
  const updateChartFullScreenLayout = useModularityStore(
    (state) => state.updateChartFullScreenLayout
  );

  //Unit of Measure
  const UoMValueOnSearch = useAppModeStore((state) => state.UoMValueOnSearch);
  const updateUoMValueOnSelection = useAppModeStore(
    (state) => state.updateUoMValueOnSelection
  );
  const updateUoMValueOnSearch = useAppModeStore(
    (state) => state.updateUoMValueOnSearch
  );
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { getScatterPlotDefaultByRecordType } = useScatterPlotChartReset();
  const { loadMapConfigs } = useLoadMapConfigs();

  const loadModulesConfigs = (modulesConfig?: SavedModulesConfiguration) => {
    if (!modulesConfig) {
      updateChartDefaultLayout([]);
      updateChartFullScreenLayout([]);
      updateModules([]);
      updateChartData([]);
      toggleAnalysisPanel(false);
      return;
    }

    const { layout, modules, chartData } = modulesConfig;
    let toggleAnalysisPane = false;

    if ("default" in layout && "fullScreen" in layout) {
      toggleAnalysisPane = Boolean(layout.default.length);
      updateChartDefaultLayout(layout.default || []);
      updateChartFullScreenLayout(layout.fullScreen || []);
    } else {
      //For old saved searches
      toggleAnalysisPane = Boolean(layout?.length);

      const defaultLayout: DefaultLayout[] = [];

      const fullScreenLayout: DefaultLayout[] = [];

      layout.forEach((chartLayout) => {
        const fullScreenLayoutObj: DefaultLayout = {
          type: chartLayout.type,
          i: chartLayout.i,
          x: 0,
          y: fullScreenLayout.length * 2,
          w: 9,
          h: 2,
          minW: 4,
          maxW: 9,
          minH: 2,
        };

        fullScreenLayout.push(fullScreenLayoutObj);

        const defaultLayoutObj: DefaultLayout = {
          type: chartLayout.type,
          i: chartLayout.i,
          x: 0,
          y: fullScreenLayout.length * 2,
          w: 2.88,
          h: 2,
          minW: 2.88,
          maxW: 2.88,
          minH: 2,
        };
        defaultLayout.push(defaultLayoutObj);
      });

      updateChartDefaultLayout(defaultLayout);
      updateChartFullScreenLayout(fullScreenLayout);
    }

    toggleAnalysisPanel(toggleAnalysisPane);
    updateModules(modules || []);
    const copiedChartData = cloneDeep(chartData);
    const mappedData = copiedChartData.map((data) => {
      switch (data.chartType) {
        case CHART_TYPES.PRODUCTION_PLOT:
          data.objectType = CHART_TYPES.PRODUCTION_PLOT;
          break;
        case CHART_TYPES.TYPE_CURVE:
          data.objectType = CHART_TYPES.TYPE_CURVE;
          break;
        case CHART_TYPES.SCATTER_PLOT:
          data.objectType = CHART_TYPES.SCATTER_PLOT;
          break;
        case CHART_TYPES.CASH_FLOW:
          data.objectType = CHART_TYPES.CASH_FLOW;
          break;
      }

      if (
        data.objectType === CHART_TYPES.PRODUCTION_PLOT ||
        data.objectType === CHART_TYPES.TYPE_CURVE
      ) {
        const savedDataFieldList =
          data.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
            ? data.chartDisplayedDataFields
            : getTopDataField(
                data.chartDisplayedDataFields,
                PRODUCTION_DATA_FIELDS_LIST
              );

        data.chartDisplayedDataFields = (
          savedDataFieldList as ChartDataFields[]
        ).reduce((result: ChartDataFields[], field: ChartDataFields) => {
          if (
            data.chartType === PRODUCTION_PLOT ||
            data.chartType === TYPE_CURVE
          ) {
            const fieldInfo = PRODUCTION_DATA_FIELDS_LIST.find(
              (info) =>
                info.name === field.name &&
                info.accumMethod === field.accumMethod
            );
            if (fieldInfo) result.push(fieldInfo);
          }
          return result;
        }, []);
      } else if (data.objectType === CHART_TYPES.SCATTER_PLOT) {
        data.chartXAxisDataFields = SCATTER_PLOT_RECORDS_X_AXIS_ATTRIBUTES.find(
          (dataField) => dataField.name === data.chartXAxisDataFields?.name
        );
        data.chartYAxisDataFields = data.chartYAxisDataFields.reduce(
          (result: ChartAxisDataFields[], field: SavedChartAxisDataFields) => {
            const fieldInfo = SCATTER_PLOT_RECORDS_Y_AXIS_ATTRIBUTES.find(
              (info) => info.name === field?.name
            );

            if (fieldInfo) {
              fieldInfo.axis = field?.axis ?? CHART_AXIS.LEFT; //backward compatiblity for old save searches to default the selected attributes on left axis
              result.push(fieldInfo);
            }

            return result;
          },
          []
        );

        if (!data.chartAxis) {
          const defaultAxis = getScatterPlotDefaultByRecordType(
            RECORD_TYPES.WELL
          )?.axis;
          if (defaultAxis) data.chartAxis = defaultAxis; //backward compatiblity for old save searches to have a default y axis
        }

        if (data.chartColorBy) {
          const dataType = SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES.find(
            (attr) => attr.key === data.chartColorBy?.attributeKey
          )?.dataType;
          data.chartColorBy = {
            ...data.chartColorBy,
            attibuteDataType: dataType,
          };
        }
      } else if (data.objectType === CHART_TYPES.ASSET_ECONOMICS) {
        if (data.economicsData) data.economicsData = undefined;
      }

      return data;
    }) as IChartTypeInfo[];

    updateChartData(mappedData || []);
  };

  const loadDashboardChartConfigs = (
    dashboardConfig?: SavedDashboardChartConfiguration
  ) => {
    if (!dashboardConfig?.dashboardChartData?.length) {
      revertDashboardDataAttributeDefaultValue();
      return;
    }
    loadSavedSearchDashboardData(dashboardConfig.dashboardChartData);
  };

  const loadUnitOfMeasure = (setting: UnitOfMeasure) => {
    const val = setting ?? UNIT_OF_MEASURE.IMPERIAL.key;
    if (UoMValueOnSearch !== val)
      updateUoMAlertState({
        message: "The saved search changed your session's unit of measurement",
        severity: "info",
      });
    updateUoMValueOnSearch(val);
    updateUoMValueOnSelection(val);
  };

  return {
    loadModulesConfigs,
    loadMapConfigs,
    loadDashboardChartConfigs,
    loadUnitOfMeasure,
  };
};

export default useLoadConfigs;
