import { GridFilterModel } from "@mui/x-data-grid-premium";

import { RecordType } from "../../types/panels/searchPanel/search";

import { filterRequestFormat, removeFilterFields } from "../../utils/datagrid";

import { clone } from "../../utils";
import useSearchStore from "../search/useSearchStore";
import useDataGridRecordStore from "./useDataGridRecordStore";

export const useGridFilter = (searchRecordType: RecordType) => {
  const searchStore = useSearchStore({
    searchRecordType: searchRecordType,
  });
  const updateSearchCriteria = searchStore(
    (state) => state.updateSearchCriteria
  );
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: searchRecordType,
  });
  const allSelectedFilterAttributes = dataGridRecordStore(
    (state) => state.allSelectedFilterAttributes
  );
  const updateFilterModel = dataGridRecordStore(
    (state) => state.updateFilterModel
  );
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );

  const removeSearchCriteriaFilter = () => {
    updateSearchCriteria({
      filters: [],
    });
  };

  const getMapGridFilterData = (newFilterModel: GridFilterModel) => {
    updateFilterModel(newFilterModel);
    updateGridRecordDataTrigger("filter");
    const filters = clone(
      filterRequestFormat(newFilterModel, searchRecordType)
    );
    if (filters.length) {
      //remove fields (all selected) from filter
      if (allSelectedFilterAttributes.length) {
        const filteredFilters = removeFilterFields(
          allSelectedFilterAttributes,
          filters
        );

        if (filteredFilters.length) {
          updateSearchCriteria({
            filters: filteredFilters,
          });
        } else {
          removeSearchCriteriaFilter();
        }
      } else {
        updateSearchCriteria({
          filters,
        });
      }
    } else {
      removeSearchCriteriaFilter();
    }
  };

  return {
    getMapGridFilterData,
  };
};
