import { useCallback } from "react";

import { GridSortModel } from "@mui/x-data-grid-premium";

import { orderBy } from "lodash";

import { RecordData } from "../../types/common/records";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { getRecordIdByRecordType } from "../../utils/datagrid/getRecordSpecificConstants";

import useSearchStore from "../search/useSearchStore";
import useDataGridRecordStore from "./useDataGridRecordStore";
import { useGridColumn } from "./useGridColumn";
import { useGridData } from "./useGridData";
import useRecordSelectionStore from "./useRecordSelectionStore";

export const useGridSortBySelected = (searchRecordType: RecordType) => {
  const pageLimit = config.searchPageLimit;

  const searchStore = useSearchStore({
    searchRecordType: searchRecordType,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: searchRecordType,
  });
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );

  const selectionStore = useRecordSelectionStore({
    searchRecordType: searchRecordType,
  });
  const selectedIdsKeys = selectionStore((state) => state.selectedIdsKeys);

  const { searchGridData } = useGridData(searchRecordType);
  const { getColumnsPayloadData } = useGridColumn();

  const sortGridBySelected = useCallback(
    (gridData: RecordData[], sortModel: GridSortModel) => {
      const selectedData: RecordData[] = [];
      const nonSelectedData: RecordData[] = [];

      //separate selected by non-selected grid data
      gridData.forEach((data) => {
        const recordKey = getRecordIdByRecordType(data, searchRecordType);
        if (recordKey) {
          if (selectedIdsKeys.includes(recordKey)) {
            selectedData.push(data);
          } else {
            nonSelectedData.push(data);
          }
        }
      });

      if (sortModel && sortModel.length && sortModel[0].sort) {
        return orderBy(
          selectedData,
          [sortModel[0].field],
          [sortModel[0].sort]
        ).concat(nonSelectedData);
      }

      return selectedData.concat(nonSelectedData);
    },
    [selectedIdsKeys]
  );

  const getGridDataForSortBySelected = useCallback(() => {
    updateGridRecordDataTrigger("sortBySelected");

    const columns = getColumnsPayloadData();

    searchGridData({
      ...searchCriteria,
      offset: 0,
      pageLimit: pageLimit,
      columns: columns,
      sort: sortPayload,
      optimizeColumns: true,
    });
  }, [searchCriteria, sortPayload, getColumnsPayloadData]);

  return { sortGridBySelected, getGridDataForSortBySelected };
};
