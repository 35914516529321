import * as attribute from "../../../constants/attributes";

export const columnVisibilityList = {
  __check__: true,
  UWI: true,
  [attribute.COUNTRY.key]: true,
  StateName: true,
  County: true,
  WellName: true,
  WellNumber: true,
  FieldName: true,
  MeasuredDepth: true,
  OperatorName: false,
  WellType: true,
  WellStatus: true,
  ProducingFormation: true,
  Slant: true,
  CompletionDate: true,
  CumOil: true,
  CumGas: true,
  [attribute.PERMIT_NUMBER.key]: false,
  [attribute.PERMIT_APPROVAL_DATE.key]: false,
  [attribute.PERMIT_TOTAL_DEPTH.key]: false,
  BasinName: false,
  CumInjectionGas: false,
  CumInjectionLiquid: false,
  CumWater: false,
  FirstMonth: false,
  LandingZone: false,
  LastMonth: false,
  LateralLength: false,
  [attribute.MAX_GAS.key]: false,
  [attribute.MAX_GAS_PLUS_2.key]: false,
  [attribute.MAX_OIL.key]: false,
  [attribute.MAX_OIL_PLUS_2.key]: false,
  [attribute.MAX_WATER.key]: false,
  [attribute.MAX_WATER_PLUS_2.key]: false,
  [attribute.WATER_CUT_TOTAL_CUM.key]: false,
  [attribute.WATER_OIL_RATIO_TOTAL_CUM.key]: false,
  TotalVerticalDepth: false,
  [attribute.SURFACE_UWI.key]: false,
  //Release 14 Columns 1 of 3
  [attribute.ULTIMATE_OWNER.key]: true,
  OriginalOperator: false,
  LeaseName: false,
  [attribute.LEASE_NAME_AND_ID.key]: false,
  [attribute.PERF_INTERVAL_TOP.key]: false,
  [attribute.PERF_INTERVAL_BOTTOM.key]: false,
  ElevationGround: false,
  ElevationKellyBushing: false,
  LastInjectionType: false,
  LastInjectionFormation: false,
  DispositionType: false,
  [attribute.HAS_PRODUCTION.key]: false,
  [attribute.HAS_FORECAST.key]: false,
  [attribute.TEST.key]: false,
  [attribute.PERF.key]: false,
  HasDisposition: false,
  HasCompletion: false,
  [attribute.HAS_INJECTION.key]: false,
  ActiveInjection: false,
  HasDrillString: false,
  [attribute.HAS_VENT_FLARE.key]: false,
  ActiveVentFlare: false,
  HasReportedTops: false,
  [attribute.OFFSHORE_FLG.key]: false,
  [attribute.HAS_WELL_ECONOMICS.key]: false,
  [attribute.HAS_LAS.key]: false,
  [attribute.HAS_RASTER.key]: false,
  //Release 14 Columns 2 of 3
  StateLeaseID: false,
  StateWellID: false,
  [attribute.TD_DATE.key]: false,
  PlugDate: false,
  DrillingDays: false,
  [attribute.NUMBER_OF_STAGES.key]: false,
  FractureFluidType: false,
  [attribute.FRAC_FLUID_AMOUNT.key]: false,
  ProppantType: false,
  [attribute.PROPPANT_AMOUNT.key]: false,
  MaxTreatmentRate: false,
  MaxTreatmentPressure: false,
  [attribute.ACID_AMOUNT.key]: false,
  TreatmentRemarks: false,
  [attribute.PROPPANT_AMOUNT_PER_FT.key]: false,
  [attribute.FRAC_FLUID_AMOUNT_PER_FT.key]: false,
  BOEMaxPer1000Ft: false,
  [attribute.EUR_PER_FT.key]: false,
  [attribute.SECTION.key]: false,
  Township: false,
  TownshipDirection: false,
  [attribute.RANGE.key]: false,
  [attribute.RANGE_DIRECTION.key]: false,
  QuarterQuarter: false,
  SpudDate: false,
  //Release 14 Columns 3 of 3
  DisplayLocation: false,
  Survey: false,
  [attribute.DISTRICT.key]: false,
  Abstract: false,
  Block: false,
  [attribute.AREA.key]: false,
  [attribute.OFFSHORE_BLOCK.key]: false,
  DistanceNS: false,
  DistanceNSD: false,
  DistanceEW: false,
  DistanceEWD: false,
  [attribute.CUM_BOE.key]: false,
  [attribute.CUM_GOR.key]: false,
  CumVentFlareVol: false,
  WellForecastBOERemaining: false,
  WellForecastBOEUltimate: false,
  WellForecastGasRemaining: false,
  WellForecastGasUltimate: false,
  WellForecastOilRemaining: false,
  WellForecastOilUltimate: false,
  WellForecastWaterRemaining: false,
  WellForecastWaterUltimate: false,
  WellForecastGORUltimate: false,
  WellForecastWaterCutUltimate: false,
  [attribute.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key]: false,
  [attribute.CLOSEST_HORIZONTAL_DISTANCE.key]: false,
  [attribute.CLOSEST_VERTICAL_DISTANCE.key]: false,
  [attribute.COUNT_OF_PARENT_WELLS.key]: false,
  [attribute.COUNT_OF_CHILD_WELLS.key]: false,
  [attribute.COUNT_OF_SIBLING_WELLS.key]: false,
  [attribute.BOE_MAX_PER_FT.key]: false,
  [attribute.OIL_EUR_PER_FT.key]: false,
  [attribute.GAS_EUR_PER_FT.key]: false,
  [attribute.OIL_CUM_PER_FT.key]: false,
  [attribute.GAS_CUM_PER_FT.key]: false,
  [attribute.WATER_CUM_PER_FT.key]: false,
  [attribute.BOE_CUM_PER_FT.key]: false,
  [attribute.BOE_IP90.key]: false,
  BOEIP30: false,
  SurfaceLatitudeNAD83: false,
  SurfaceLongitudeNAD83: false,
  BottomLatitudeNAD83: false,
  BottomLongitudeNAD83: false,
  SurfaceLatitudeNAD27: false,
  SurfaceLongitudeNAD27: false,
  BottomLatitudeNAD27: false,
  BottomLongitudeNAD27: false,
  SurfaceLatitudeEPSG3857: false,
  SurfaceLongitudeEPSG3857: false,
  BottomLatitudeEPSG3857: false,
  BottomLongitudeEPSG3857: false,
  SurfaceLatitudeWGS84: false,
  SurfaceLongitudeWGS84: false,
  BottomLatitudeWGS84: false,
  BottomLongitudeWGS84: false,
  OilAlgorithm: false,
  OilInitialRate: false,
  OilDeclineRate: false,
  OilExponent: false,
  OilStart: false,
  GasAlgorithm: false,
  GasInitialRate: false,
  GasDeclineRate: false,
  GasExponent: false,
  GasStart: false,
  WaterAlgorithm: false,
  WaterInitialRate: false,
  WaterDeclineRate: false,
  WaterExponent: false,
  WaterStart: false,
  Vshale: false,
  Density: false,
  NeutronPorosity: false,
  Resistivity: false,
  Sonic: false,
  [attribute.HAS_DST.key]: false,
  [attribute.HAS_WFT.key]: false,
  //Releease 01-31
  [attribute.RECENT_1MO_GAS.key]: false,
  [attribute.RECENT_1MO_WATER.key]: false,
  [attribute.RECENT_1MO_OIL.key]: false,
  [attribute.RECENT_6MO_GAS.key]: false,
  [attribute.RECENT_6MO_WATER.key]: false,
  [attribute.RECENT_6MO_OIL.key]: false,
  [attribute.RECENT_1YR_GAS.key]: false,
  [attribute.RECENT_1YR_WATER.key]: false,
  [attribute.RECENT_1YR_OIL.key]: false,
  [attribute.LOE_COST.key]: false,
  [attribute.GPT_COST.key]: false,
  [attribute.WATER_COST.key]: false,
  [attribute.FIXED_COST.key]: false,
  [attribute.DRILLING_COMPLETION_CAP_EX.key]: false,
  [attribute.INFRA_CAP_EX.key]: false,
  [attribute.WORKING_INTEREST.key]: false,
  [attribute.ROYALTY.key]: false,
  [attribute.INTERNAL_RATE_OF_RETURN.key]: false,
  [attribute.BREAK_EVEN_PRICE.key]: false,
  [attribute.NET_PRESENT_VALUE.key]: false,
  [attribute.PAYOUT.key]: false,
  [attribute.PERMIT_POSTED_DATE.key]: false,
  PermitType: false,
  [attribute.PERMIT_HAS_H2S.key]: false,
  OperatorContact: false,
  OperatorPhoneNumber: false,
  OperatorAddress: false,
  OperatorCity: false,
  OperatorState: false,
  OperatorZipCode: false,
  [attribute.PERMIT_FORMATION_DEPTH.key]: false,
};
