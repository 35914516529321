import { PickingInfo } from "deck.gl";

import { PopoverVirtualElement } from "@mui/material";

import { RecordData } from "../../types/common/records";
import { Feature } from "../../types/map/layers/carto";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";
import {
  PERMIT_PANEL_RIGHT_CLICK_ACTION,
  WELL_PANEL_RIGHT_CLICK_ACTION,
} from "../../constants/panels/wellPanel/wellPanel";

import useDataGridPermitsStore from "../../store/grid/permits/dataGridPermitsStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

import { displayHoverCardViaMenu } from "../../utils/map/mapHover";
import { mapWellSelection } from "../../utils/map/selection/wellSelection";

const useMapDistinctionMenuEvents = () => {
  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  //Wells
  const addSelectedMapParentWellID = useWellSelectionStore(
    (state) => state.addSelectedMapParentWellID
  );
  const addSelectedBottomWellboreID = useWellSelectionStore(
    (state) => state.addSelectedBottomWellboreID
  );
  const updateWellSelectTrigger = useDataGridWellsStore(
    (state) => state.updateRecordSelectTrigger
  );

  //Permits
  const addMapSelectedPermitIDs = usePermitSelectionStore(
    (state) => state.addMapSelectedIDs
  );
  const updatePermitSelectTrigger = useDataGridPermitsStore(
    (state) => state.updateRecordSelectTrigger
  );

  const toggleLayer = useMapStore((state) => state.toggleLayer);

  const handleOnMouseEnter = (
    anchorEl: PopoverVirtualElement | null,
    popperRef: React.RefObject<HTMLDivElement>,
    info: PickingInfo<Feature>,
    data: RecordData | null,
    idx: number
  ) => {
    if (!anchorEl) return;
    const offset = popperRef.current?.offsetWidth ?? 0;
    displayHoverCardViaMenu(
      info,
      data,
      updateMapHoverData,
      anchorEl,
      idx,
      offset
    );
  };

  const handleOnClick = (info: PickingInfo<Feature>) => {
    mapWellSelection({
      info,
      addSelectedMapParentWellID,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,
      toggleLayer,
      updateWellSelectTrigger,
      updatePermitSelectTrigger,
    });
  };

  const handleOnContextMenu = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    data: RecordData | null,
    info: PickingInfo<Feature>
  ) => {
    e.preventDefault();
    if (data && info.layer && info.object) {
      updateMapHoverData({
        info: data,
        layer: info.layer,
        trigger:
          data.RecordType === RECORD_TYPES.WELL
            ? WELL_PANEL_RIGHT_CLICK_ACTION
            : PERMIT_PANEL_RIGHT_CLICK_ACTION,
      });
    }

    return false;
  };

  return {
    handleOnMouseEnter,
    handleOnClick,
    handleOnContextMenu,
  };
};

export default useMapDistinctionMenuEvents;
