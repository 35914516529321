import { styled } from "@mui/material";
import { GridSlotsComponentsProps } from "@mui/x-data-grid-premium";

import { RecordType } from "../../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../../store/grid/dataGridStore";

import useDataGridRecordStore from "../../../../customHooks/grid/useDataGridRecordStore";

import CustomFilter from "./Filter/Filter";
import MenuHeader from "./MenuHeader";
import PinColumnItem from "./PinColumnItem";
import SortColumnItem from "./SortColumnItem";

declare module "@mui/x-data-grid" {
  interface ColumnMenuPropsOverrides {
    searchRecordType: RecordType;
  }
}

interface GridCustomMenuProp {
  gridHeight: number;
  isFullScreen: boolean;
}

const GridCustomMenu = styled("div")(
  ({ gridHeight, isFullScreen }: GridCustomMenuProp) => ({
    width: 270,
    maxHeight: isFullScreen ? 520 : `calc(100vh - ${65 + gridHeight}px)`,
    paddingTop: 0,
    overflowY: "auto",
  })
);

const CustomColumnMenu = (
  props: GridSlotsComponentsProps["columnMenu"] = {}
) => {
  const { colDef, hideMenu, searchRecordType } = props;

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: searchRecordType ?? RECORD_TYPES.WELL,
  });
  const isFullScreenGrid = dataGridRecordStore(
    (state) => state.isFullScreenGrid
  );
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  return (
    <GridCustomMenu
      gridHeight={parseInt(
        gridHeight.slice(0, gridHeight.length - 2) ?? "350px"
      )}
      isFullScreen={isFullScreenGrid}
    >
      {colDef && searchRecordType && hideMenu && (
        <>
          <MenuHeader searchRecordType={searchRecordType} />
          {/* Sort Options */}
          <SortColumnItem
            hideMenu={hideMenu}
            colDef={colDef}
            searchRecordType={searchRecordType}
          />

          {/* Pin Column Options */}
          <PinColumnItem
            hideMenu={hideMenu}
            colDef={colDef}
            searchRecordType={searchRecordType}
          />

          {/* Custom Filter */}
          <CustomFilter searchRecordType={searchRecordType} />
        </>
      )}
    </GridCustomMenu>
  );
};

export default CustomColumnMenu;
