import { StateCreator } from "zustand";

import {
  DataGridAllWellDataStore,
  DataGridBatchWellDataStore,
  DataGridConcatBatchesWellDataStore,
} from "../../../types/grid";

import { getAllGridDataSelectors } from "../../../utils/datagrid/getAllDataGridSelector";

const dataGridAllWellDataSlice: StateCreator<DataGridAllWellDataStore> = (
  set,
  get
) => ({
  allGridData: [],
  allWellGridDataByUWI: {}, //might not be used
  allWellSelectors: {
    byWellHeaderPermitKey: {},
    byPermitID: {},
    byParentIDandUWI: {},
    byWellID: {},
    groupedByUWID10: {},
    groupedByUWID12: {},
    groupedByParentWellID: {},
    groupedByBottomWellboreID: {},
    bottomWellboreIDByPWID: {},
  },
  updateAllGridData: (allGridData, searchRecordType) => {
    const allWellSelectors = getAllGridDataSelectors(
      allGridData,
      searchRecordType
    );
    return set((state) => ({
      allGridData,
      allWellSelectors,
    }));
  },
  allWellGridDataSuccess: false,
  updateAllWellGridDataSuccess: (allWellGridDataSuccess) =>
    set((state) => ({ allWellGridDataSuccess })),
  allWellGridDataLoading: false,
  updateAllWellGridDataLoading: (allWellGridDataLoading) =>
    set((state) => ({ allWellGridDataLoading })),
  allWellGridDataError: undefined,
  updateAllWellGridDataError: (allWellGridDataError) =>
    set((state) => ({ allWellGridDataError })),
});

const dataGridBatchWellDataSlice: StateCreator<DataGridBatchWellDataStore> = (
  set,
  get
) => ({
  batchWellGridData: [],
  updateBatchWellGridData: (batchWellGridData) =>
    set((state) => ({ batchWellGridData })),
  batchWellGridDataSuccess: false,
  updateBatchWellGridDataSuccess: (batchWellGridDataSuccess) =>
    set((state) => ({ batchWellGridDataSuccess })),
  batchWellGridDataLoading: false,
  updateBatchWellGridDataLoading: (batchWellGridDataLoading) =>
    set((state) => ({ batchWellGridDataLoading })),
  batchWellGridDataError: undefined,
  updateBatchWellGridDataError: (batchWellGridDataError) =>
    set((state) => ({ batchWellGridDataError })),
});

// contains concatenated batch well grid data
const dataGridConcatBatchWellDataSlice: StateCreator<
  DataGridConcatBatchesWellDataStore
> = (set) => ({
  concatBatchesWellGridData: [],
  updateConcatBatchesWellGridData: (concatBatchesWellGridData) =>
    set(() => ({ concatBatchesWellGridData })),
});

export {
  dataGridAllWellDataSlice,
  dataGridBatchWellDataSlice,
  dataGridConcatBatchWellDataSlice,
};
