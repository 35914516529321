import { FC } from "react";

import { Typography } from "@mui/material";

import { RecordType } from "../../../../../types/panels/searchPanel/search";

import FilterCheckbox from "./FilterCheckboxPanel/FilterCheckbox";
import FilterExpressionBuilder from "./FilterExpressionBuilderPanel/FilterExpressBuilder";

const CustomFilter: FC<{ searchRecordType: RecordType }> = ({
  searchRecordType,
}) => {
  return (
    <>
      <div className="grid-menu-header">
        <Typography variant={"body1"}>FILTER</Typography>
      </div>
      <FilterExpressionBuilder searchRecordType={searchRecordType} />
      <FilterCheckbox searchRecordType={searchRecordType} />
    </>
  );
};

export default CustomFilter;
