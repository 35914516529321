import {
  RecordType,
  SearchTypesMapper,
} from "../../../types/panels/searchPanel/search";

import {
  PERMIT_DVT_LAYERS,
  PERMIT_PATH_AND_STICKS,
  PERMIT_SPOTS,
  WELL_DVT_LAYERS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../constants";

export const SEARCH_TYPES: SearchTypesMapper = {
  ATTRIBUTE_SEARCH: "Attribute Search",
  IDENTIFIER_PASTED_SEARCH: "Identifier Search",
  IDENTIFIER_FILE_SEARCH: "File Upload Search",
  IDENTIFIER_CONVERTED_FILE_SEARCH: "Identifier Converted File Search",
};

export const IDENTIFIER_SEARCH_TYPES = [
  SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH,
  SEARCH_TYPES.IDENTIFIER_FILE_SEARCH,
  SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH,
];

export const RECORD_TYPES = {
  WELL: "Well",
  PERMIT: "Permit",
} as const;

export const RECORD_TYPES_LIST: { key: RecordType; displayName: string }[] = [
  { key: RECORD_TYPES.WELL, displayName: "Well" },
  { key: RECORD_TYPES.PERMIT, displayName: "Permit" },
];

// layers to toggle on/off when searching
export const DEFAULT_LAYERS_TO_TOGGLE_ON = {
  [RECORD_TYPES.WELL]: [WELL_SPOTS, WELL_PATH_AND_STICKS],
  [RECORD_TYPES.PERMIT]: [PERMIT_SPOTS, PERMIT_PATH_AND_STICKS],
};
export const LAYERS_TO_TOGGLE_OFF = {
  [RECORD_TYPES.WELL]: WELL_DVT_LAYERS,
  [RECORD_TYPES.PERMIT]: PERMIT_DVT_LAYERS,
};

//Saving for reference when handling old saved searches
// IDENTIFIER_PASTED_SEARCH: "UWI Search",
//   IDENTIFIER_FILE_SEARCH: "File Upload Search",
//   IDENTIFIER_CONVERTED_FILE_SEARCH: "UWI Converted File Search",
