import { FC, useEffect, useState } from "react";

import {
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import { SaveSearchAttributeDialogProps } from "../../../types/modals/saveSearch/saveSearchAttributesDialog";

import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../constants/panels/savedSearchPanel/hooks";

import useSaveSearchStore from "../../../store/saveSearch/saveSearchStore";
import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import useStore from "../../../store/useStore";

import useSavedSearchData from "../../../customHooks/savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";
import useSearchPanelActions from "../../../customHooks/search/useSearchPanelActions";

const SaveSearchAttributesDialog: FC<SaveSearchAttributeDialogProps> = ({
  isFilterIncluded,
}) => {
  const { saveCallback } = useSearchPanelActions();

  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const isOpenSaveSearchDialog = useSaveSearchStore(
    (state) => state.isOpenSaveSearchDialog
  );
  const toggleIsOpenSaveSearchDialog = useSaveSearchStore(
    (state) => state.toggleIsOpenSaveSearchDialog
  );
  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();

  const [saveName, setSaveName] = useState("");

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchDataError,
    createSavedSearch,
  } = useSavedSearchData();

  useEffect(() => {
    if (!savedSearchDataLoading && savedSearchData && !savedSearchDataError) {
      toggleIsOpenSaveSearchDialog();
      setSaveName("");
      updateToastMessage("Saved successfully");
      searchWellsStore.updateIsQBUpdated(false);
      searchPermitsStore.updateIsQBUpdated(false);
    } else if (savedSearchDataError) {
      toggleIsOpenSaveSearchDialog();
      setSaveName("");
      updateToastMessage("This Search Name already exists.");
    }
  }, [savedSearchDataLoading]);

  const handleSaveSearch = async () => {
    createSavedSearch(
      saveName,
      isFilterIncluded,
      false,
      SAVED_SEARCH_VISIBILITY_PRIVATE,
      saveCallback
    );
  };

  const handleCancelSave = () => {
    toggleIsOpenSaveSearchDialog();
    setSaveName("");
  };

  return (
    <Dialog className="save-search-attrib-modal" open={isOpenSaveSearchDialog}>
      <DialogTitle>Save Search</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Provide a name for your search and click on &quot;Save&quot; to save
          your current search. This search will be available in the &quot;Saved
          Search&quot; section.
        </Typography>
        <TextField
          variant="outlined"
          label="Saved Search Name"
          placeholder="My Saved Search"
          value={saveName}
          disabled={savedSearchDataLoading}
          onChange={(e) => setSaveName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancelSave}
          startIcon={<ArrowBackIcon />}
        >
          CANCEL
        </Button>
        <LoadingButton
          loading={savedSearchDataLoading}
          loadingPosition="start"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSaveSearch}
          disabled={!saveName.trim()}
        >
          SAVE
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSearchAttributesDialog;
