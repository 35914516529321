import { useCallback } from "react";

import {
  BaseSearchRequestNoPaginationPayload,
  SearchRequestWithFileID,
  SearchRequestWithUWIs,
} from "../../types/common/api";
import { BaseSearchCriteria } from "../../types/common/search";
import { RecordType } from "../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";

import { getGeometryFromPolygonArray } from "../../data/map/mapUtils";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";

const useSearchRequest = () => {
  // WELL
  const wellSearchCriteria = useSearchWellsStore(
    (state) => state.searchCriteria
  );
  const wellIdentifierSearchUploadedFile = useSearchWellsStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry: getWellIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(RECORD_TYPES.WELL);

  // Permit
  const permitSearchCriteria = useSearchPermitsStore(
    (state) => state.searchCriteria
  );
  const permitIdentifierSearchUploadedFile = useSearchPermitsStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry: getPermitIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(RECORD_TYPES.PERMIT);

  const getFileIdReferenceByRecordType = useCallback(
    (recordType: RecordType) => {
      let fileId = "";
      switch (recordType) {
        case RECORD_TYPES.WELL:
          fileId = wellSearchCriteria.identifier.fileId;
          break;
        case RECORD_TYPES.PERMIT:
          fileId = permitSearchCriteria.identifier.fileId;
          break;

        default:
          fileId = wellSearchCriteria.identifier.fileId;
          break;
      }

      return fileId;
    },
    [permitSearchCriteria, wellSearchCriteria]
  );

  const getFileReferenceByRecordType = useCallback(
    (recordType: RecordType) => {
      let file = null;
      switch (recordType) {
        case RECORD_TYPES.WELL:
          file = wellIdentifierSearchUploadedFile;
          break;
        case RECORD_TYPES.PERMIT:
          file = permitIdentifierSearchUploadedFile;
          break;

        default:
          file = wellIdentifierSearchUploadedFile;
          break;
      }

      return file;
    },
    [wellIdentifierSearchUploadedFile, permitIdentifierSearchUploadedFile]
  );

  const getIdentifierFileRetry = useCallback(
    (recordType: RecordType) => {
      let file = null;
      switch (recordType) {
        case RECORD_TYPES.WELL:
          file = getWellIdentifierFileIdWithRetry;
          break;
        case RECORD_TYPES.PERMIT:
          file = getPermitIdentifierFileIdWithRetry;
          break;

        default:
          file = getWellIdentifierFileIdWithRetry;
          break;
      }

      return file;
    },
    [getWellIdentifierFileIdWithRetry, getPermitIdentifierFileIdWithRetry]
  );
  // Note: This function is used by pre-search and post-search APIs
  const buildSearchRequestByParam = ({
    pSearchCriteria,
    viewport,
  }: {
    pSearchCriteria: BaseSearchCriteria;
    viewport?: any;
  }) => {
    let geometry = null;
    let shapesCriteria = null;
    if (pSearchCriteria.shapeId || pSearchCriteria.drawnPolygons.length) {
      //shape id & drawnPolygon: for polygon search or shape file upload
      if (viewport) {
        geometry = getGeometryFromPolygonArray(
          pSearchCriteria.drawnPolygons,
          viewport
        );
      } else {
        geometry = getGeometryFromPolygonArray(pSearchCriteria.drawnPolygons);
      }

      shapesCriteria = {
        ...(geometry && { geometry: geometry }),
        ...(pSearchCriteria.shapeId && {
          shapeId: pSearchCriteria.shapeId,
        }),
      };
    }

    if (pSearchCriteria.currentBounds.length) {
      const boundsSearchRequest: BaseSearchRequestNoPaginationPayload = {
        bounds: pSearchCriteria.currentBounds,
        ...(shapesCriteria && { ...shapesCriteria }),
        ...(pSearchCriteria.filters?.length && {
          filters: pSearchCriteria.filters,
        }),
      };
      return boundsSearchRequest;
    } else if (pSearchCriteria.identifier.identifiers.length) {
      //TODO: Change and rename Once different Identifier types are introduced
      const identifierSearchRequest: SearchRequestWithUWIs<BaseSearchRequestNoPaginationPayload> =
        {
          uwis: pSearchCriteria.identifier.identifiers,
          ...(shapesCriteria && { ...shapesCriteria }),
          ...(pSearchCriteria.filters?.length && {
            filters: pSearchCriteria.filters,
          }),
        };
      return identifierSearchRequest;
    } else if (pSearchCriteria.identifier.fileId) {
      const identifierFileUploadSearch: SearchRequestWithFileID<BaseSearchRequestNoPaginationPayload> =
        {
          fileId: pSearchCriteria.identifier.fileId,
          ...(shapesCriteria && { ...shapesCriteria }),
          ...(pSearchCriteria.filters?.length && {
            filters: pSearchCriteria.filters,
          }),
        };
      return identifierFileUploadSearch;
    } else if (shapesCriteria) {
      const shapesSearchRequest: BaseSearchRequestNoPaginationPayload = {
        ...shapesCriteria,
        ...(pSearchCriteria.filters?.length && {
          filters: pSearchCriteria.filters,
        }),
      };
      return shapesSearchRequest;
    }
  };

  return {
    buildSearchRequestByParam,
    getFileIdReferenceByRecordType,
    getFileReferenceByRecordType,
    getIdentifierFileRetry,
  };
};

export default useSearchRequest;
