import { useCallback, useMemo } from "react";

import { Accordion } from "component-library";

import {
  CARBON_STORAGE_LABEL,
  ORDERED_CARBON_LAYERS,
} from "../../../constants/map/carbonStorage";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import { clone } from "../../../utils";
import CarbonStorageLegendContent from "./CarbonStorageLegendContent";

const orderedLegendList = (
  clone(ORDERED_CARBON_LAYERS) as {
    key: string;
    label: string;
  }[]
).sort((a, b) => a.label.localeCompare(b.label));

const CarbonStorageLegend = () => {
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );

  const isCarbonStorageSelected = useMemo(
    () => carbonStorageInfo.isSelected,
    [carbonStorageInfo]
  );

  const isBasinSelected = useCallback(
    (basinName: string) => {
      return Boolean(carbonStorageInfo.basins[basinName]?.isSelected);
    },
    [carbonStorageInfo]
  );

  const selectedFormationByBasin = useCallback(
    (basinName: string) => {
      return carbonStorageInfo.basins[basinName]?.formationName;
    },
    [carbonStorageInfo]
  );

  return (
    <Accordion
      title={CARBON_STORAGE_LABEL}
      className="layer-info-accordion"
      isDefaultOpen
    >
      {orderedLegendList.map(
        (basin) =>
          isCarbonStorageSelected &&
          isBasinSelected(basin.key) && (
            <CarbonStorageLegendContent
              key={basin.key}
              basinDisplayName={basin.label}
              formationName={selectedFormationByBasin(basin.key)}
            />
          )
      )}
    </Accordion>
  );
};

export default CarbonStorageLegend;
