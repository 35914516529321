export const changeKey = (columnKey: string) => {
  switch (columnKey) {
    case "ProducingFormation":
      return "DisplayFormation";
    case "LandingZone":
      return "InterpretedProducingFormation";
    case "BOEIP30":
      return "MaxBOE";
    case "SurfaceLatitudeNAD27":
      return "SurfaceLatitude_NAD27";
    case "SurfaceLongitudeNAD27":
      return "SurfaceLongitude_NAD27";
    case "BottomLatitudeNAD27":
      return "BottomLatitude_NAD27";
    case "BottomLongitudeNAD27":
      return "BottomLongitude_NAD27";
    case "SurfaceLatitudeNAD83":
      return "SurfaceLatitude_NAD83";
    case "SurfaceLongitudeNAD83":
      return "SurfaceLongitude_NAD83";
    case "BottomLatitudeNAD83":
      return "BottomLatitude_NAD83";
    case "BottomLongitudeNAD83":
      return "BottomLongitude_NAD83";
    case "SurfaceLatitudeWGS84":
      return "SurfaceLatitude_WGS84";
    case "SurfaceLongitudeWGS84":
      return "SurfaceLongitude_WGS84";
    case "BottomLatitudeWGS84":
      return "BottomLatitude_WGS84";
    case "BottomLongitudeWGS84":
      return "BottomLongitude_WGS84";
    case "SurfaceLatitudeEPSG3857":
      return "SurfaceLatitude_EPSG3857";
    case "SurfaceLongitudeEPSG3857":
      return "SurfaceLongitude_EPSG3857";
    case "BottomLatitudeEPSG3857":
      return "BottomLatitude_EPSG3857";
    case "BottomLongitudeEPSG3857":
      return "BottomLongitude_EPSG3857";
    default:
      return columnKey;
  }
};

export const changeAttributeKey = (attribute: string) => {
  switch (attribute) {
    case "DisplayFormation":
      return "ProducingFormation";
    case "InterpretedProducingFormation":
      return "LandingZone";
    case "MaxBOE":
      return "BOEIP30";
    case "SurfaceLatitude_NAD27":
      return "SurfaceLatitudeNAD27";
    case "SurfaceLongitude_NAD27":
      return "SurfaceLongitudeNAD27";
    case "BottomLatitude_NAD27":
      return "BottomLatitudeNAD27";
    case "BottomLongitude_NAD27":
      return "BottomLongitudeNAD27";
    case "SurfaceLatitude_NAD83":
      return "SurfaceLatitudeNAD83";
    case "SurfaceLongitude_NAD83":
      return "SurfaceLongitudeNAD83";
    case "BottomLatitude_NAD83":
      return "BottomLatitudeNAD83";
    case "BottomLongitude_NAD83":
      return "BottomLongitudeNAD83";
    case "SurfaceLatitude_WGS84":
      return "SurfaceLatitudeWGS84";
    case "SurfaceLongitude_WGS84":
      return "SurfaceLongitudeWGS84";
    case "BottomLatitude_WGS84":
      return "BottomLatitudeWGS84";
    case "BottomLongitude_WGS84":
      return "BottomLongitudeWGS84";
    case "SurfaceLatitude_EPSG3857":
      return "SurfaceLatitudeEPSG3857";
    case "SurfaceLongitude_EPSG3857":
      return "SurfaceLongitudeEPSG3857";
    case "BottomLatitude_EPSG3857":
      return "BottomLatitudeEPSG3857";
    case "BottomLongitude_EPSG3857":
      return "BottomLongitudeEPSG3857";
    default:
      return attribute;
  }
};
