import { FC, useMemo, useRef, useState } from "react";

import { AutocompleteInputChangeReason } from "@mui/base";

import { useDebouncedCallback } from "use-debounce";

import { AttributeBound } from "../../../../../../types/common/attributes";
import { RecordCounts } from "../../../../../../types/common/records";
import { DefaultAttributeProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import {
  IN,
  LK,
} from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useMapDrawStore from "../../../../../../store/map/draw/mapDrawStore";

import useAttributeCountData from "../../../../../../customHooks/common/useAttributeCountData";
import useUnitOfMeasure from "../../../../../../customHooks/common/useUnitOfMeasure";
import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import { getAttributeBounds } from "../../../../../../utils/search/formatter";

import QBTypeAheadComboBox from "./QBAutocomplete";

const QBSpecialAttribute: FC<DefaultAttributeProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
  modifyAttributeBounds,
}) => {
  const { isLoading, getAttributeCount } = useAttributeCountData({
    searchRecordType,
  });
  const { isMetricOnSelection } = useUnitOfMeasure();

  const searchStore = useSearchStore({ searchRecordType });
  const currentBounds = searchStore((state) => state.currentBounds);
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const inputRef = useRef(null);

  const hasReqChars = useMemo(
    () =>
      attribute.reqChars && attributeQBInfo.input.length >= attribute.reqChars,
    [attribute.reqChars, attributeQBInfo.input]
  );
  const attributeOptions = useMemo(
    () => (hasReqChars ? attributeQBInfo.options : []),
    [hasReqChars, attributeQBInfo.options]
  );

  const getAttributeCountWithBounds = (inputValue: string) => {
    const filteredBounds = currentBounds.filter(
      ({ bound }: AttributeBound) => bound !== attribute.key
    );

    const inputBound = getAttributeBounds(attribute, [inputValue], LK.key);

    const newBounds = [...filteredBounds, inputBound];
    getAttributeCount({
      bounds: newBounds,
      searchType: attribute.key,
      drawnPolygons,
      shapeId,
      isBasedOnIdentifierFileUpload: false,
      isMetric: isMetricOnSelection,
    });
  };

  const getCountWithDebounce = useDebouncedCallback((value) => {
    getAttributeCountWithBounds(value);
    setIsDebouncing(false);

    if (attributeQBInfo.isUpdated) return;
    updateCurrentQBInfo(attribute.key, "isUpdated", true);
  }, attribute.debounce);

  const handleInputChange = (
    value: string,
    reason?: AutocompleteInputChangeReason
  ) => {
    if (reason === "reset") return;

    updateCurrentQBInfo(attribute.key, "input", value);

    // NOTE: Prevent calling of count api when satisfied the required
    // characters (will not work as expected when text is pasted)
    // if (attribute.debounce === 0 && value.length > attribute.reqChars) {
    //   return;
    // }

    if (attribute.reqChars && value.length < attribute.reqChars) return;
    setIsDebouncing(true);
    getCountWithDebounce(value);
  };

  const onAttributeSelect = (
    selected: RecordCounts[],
    isMultipleSelecting?: boolean
  ) => {
    const selectedValues = selected.map((item) => item.attributeValue);
    updateCurrentQBInfo(attribute.key, "values", selectedValues);
    modifyAttributeBounds(selectedValues, IN.key);
    if (!isMultipleSelecting) handleOnBlur();
  };

  const handleClick = () => {
    const value = attributeQBInfo.input;
    if (
      (attribute.reqChars && value.length < attribute.reqChars) ||
      attributeQBInfo.isUpdated
    ) {
      return;
    }

    getAttributeCountWithBounds(value);
    updateCurrentQBInfo(attribute.key, "isUpdated", true);
  };

  const handleOnBlur = () => {
    updateCurrentQBInfo(attribute.key, "input", "");
    updateCurrentQBInfo(attribute.key, "options", []);
  };

  return (
    <div className="relative">
      <QBTypeAheadComboBox
        inputRef={inputRef}
        options={attributeOptions}
        isOptionsLoading={hasReqChars ? isLoading : false}
        value={attributeQBInfo.values.map((val) => ({
          attributeValue: val.toString(),
          count: 0,
        }))}
        label={attribute.label}
        onSelect={(_, value, isMultipleSelecting) =>
          onAttributeSelect(value as RecordCounts[], isMultipleSelecting)
        }
        onInputChange={(_, value, reason) => handleInputChange(value, reason)}
        onBlur={handleOnBlur} // Replaces `clearOnBlur`
        onClick={handleClick}
        reqChars={attribute.reqChars}
        isDebouncing={isDebouncing}
        inputValue={attributeQBInfo.input}
        hasOnInputEvents
        isMultiple
        attribute={attribute}
        sortKey={attributeQBInfo.sortKey}
      />
    </div>
  );
};

export default QBSpecialAttribute;
