import { SearchCriteria } from "../../../types/common/search";
import { SaveRecordConfigData } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../constants/panels/searchPanel/common/accordion";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";

import { formatToNonAutoSearchType } from "../../../utils/savedSearch/savedData";

import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useSearchStore from "../../search/useSearchStore";
import useLoadRecordGridConfigs from "./useLoadRecordGridConfigs";
import useLoadSearches from "./useLoadSearches";

const useLoadRecordConfigs = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateSearchTypeTab = searchStore((state) => state.updateSearchTypeTab);
  const updateNewSearchCriteria = searchStore(
    (state) => state.updateNewSearchCriteria
  );
  const updateIsSearchedRecordType = searchStore(
    (state) => state.updateIsSearchedRecordType
  );
  const toggleIsSearchAccordionToggledOn = searchStore(
    (state) => state.toggleIsSearchAccordionToggledOn
  );

  const dataGridStore = useDataGridRecordStore({ searchRecordType });
  const updateSortPayload = dataGridStore((state) => state.updateSortPayload);
  const updateInitialWellDataFetched = dataGridStore(
    (state) => state.updateInitialWellDataFetched
  );

  const loadGridConfigs = useLoadRecordGridConfigs(searchRecordType);
  const loadSearchConfigs = useLoadSearches(searchRecordType);

  const loadConfigByRecordType = async (
    recordConfigData: SaveRecordConfigData,
    shapesConfigData?: Pick<
      SearchCriteria,
      "drawnPolygons" | "polygonType" | "shapeId"
    >
  ) => {
    const {
      searchType,
      attributeSearch,
      sortPerAttribute,
      identifierSearch,
      fileUpload,
      gridConfiguration,
      isToggleOn,
    } = recordConfigData;
    const nonAutoSearchType = formatToNonAutoSearchType(searchType);
    updateSearchTypeTab(
      nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH
        ? ATTRIBUTES_TAB
        : IDENTIFIER_TAB
    );

    updateIsSearchedRecordType(isToggleOn);
    toggleIsSearchAccordionToggledOn(isToggleOn);

    let searchCriteria: Partial<SearchCriteria> = {};
    if (
      nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH &&
      attributeSearch
    ) {
      // Attribute Search
      searchCriteria = loadSearchConfigs.loadAttributeSearch(
        attributeSearch,
        sortPerAttribute
      );
    } else if (
      nonAutoSearchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH &&
      identifierSearch
    ) {
      // Identifier Search
      const identifier = loadSearchConfigs.loadUWISearch(
        identifierSearch.searchIdentifiers
      );
      searchCriteria = {
        ...searchCriteria,
        identifier: { ...identifier, type: "uwi", fileId: "" },
      };
    } else if (
      // Identifier File Search
      nonAutoSearchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH &&
      fileUpload
    ) {
      const uwiUploadState = await loadSearchConfigs.loadUWIFileUpload(
        fileUpload
      );
      searchCriteria = {
        ...searchCriteria,
        identifier: { ...uwiUploadState, type: "uwi", identifiers: [] },
      };
    } else if (
      // Identifier Converted File Search
      nonAutoSearchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH &&
      fileUpload &&
      identifierSearch
    ) {
      const uwiConvertedFileState = await loadSearchConfigs.loadUWIFileUpload(
        fileUpload,
        identifierSearch.searchIdentifiers
      );
      searchCriteria = { ...searchCriteria, ...uwiConvertedFileState };
    }

    const gridConfigs = loadGridConfigs.loadGridConfigs(gridConfiguration);
    updateInitialWellDataFetched(true);
    if (gridConfigs.sort?.length) {
      updateSortPayload(gridConfigs.sort);
    } else {
      updateSortPayload([]);
    }

    updateNewSearchCriteria({
      drawnPolygons: [],
      polygonType: "",
      currentBounds: [],
      identifier: { type: "uwi", identifiers: [], fileId: "" },
      shapeId: "",
      ...searchCriteria,
      ...gridConfigs,
      ...shapesConfigData,
    });
  };

  return { loadConfigByRecordType };
};

export default useLoadRecordConfigs;
