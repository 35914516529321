import { useEffect } from "react";

import { fromCircle, fromExtent } from "ol/geom/Polygon";

import { DRAW_MODE_NONE } from "../../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useAlertPopupStore from "../../../store/map/alert/alertStore";
import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import useStore from "../../../store/useStore";

import { drawToSelectDataCallback } from "../../../utils/map/selection/wellSelection";

import useRecordType from "../../common/useRecordType";
import useDrawPolygon from "../../search/useDrawPolygon";
import useCartoClickProps from "../useCartoClickProps";
import useSagaMap from "../useSagaMap";
import useSelectionData from "./useSelectionData";

const useDrawToSelect = () => {
  const { searchedRecordTypes } = useRecordType();

  const updateDrawToSelectMode = useStore(
    (state) => state.updateDrawToSelectMode
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );
  // Wells
  const wellsSearchCriteria = useSearchWellsStore(
    (state) => state.searchCriteria
  );

  // Permits
  const permitsSearchCriteria = useSearchPermitsStore(
    (state) => state.searchCriteria
  );

  const wellSelectionData = useSelectionData(RECORD_TYPES.WELL);
  const permitSelectionData = useSelectionData(RECORD_TYPES.PERMIT);

  const { cartoClickProps } = useCartoClickProps();
  const { map } = useSagaMap();
  const { updateDrawMode } = useDrawPolygon();

  useEffect(() => {
    drawToSelectDataCallback({
      recordType: RECORD_TYPES.WELL,
      drawToSelectData: wellSelectionData.data,
      updateAlertState,
      ...cartoClickProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellSelectionData.data]);

  useEffect(() => {
    drawToSelectDataCallback({
      recordType: RECORD_TYPES.PERMIT,
      drawToSelectData: permitSelectionData.data,
      updateAlertState,
      ...cartoClickProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitSelectionData.data]);

  const callGetSelectionData = (
    polygonSelect: { coordinates: number[][][]; type: string }[],
    viewport?: { type: string }
  ) => {
    if (searchedRecordTypes.includes(RECORD_TYPES.WELL)) {
      wellSelectionData.getSelectionData({
        searchCriteria: wellsSearchCriteria,
        polygonSelect,
        viewport,
      });
    }
    if (searchedRecordTypes.includes(RECORD_TYPES.PERMIT)) {
      permitSelectionData.getSelectionData({
        searchCriteria: permitsSearchCriteria,
        polygonSelect,
        viewport,
      });
    }
  };

  const drawToSelectCircleCallBack = (e: any, newDrawListener: any) => {
    if (e?.feature?.getGeometry() && map) {
      map.removeInteraction(newDrawListener);
      updateDrawToSelectMode(DRAW_MODE_NONE);

      const circleGeometry = e.feature.clone().getGeometry();
      const polygon = fromCircle(circleGeometry);

      const selectedPolygon4326 = polygon.transform("EPSG:3857", "EPSG:4326");

      const drawToSelectPolygon = {
        // coordinates: selectedPolygon4326.getCoordinates(),
        coordinates: [],
        type: "Polygon",
      };

      const extent = map.getView().calculateExtent(map.getSize());

      const polygonFromExtent = fromExtent(extent).transform(
        "EPSG:3857",
        "EPSG:4326"
      );

      const viewport = {
        // coordinates: polygonFromExtent.getCoordinates(),
        type: "Polygon",
      };

      callGetSelectionData([drawToSelectPolygon], viewport);
    }
  };

  const drawToSelectCallback = (e: any, newDrawListener: any) => {
    if (e?.feature?.getGeometry() && map) {
      map.removeInteraction(newDrawListener);
      updateDrawToSelectMode(DRAW_MODE_NONE);

      const selectedPolygon4326 = e.feature
        .clone()
        .getGeometry()
        .transform("EPSG:3857", "EPSG:4326");

      const drawToSelectPolygon = {
        coordinates: selectedPolygon4326.getCoordinates(),
        type: "Polygon",
      };

      const extent = map.getView().calculateExtent(map.getSize());

      const polygonFromExtent = fromExtent(extent).transform(
        "EPSG:3857",
        "EPSG:4326"
      );

      const viewport = {
        // coordinates: polygonFromExtent.getCoordinates(),
        type: "Polygon",
      };

      callGetSelectionData([drawToSelectPolygon], viewport);
    }
  };

  return {
    drawToSelectDataLoading:
      wellSelectionData.isLoading && permitSelectionData.isLoading,
    drawToSelectCallback,
    drawToSelectCircleCallBack,
    updateDrawMode,
  };
};

export default useDrawToSelect;
