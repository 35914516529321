import { RecordType } from "../../types/panels/searchPanel/search";

import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useAppModeStore from "../../store/common/appModeStore";
import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";
import useMapDrawStore from "../../store/map/draw/mapDrawStore";
import useExtentPermitsStore from "../../store/map/gis/extent/permits/extentPermitsStore";
import useExtentWellsStore from "../../store/map/gis/extent/wells/extentWellsStore";
import useMapStore from "../../store/map/mapStore";
import { resetCarbonStorageSlice } from "../../store/map/settings/carbonStorageSlice";
import { resetMapOverlaySlice } from "../../store/map/settings/mapOverlaySlice";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import { resetStratModelsSlice } from "../../store/map/settings/stratigraphicModelsSlice";
import usePanelsStore from "../../store/panels/panelsStore";
import useSaveSearchStore from "../../store/saveSearch/saveSearchStore";
import useSearchPanelStore from "../../store/search/panel/useSearchPanelStore";
import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";
import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";
import useStore from "../../store/useStore";

import useRecordType from "../common/useRecordType";
import useSagaMap from "../map/useSagaMap";
import useDrawPolygon from "./useDrawPolygon";
import useSearchToggleLayers from "./useSearchToggleLayers";

const useSearchPanelActions = () => {
  const { resetMapViewBaseMap } = useSagaMap();
  const { removeAllPolygonsFromSource } = useDrawPolygon();

  const polygons = useMapDrawStore((state) => state.polygons);
  const resetAllPolygonStates = useMapDrawStore(
    (state) => state.resetAllPolygonStates
  );

  const resetWellCard = useStore((state) => state.resetWellCard);

  const updateSavedSearchData = useSaveSearchStore(
    (state) => state.updateSavedSearchData
  );

  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();
  const selectionWellsStore = useWellSelectionStore();
  const selectionPermitsStore = usePermitSelectionStore();
  const dataGridWellsStore = useDataGridWellsStore();
  const extentWellsStore = useExtentWellsStore();
  const extentPermitsStore = useExtentPermitsStore();

  const resetWellList = usePanelsStore((state) => state.resetWellList);
  const resetPermitList = usePanelsStore((state) => state.resetPermitList);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateOpenedQBEditorPanel = useSearchPanelStore(
    (state) => state.updateOpenedQBEditorPanel
  );

  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const resetExportStates = useExportCardStore(
    (state) => state.resetExportStates
  );

  const drawLayerSource = useMapStore((state) => state.drawLayerSource);
  const resetMapStates = useMapStore((state) => state.resetMapStates);
  const setDVTUpdated = useMapStore((state) => state.setDVTUpdated);
  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );
  const resetLayerStylingStates = useMapSettingsStore(
    (state) => state.resetLayerStylingStates
  );

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );
  const updateUoMValueOnSearch = useAppModeStore(
    (state) => state.updateUoMValueOnSearch
  );
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { searchedRecordTypes } = useRecordType();
  const { updateLayersByRecordType } = useSearchToggleLayers();

  const resetPolygons = () => {
    if (polygons.length) {
      resetAllPolygonStates();
      removeAllPolygonsFromSource(polygons, drawLayerSource);
    }
  };

  const resetAttribsPolygons = () => {
    searchWellsStore.clearQBValues();
    searchWellsStore.updateCurrentBounds([]);
    resetPolygons();
  };

  const searchCallback = () => {
    const newRecordTypes: RecordType[] = [];
    if (searchWellsStore.isSearchAccordionToggledOn) {
      newRecordTypes.push(RECORD_TYPES.WELL);
    }
    if (searchPermitsStore.isSearchAccordionToggledOn) {
      newRecordTypes.push(RECORD_TYPES.PERMIT);
    }

    if (
      JSON.stringify(searchedRecordTypes) !== JSON.stringify(newRecordTypes)
    ) {
      resetLayerStylingStates();
    }
    // this doesn't need to be in the above condition
    updateLayersByRecordType(searchedRecordTypes, newRecordTypes);

    searchWellsStore.resetSearchCriteria();
    searchPermitsStore.resetSearchCriteria();
    updateUoMValueOnSearch(UoMValueOnSelection);
    updateUoMAlertState(undefined);

    extentWellsStore.resetExtentInfo();
    extentPermitsStore.resetExtentInfo();

    if (newRecordTypes.includes(RECORD_TYPES.WELL))
      updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);

    updateInitialGridSearchMade(true);
    toggleGrid(true);
    toggleGridHeader(true);
    toggleIsHighlightSelectedSpots(true);
    selectionWellsStore.resetAllMapSelections();
    selectionPermitsStore.resetAllMapSelections();
    updateIsSearchCriteriaChange(false);
    resetWellCard();
    resetWellList();
    resetPermitList();
  };

  // NOTE: Saving for reference
  // TODO: Update to include resetting of Permits
  // This is triggered by the save dialog
  const saveCallback = () => {
    // let hasLoadedSavedSearch = false;
    // if (searchWellsStore.searchTypeTab === ATTRIBUTES_TAB) {
    //   searchWellsStore.resetIdentifierStates();
    //   hasLoadedSavedSearch = hasLoadedIdentifierSavedSearch;
    // } else {
    //   resetAttribsPolygons();
    //   hasLoadedSavedSearch = hasLoadedAttribSavedSearch;
    // }
    // // if saving with a loaded saved search and has a different search type
    // // Do partial reset
    // if (!hasLoadedSavedSearch) return;
    // setDVTUpdated(false);
    // toggleGrid(false);
    // toggleGridHeader(false);
    // updateInitialGridSearchMade(false);
    // updateSavedSearchData(null);
    // selectionWellsStore.resetAllMapSelections();
    // selectionPermitsStore.resetAllMapSelections();
    // searchWellsStore.resetSearchCriteria();
    // dataGridWellsStore.resetSortFilterModels();
    // dataGridWellsStore.resetGridStates();
    // resetExportStates();
  };

  const resetCallback = () => {
    updateSavedSearchData(null);
    toggleGrid(false);
    toggleGridHeader(false);
    updateInitialGridSearchMade(false);
    dataGridWellsStore.updateGridRecordDataTrigger("");
    updateOpenedQBEditorPanel(null);
    searchWellsStore.resetSearchCriteria();
    dataGridWellsStore.resetDefaultGridModels();
    resetMapViewBaseMap();
    resetMapOverlaySlice();
    resetLayerStylingStates();
    resetCarbonStorageSlice();
    resetStratModelsSlice();
    resetMapStates();
    selectionWellsStore.resetAllMapSelections();
    selectionPermitsStore.resetAllMapSelections();
    resetExportStates();
    dataGridWellsStore.resetGridStates();
    resetWellCard();
    searchWellsStore.updateIsQBUpdated(false);
    resetWellList();
  };

  return {
    searchCallback,
    saveCallback,
    resetCallback,
    resetPolygons,
    resetAttribsPolygons,
  };
};

export default useSearchPanelActions;
