import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  CHART_TYPES,
  MAX_WELLS_COUNT_ASSET_ECONOMICS,
} from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

const useChartReference = () => {
  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const selectedWellUwis = useWellSelectionStore(
    (state) => state.selectedRecordUwis
  );

  const selectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const checkIDsReference = useCallback(
    (chartType: ChartType, chartRecordType: RecordType) => {
      let hasRecordSelection = false;
      switch (chartType) {
        case CHART_TYPES.PRODUCTION_PLOT:
        case CHART_TYPES.TYPE_CURVE:
          hasRecordSelection = Boolean(selectedWellIdsKeys.length);
          break;
        case CHART_TYPES.ASSET_ECONOMICS:
          hasRecordSelection = Boolean(
            selectedWellUwis.length &&
              selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
          );
          break;
        case CHART_TYPES.SCATTER_PLOT:
          if (chartRecordType === RECORD_TYPES.WELL)
            hasRecordSelection = Boolean(selectedWellIdsKeys.length);
          if (chartRecordType === RECORD_TYPES.PERMIT)
            hasRecordSelection = Boolean(selectedPermitIdsKeys.length);
          break;
        default:
          hasRecordSelection = Boolean(selectedWellIdsKeys.length);
          break;
      }

      return hasRecordSelection;
    },
    [selectedWellIdsKeys, selectedWellUwis, selectedPermitIdsKeys]
  );

  return { checkIDsReference };
};

export default useChartReference;
