import { useCallback } from "react";

import {
  SaveShapesConfiguration,
  SavedGeometryData,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";

const useSaveShapesConfig = () => {
  const polygons = useMapDrawStore((state) => state.polygons);

  const getShapesConfig = useCallback(() => {
    let geometry: SaveShapesConfiguration["geometry"] = {
      coordinates: [],
    };
    if (polygons?.length) {
      const geometryCoordinates: SavedGeometryData[] = [];
      for (const polygonData of polygons) {
        const { id, isSelected, isShapeFile, coordinates } = polygonData;
        const polygon: SavedGeometryData = {
          id,
          isSelected,
          isShapeFile,
          coordinates: [],
        };
        coordinates[0].forEach((element: number[], index: number) => {
          const coordinate = {
            i: index,
            coordinateX: element[0],
            coordinateY: element[1],
          };
          polygon.coordinates.push(coordinate);
        });
        geometryCoordinates.push(polygon);
      }
      geometry = { coordinates: geometryCoordinates };
    }
    return geometry;
  }, [polygons]);

  return {
    getShapesConfig,
  };
};

export default useSaveShapesConfig;
