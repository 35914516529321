import { useCallback, useMemo } from "react";

import {
  ChartAxisType,
  IScatterPlotChartData,
  ScatterPlotWellChartData,
} from "../../types/charts/chartType/chartType";

import { SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES } from "../../constants/charts/scatterPlotDataFields";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

import useExportToExcel from "../exports/useExportToExcel";
import { useScatterPlotExport } from "../exports/useScatterPlotExport";

interface UseScatterPlotChartInfoProps {
  chartData: IScatterPlotChartData | undefined;
}

const useScatterPlotChartInfo = ({
  chartData,
}: UseScatterPlotChartInfoProps) => {
  const { parseDataForExport, generateExportFileName } = useScatterPlotExport();
  const { exportToExcel } = useExportToExcel();
  const selectedWellRecordIds = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const selectedPermitRecordIds = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );

  const chartReferecenceRecordIds = useMemo(() => {
    let recordIds = [];

    switch (chartData?.recordType) {
      case RECORD_TYPES.WELL:
        recordIds = selectedWellRecordIds;
        break;
      case RECORD_TYPES.PERMIT:
        recordIds = selectedPermitRecordIds;
        break;

      default:
        recordIds = selectedWellRecordIds;

        break;
    }

    return recordIds;
  }, [chartData?.recordType, selectedPermitRecordIds, selectedWellRecordIds]);

  const getScaling = useCallback(
    (yAxis: ChartAxisType) => {
      return chartData?.chartAxis.find((axis) => axis.name === yAxis)?.scale;
    },
    [chartData?.chartAxis]
  );

  const getYAxisLabel = useCallback(
    (yAxis: ChartAxisType) => {
      const filteredYAxisFields = chartData?.chartYAxisDataFields.filter(
        (field) => field.axis === yAxis
      );
      const chartAxis = chartData?.chartAxis.find(
        (axis) => axis.name === yAxis
      );
      if (chartAxis?.title) return chartAxis?.title;

      if (filteredYAxisFields?.length === 1) {
        return filteredYAxisFields[0].displayName; //get the field display name
      }

      return "";
    },
    [chartData?.chartYAxisDataFields, chartData?.chartAxis]
  );

  const showLegend = useMemo(() => {
    if (!chartData) {
      return false;
    }
    return (
      chartData?.chartYAxisDataFields?.length > 1 ||
      Boolean(chartData.chartColorBy?.toggle)
    );
  }, [chartData?.chartYAxisDataFields, chartData?.chartColorBy?.toggle]);

  const legendTitle = useMemo(() => {
    let titleText = "";

    if (
      chartData?.chartColorBy?.attributeKey &&
      chartData.chartColorBy.toggle
    ) {
      titleText =
        SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES.find(
          (attr) => attr.key === chartData.chartColorBy?.attributeKey
        )?.label ?? "";
    }

    return titleText;
  }, [chartData?.chartColorBy?.attributeKey, chartData?.chartColorBy?.toggle]);

  const onExportData = useCallback(() => {
    const fileName = generateExportFileName(
      chartData?.chartType ?? "",
      chartData?.chartId ?? "",
      chartData?.title
    );

    const dataForExport = parseDataForExport(
      chartData?.chartRawData as ScatterPlotWellChartData[] | null,
      chartData?.chartXAxisDataFields,
      chartData?.chartYAxisDataFields,
      chartData?.chartColorBy
    );

    exportToExcel(dataForExport, fileName);
  }, [
    chartData?.chartRawData,
    chartData?.chartYAxisDataFields,
    chartData?.chartXAxisDataFields,
    chartData?.title,
    chartData?.chartColorBy,
  ]);

  return {
    getScaling,
    getYAxisLabel,
    onExportData,
    showLegend,
    legendTitle,
    chartReferecenceRecordIds,
  };
};

export default useScatterPlotChartInfo;
