import { FC } from "react";

import { Menu, MenuItem, MenuProps } from "@mui/material";

import classNames from "classnames";

import { SavedSearchInfo } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { MenuPlacements } from "../../../types/shareLink/shareLink";

import {
  SHARE_SEARCH_BOTTOM_MENU_PLACEMENT,
  SHARE_SEARCH_RIGHT_MENU_PLACEMENT,
} from "../../../constants/panels/shareLink";

import useShareSearch from "../../../customHooks/shareSearch/useShareSearch";

import CopyIcon from "../../common/icons/CopyIcon";

interface ShareSearchMenuProps {
  open: MenuProps["open"];
  anchorEl: MenuProps["anchorEl"];
  handleClose?: () => void;
  isFilterIncluded: boolean;
  shareInfo?: SavedSearchInfo;
  placement?:
    | typeof SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
    | typeof SHARE_SEARCH_RIGHT_MENU_PLACEMENT;
}

const ShareSearchMenu: FC<ShareSearchMenuProps> = ({
  open,
  anchorEl,
  handleClose,
  isFilterIncluded,
  shareInfo,
  placement = SHARE_SEARCH_BOTTOM_MENU_PLACEMENT,
}) => {
  const { handleCopyLinkClick, savedSearchDataLoading } = useShareSearch({
    isFilterIncluded,
    shareInfo,
  });

  const bottomPlacement: MenuPlacements = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const rightPlacement: MenuPlacements = {
    anchorOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  };

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={
        placement === SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
          ? bottomPlacement.anchorOrigin
          : rightPlacement.anchorOrigin
      }
      transformOrigin={
        placement === SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
          ? bottomPlacement.transformOrigin
          : rightPlacement.transformOrigin
      }
      onClose={handleClose}
      className={classNames("share-search-menu-container", {
        "right-placement": Boolean(
          placement === SHARE_SEARCH_RIGHT_MENU_PLACEMENT
        ),
      })}
    >
      <MenuItem onClick={handleCopyLinkClick} disabled={savedSearchDataLoading}>
        Copy Link{" "}
        <span>
          <CopyIcon />
        </span>
      </MenuItem>
    </Menu>
  );
};

export default ShareSearchMenu;
