import { useCallback, useState } from "react";

import { BaseSearchCriteria } from "../../../types/common/search";
import {
  GetTopAttributesProps,
  GetTopAttributesResponse,
} from "../../../types/map/mapSettings/useTopAttributeValues";
import { RecordType } from "../../../types/panels/searchPanel/search";

import config from "../../../configs/appSettings";

import { CUM_BOE } from "../../../constants/attributes";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";

import { callServiceAPI } from "../../../action/callServiceAPI";
import useSearchRequest from "../../common/useSearchRequest";
import useUnitOfMeasure from "../../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../../search/identifier/useIdentifierFileUpload";

const useTopAttributeValues = () => {
  const { buildSearchRequestByParam } = useSearchRequest();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetTopAttributesResponse>([]);
  const [error, setError] = useState<unknown>(null);

  // Wells
  const wellsSearchStore = useSearchWellsStore();
  const wellsIdentifierFileUpload = useIdentifierFileUpload(RECORD_TYPES.WELL);

  // Permits
  const permitsSearchStore = useSearchPermitsStore();
  const permitsIdentifierFileUpload = useIdentifierFileUpload(
    RECORD_TYPES.PERMIT
  );

  const { isMetricOnSearch } = useUnitOfMeasure();

  const getSearchStore = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) {
        return wellsSearchStore;
      }
      return permitsSearchStore;
    },
    [wellsSearchStore, permitsSearchStore]
  );

  const getIdentifierFileUpload = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) {
        return wellsIdentifierFileUpload;
      }
      return permitsIdentifierFileUpload;
    },
    [wellsIdentifierFileUpload, permitsIdentifierFileUpload]
  );

  const getTopAttributeValues = async ({
    attribute,
    sortBy = CUM_BOE.key,
    recordType = RECORD_TYPES.WELL,
  }: GetTopAttributesProps) => {
    setIsLoading(true);
    setData([]);
    setError(null);

    try {
      const { searchCriteria, identifierSearchUploadedFile } =
        getSearchStore(recordType);
      const { getIdentifierFileIdWithRetry } =
        getIdentifierFileUpload(recordType);

      const topAttributesURL = `${config.endpoints.wellService}api/records/top/${attribute}/by/${sortBy}`;
      const { currentBounds, drawnPolygons, identifier, filters, shapeId } =
        searchCriteria;

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds,
        identifier,
        filters,
      };

      const searchRequestBody = buildSearchRequestByParam({
        pSearchCriteria: requestBodySearchCriteria,
      });

      const body = {
        ...searchRequestBody,
        ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
        recordType,
      };

      const response = await callServiceAPI<GetTopAttributesResponse>(
        topAttributesURL,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        Boolean(identifier.fileId)
      );

      if (!response || !("data" in response)) return;
      setData(response.data);
    } catch (error) {
      console.error("getTopAttributeValues error", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getTopAttributeValues,
  };
};

export default useTopAttributeValues;
