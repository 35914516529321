import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
} from "@mui/x-data-grid-premium";

import { RecordData } from "../../types/common/records";
import {
  FilterOperations,
  FilteringType,
  GridFilterPayload,
} from "../../types/grid/filter";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  ALL_FILTER_OPERATORS_OBJ,
  BETWEEN,
  FILTER_IS_ANY_OPERATOR,
  FILTER_IS_EMPTY_OPERATOR,
  FILTER_LOGICAL_OPERATOR_OR,
  FILTER_TYPE_EXPRESSION_BUILDER,
  IS_ANY_OF,
  IS_EMPTY,
  IS_NOT_EMPTY,
} from "../../constants/grid";

import { changeKey } from "./columnKey";
import {
  bigNumericFields,
  booleanFields,
  columnsNumDataType,
} from "./columnsIdentifier";

export const filterRequestFormat = (
  filterModel: GridFilterModel,
  searchRecordType: RecordType
) => {
  const filters: GridFilterPayload[] = [];
  const linkOperator = filterModel.logicOperator
    ? filterModel.logicOperator
    : GridLogicOperator.And;

  //reformat filter based on api format request
  if (filterModel.items.length > 0) {
    filterModel.items.forEach((filter, index) => {
      if (checkFilterValue(filter)) {
        const { payload: operatorValue } = mapFilterOperators(filter.operator);
        const fieldIndex = filters.findIndex((item) => {
          return item.filter === changeKey(filter.field);
        });

        if (fieldIndex >= 0) {
          const operatorIndex = filters[fieldIndex].operations.findIndex(
            (item) => {
              return (
                item.type === operatorValue &&
                (index > 0 ? item.logicalOperator : !item.logicalOperator)
              );
            }
          );

          if (operatorIndex >= 0) {
            const filterOperator =
              filters[fieldIndex].operations[operatorIndex];

            if (index > 0) {
              if (
                filterOperator &&
                filterOperator.type &&
                filterOperator.type === operatorValue
              ) {
                if (
                  filterOperator.values &&
                  filterOperator.values.length > 0 &&
                  filters[fieldIndex] &&
                  filters[fieldIndex].operations[operatorIndex]
                ) {
                  filters[fieldIndex].operations[operatorIndex].values?.push(
                    filter.value
                  );
                } else {
                  filters[fieldIndex].operations[operatorIndex].values = [
                    filter.value,
                  ];
                }
              } else {
                const updatedOperator = setFilterOperator(
                  filters,
                  linkOperator,
                  filter.field
                );
                filters[fieldIndex].operations.push(
                  getOperationFormat(filter, updatedOperator, searchRecordType)
                );
              }
            } else {
              filters[fieldIndex].operations.push(
                getOperationFormat(filter, "", searchRecordType)
              );
            }
          } else {
            const updatedOperator = setFilterOperator(
              filters,
              linkOperator,
              filter.field
            );
            filters[fieldIndex].operations.push(
              getOperationFormat(filter, updatedOperator, searchRecordType)
            );
          }
        } else {
          const newOperation = getOperationFormat(
            filter,
            index > 0 ? linkOperator : "",
            searchRecordType
          );
          filters.push({
            filter: changeKey(filter.field) as keyof RecordData,
            operations: [newOperation],
          });
        }
      }
    });
  }

  return filters;
};

const setFilterOperator = (
  filters: GridFilterPayload[],
  filterOperator: GridLogicOperator,
  field: string
) => {
  if (filters?.length) {
    const item = filters.find((item) => item.filter === changeKey(field));

    if (item && item.operations?.length) {
      //check if operations contains isAnyOf or isEmpty operator
      //change logical operator to OR, if true
      if (
        item.operations.some((op) =>
          [
            FILTER_IS_EMPTY_OPERATOR.payload,
            FILTER_IS_ANY_OPERATOR.payload,
          ].includes(op.type)
        )
      ) {
        return FILTER_LOGICAL_OPERATOR_OR;
      }
    }
  }

  return filterOperator;
};

const getOperationFormat = (
  filter: GridFilterItem,
  linkOperator = "",
  searchRecordType: RecordType
) => {
  const { payload: payloadType } = mapFilterOperators(filter.operator);
  const operation: FilterOperations = {
    type: payloadType,
  };

  if (filter.operator !== IS_EMPTY && filter.operator !== IS_NOT_EMPTY) {
    // if column is a number datatype
    if (
      filter.value &&
      columnsNumDataType.length > 0 &&
      columnsNumDataType(searchRecordType).includes(filter.field)
    ) {
      // check if operator is anyOf
      // convert array of strings to array of number
      if (filter.operator === IS_ANY_OF && filter.value.length > 0) {
        //if field is big numeric, pass the values as string
        // otherwise, convert to number type
        if (bigNumericFields(searchRecordType).includes(filter.field)) {
          operation.values = [filter.value];
        } else {
          const arrOfNum = filter.value.map((str: number) => {
            return isNaN(str) ? str : Number(str);
          });

          operation.values = [arrOfNum];
        }
      } else {
        if (bigNumericFields(searchRecordType).includes(filter.field)) {
          operation.values = [filter.value];
        } else {
          if (filter.operator === BETWEEN) {
            const arrOfNum = filter.value.map((str: number) => {
              return Number(str);
            });

            operation.values = [arrOfNum];
          } else {
            const val = isNaN(filter.value)
              ? filter.value
              : Number(filter.value);
            operation.values = [val];
          }
        }
      }
    } else {
      if (booleanFields(searchRecordType).includes(filter.field)) {
        const val = filter.value.map(
          (item: string) => item.toLowerCase() === "true"
        );
        operation.values = [val];
      } else {
        operation.values = [filter.value];
      }
    }
  }

  if (linkOperator !== "") {
    operation.logicalOperator = linkOperator.toUpperCase();
  }

  return operation;
};

const mapFilterOperators = (operator: string) => {
  const map = ALL_FILTER_OPERATORS_OBJ;
  const { label, payload } =
    map[operator as keyof typeof ALL_FILTER_OPERATORS_OBJ];

  return { label, payload };
};

const checkFilterValue = (filter: GridFilterItem) => {
  if (filter.operator === IS_EMPTY || filter.operator === IS_NOT_EMPTY)
    return true;

  if (
    !!filter?.value ||
    (Array.isArray(filter.value) && filter.value.length > 0) ||
    filter?.value > -1
  )
    return true;

  return false;
};

export const removeFilterFields = (
  fieldsToRemove: string[] = [],
  filterPayload: GridFilterPayload[] = []
) => {
  filterPayload = filterPayload.filter(
    (payload) => !fieldsToRemove.includes(payload.filter)
  );

  //Important: remove logicalOperator from first array item, if exists
  if (filterPayload[0]?.operations[0]?.logicalOperator) {
    delete filterPayload[0].operations[0].logicalOperator;
  }

  return filterPayload;
};

export const changeColumnFilterAccordingToType = (
  filterModel: GridFilterModel,
  columnsFilterType: { [key: string]: FilteringType }
) => {
  if (filterModel?.items.length) {
    filterModel.items.forEach((item) => {
      if (item.operator !== IS_ANY_OF) {
        columnsFilterType[item.field] = FILTER_TYPE_EXPRESSION_BUILDER;
      }
    });

    return columnsFilterType;
  } else {
    return columnsFilterType;
  }
};
