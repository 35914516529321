import { SearchCriteria } from "../../../types/common/search";
import { Polygon, PolygonData } from "../../../types/map/gis/common";
import { SaveShapesConfiguration } from "../../../types/panels/savedSearchPanel/savedSearchData";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";

import { getPolygonType } from "../../../utils/map/draw/polygon";

import useDrawPolygon from "../../search/useDrawPolygon";

const useLoadShapesConfigs = () => {
  const updatePolygons = useMapDrawStore((state) => state.updatePolygons);
  const updateShapeId = useMapDrawStore((state) => state.updateShapeId);

  const { drawPolygonDynamically } = useDrawPolygon();

  const loadShapes = (
    shapesConfig?: SaveShapesConfiguration
  ):
    | Pick<SearchCriteria, "drawnPolygons" | "polygonType" | "shapeId">
    | undefined => {
    if (!shapesConfig) return;

    const { geometry, shapeId } = shapesConfig;
    const coordinates = geometry?.coordinates;
    const polygons: Polygon = [];
    if (coordinates?.length) {
      for (const geometryData of coordinates) {
        const { id, isSelected, isShapeFile, coordinates } = geometryData;
        const polygon: PolygonData = {
          id,
          isSelected,
          isShapeFile: !!isShapeFile,
          coordinates: [
            coordinates.map((value) => [value.coordinateX, value.coordinateY]),
          ],
        };
        polygons.push(polygon);
        drawPolygonDynamically(polygon);
      }
    }
    updatePolygons(polygons);
    updateShapeId(shapeId ?? "");

    const drawnPolygons = polygons.filter((p) => !p.isShapeFile);
    return {
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      shapeId: shapeId ?? "",
    };
  };

  return { loadShapes };
};

export default useLoadShapesConfigs;
