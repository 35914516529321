import { RecordType } from "../../types/panels/searchPanel/search";

import * as constants from "../../constants/grid";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { allPermitColumns } from "./permits/permitColumns";
import { allWellColumns } from "./wells/wellColumns";

export const columnByType = (searchRecordType: RecordType) => {
  if (searchRecordType === RECORD_TYPES.PERMIT) return allPermitColumns;
  return allWellColumns;
};

export const columnsNumDataType = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((item) => item.type && item.type === "number")
    .map((obj) => obj.field);

export const dateFields = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_DATE)
    .map((col) => col.field);

export const numberFields = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter(
      (item) =>
        item.bigQueryType === constants.COLUMN_TYPE_INTEGER ||
        item.bigQueryType === constants.COLUMN_TYPE_NUMERIC ||
        item.bigQueryType === constants.COLUMN_TYPE_BIG_NUMERIC ||
        item.bigQueryType === constants.COLUMN_TYPE_FLOAT
    )
    .map((col) => col.field);

export const bigNumericFields = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter(
      (item) =>
        item.bigQueryType === constants.COLUMN_TYPE_BIG_NUMERIC ||
        item.bigQueryType === constants.COLUMN_TYPE_FLOAT
    )
    .map((col) => col.field);

export const numericFieldsForFormatting = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((item) => item.type === "number")
    .map((col) => col.field);

export const booleanFields = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_BOOLEAN)
    .map((col) => col.field);

export const varcharFields = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_STRING)
    .map((col) => col.field);

export const fieldFormattedDisplay = (searchRecordType: RecordType) =>
  columnByType(searchRecordType)
    .filter((field) => field.applyFormatting)
    .map((col) => col.field);
