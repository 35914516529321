import { useCallback } from "react";

import { RecordStylingDataSegment } from "../../../types/map/mapSettings/store";
import { RecordStylingData } from "../../../types/map/mapSettings/useRecordSpotStyling";

import { BOTTOM_WELL_SPOTS, WELL_SPOTS } from "../../../constants/constants";

import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

const useBubbleMapInfo = () => {
  const layers = useMapStore((state) => state.layers);
  const compressedSurfaceBubbleMapData = useMapSettingsStore(
    (state) => state.compressedSurfaceBubbleMapData
  );
  const compressedBottomBubbleMapData = useMapSettingsStore(
    (state) => state.compressedBottomBubbleMapData
  );
  const surfaceBubbleMapValues = useMapSettingsStore(
    (state) => state.surfaceBubbleMapValues
  );
  const bottomBubbleMapValues = useMapSettingsStore(
    (state) => state.bottomBubbleMapValues
  );
  const bubbleMapPartitionMinMax = useMapSettingsStore(
    (state) => state.bubbleMapPartitionMinMax
  );

  const isLayerSelected = useCallback(
    (layerName: string) => {
      return !!layers.find((layer) => layer.name === layerName)?.isSelected;
    },
    [layers]
  );

  const getBubbleMapInfo = useCallback(() => {
    let compressedBubbleMapData: RecordStylingData = [];
    let bubbleMapValue: RecordStylingDataSegment = {};
    if (isLayerSelected(BOTTOM_WELL_SPOTS)) {
      compressedBubbleMapData = compressedBottomBubbleMapData;
      bubbleMapValue = bottomBubbleMapValues.data;
    } else if (isLayerSelected(WELL_SPOTS)) {
      compressedBubbleMapData = compressedSurfaceBubbleMapData;
      bubbleMapValue = surfaceBubbleMapValues.data;
    }

    const compressedBubbleMapInfo = {
      data: bubbleMapValue,
      min: bubbleMapPartitionMinMax.min,
      max: bubbleMapPartitionMinMax.max,
    };

    return { compressedBubbleMapData, compressedBubbleMapInfo };
  }, [
    compressedBottomBubbleMapData,
    compressedSurfaceBubbleMapData,
    bottomBubbleMapValues.data,
    surfaceBubbleMapValues.data,
    bubbleMapPartitionMinMax,
    isLayerSelected,
  ]);

  return { getBubbleMapInfo };
};

export default useBubbleMapInfo;
