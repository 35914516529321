import { useState } from "react";

import { SearchRequestPayload } from "../types/common/api";
import { RecordData } from "../types/common/records";
import { BaseSearchCriteria, SearchCriteria } from "../types/common/search";
import { RecordDataResponse } from "../types/grid";
import { VisibleColumns } from "../types/grid/export";

import config from "../configs/appSettings";

import * as attribute from "../constants/attributes";
import { EXPORT_TYPE } from "../constants/constants";
import { RECORD_TYPES } from "../constants/panels/searchPanel/search";

import { convertStringToDate } from "../utils/charts/aggregator";
import { changeKey } from "../utils/datagrid/columnKey";

import { callServiceAPI } from "../action/callServiceAPI";
import useSearchRequest from "./common/useSearchRequest";
import useUnitOfMeasure from "./common/useUnitOfMeasure";
import useExportToExcel from "./exports/useExportToExcel";
import useDataGridRecordStore from "./grid/useDataGridRecordStore";
import useIdentifierFileUpload from "./search/identifier/useIdentifierFileUpload";
import useSearchStore from "./search/useSearchStore";

const searchUrl = `${config.endpoints.wellService}api/wells/search/`;

const useAllWellDataForExport = () => {
  const pageLimit = config.searchPageLimit;
  const { buildSearchRequestByParam } = useSearchRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // TEMP: temporarily set to well
  const searchStore = useSearchStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } = useIdentifierFileUpload(
    RECORD_TYPES.WELL
  );
  const updateIsExportToGridLoading = dataGridRecordStore(
    (state) => state.updateIsExportToGridLoading
  );
  const gridFilteredCount = dataGridRecordStore(
    (state) => state.gridFilteredCount
  );
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);
  // END TEMP: temporarily set to well

  const { exportToExcel } = useExportToExcel();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getAllWellDataForExport = async (
    searchCriteria: SearchCriteria,
    columns: string[]
  ) => {
    const allWellData: RecordData[] = [];

    const totalIterations = searchCriteria.gridTotalCount
      ? Math.ceil(searchCriteria.gridTotalCount / pageLimit)
      : 0;

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons: searchCriteria.drawnPolygons,
      shapeId: searchCriteria.shapeId,
      currentBounds: searchCriteria.currentBounds,
      identifier: searchCriteria.identifier,
      filters: searchCriteria.filters,
    };

    const searchRequestBody = buildSearchRequestByParam({
      pSearchCriteria: requestBodySearchCriteria,
    });

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: pageLimit,
      ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
    };

    if (sortPayload.length) {
      body.sort = sortPayload;
    }

    if (columns.length) {
      const searchColumn = columns.map((col) => {
        return changeKey(col);
      });
      body.columns = searchColumn;
    }

    try {
      for (let batchCount = 0; batchCount < totalIterations; batchCount++) {
        body.offset = batchCount * pageLimit;

        const response = await callServiceAPI<RecordDataResponse>(
          searchUrl,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          Boolean(searchCriteria.identifier.fileId)
        );

        if (response && "data" in response) {
          allWellData.push(...response.data.records);
        }
      }

      return { data: allWellData, isSuccess: true };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  };

  const exportDataToExcel = (data: RecordData[], headers: VisibleColumns[]) => {
    const mappedData = data.map((well: any) => {
      const wellObj: { [key: string]: any } = {};
      headers.forEach((header) => {
        if (attribute.DATE_TYPE_FIELDS.includes(header.field)) {
          wellObj[header.field] = convertStringToDate(well[header.field]);
        } else {
          wellObj[header.field] = well[header.field];
        }
      });
      return wellObj;
    });

    const extractedExcelHeader = headers.map((col) => col.header) as string[];

    exportToExcel(
      mappedData,
      "TGS_Grid",
      extractedExcelHeader,
      EXPORT_TYPE.DATA_GRID
    );
  };

  const exportAllWellDataToExcel = (
    searchCriteria: SearchCriteria,
    customHeaders: VisibleColumns[]
  ) => {
    try {
      setIsLoading(true);
      updateIsExportToGridLoading(true);
      const columns = customHeaders.map((col) => col.field);
      getAllWellDataForExport(searchCriteria, columns).then((data) => {
        if (data.isSuccess) {
          exportDataToExcel(data.data, customHeaders);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
        updateIsExportToGridLoading(false);
      });
    } catch (error) {}
  };

  const exportDataAsExcelCallback = (
    allWells: RecordData[],
    visibleColumns: VisibleColumns[]
  ) => {
    if (gridFilteredCount <= pageLimit) {
      //if search result is less than 10k, then use allWells data for export
      const gridDataForExport = Object.assign([], allWells);
      exportDataToExcel(gridDataForExport, visibleColumns);
    } else {
      //do batch data call if filtered result is greater than 10k
      if (searchCriteria) {
        const allWellsDataForExportReq: SearchCriteria = Object.assign(
          {},
          searchCriteria
        );
        allWellsDataForExportReq.gridTotalCount = gridFilteredCount;

        exportAllWellDataToExcel(allWellsDataForExportReq, visibleColumns);
      }
    }
  };

  return {
    isLoading,
    isError,
    exportDataAsExcelCallback,
  };
};

export default useAllWellDataForExport;
