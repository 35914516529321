import { useEffect } from "react";

import { SavedSearchInfo } from "../../types/panels/savedSearchPanel/savedSearchData";

import { SAVED_SEARCH_VISIBILITY_PUBLIC } from "../../constants/panels/savedSearchPanel/hooks";

import useAlertPopupStore from "../../store/map/alert/alertStore";

import { formatCurrentDateTime } from "../../utils";
import useCreateUpdateSavedSearch from "../savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";

interface UseShareSearchProps {
  isFilterIncluded: boolean;
  shareInfo?: SavedSearchInfo;
}

const useShareSearch = ({
  isFilterIncluded,
  shareInfo,
}: UseShareSearchProps) => {
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchError,
    createSavedSearch,
    createDuplicateSavedSearch,
  } = useCreateUpdateSavedSearch();

  const handleCopyLinkClick = async () => {
    const saveName = `Shared Search ${formatCurrentDateTime()}`;

    if (shareInfo?.id) {
      createDuplicateSavedSearch({
        ...shareInfo,
        searchName: saveName,
        visibility: SAVED_SEARCH_VISIBILITY_PUBLIC,
      });
    } else {
      createSavedSearch(
        saveName,
        isFilterIncluded,
        false,
        SAVED_SEARCH_VISIBILITY_PUBLIC
      );
    }
  };

  useEffect(() => {
    // note: make sure current document is focused else will return Document Exception Error
    // not focused ex: alert box is opened or cursor is focused on dev tools or outside browser
    if (
      !savedSearchDataLoading &&
      savedSearchData?.id &&
      window.document.hasFocus()
    ) {
      const savedSearchLink = `${window.location.origin}/${savedSearchData.id}`;
      navigator.clipboard.writeText(savedSearchLink);

      updateAlertState({
        message: "Search Link copied to your clipboard.",
        severity: "success",
      });
    } else if (
      !savedSearchDataLoading &&
      savedSearchData?.id &&
      !window.document.hasFocus()
    ) {
      updateAlertState({
        message: "Cannot copy to clipboard, document is not focused.",
        severity: "error",
      });
    } else if (!savedSearchDataLoading && savedSearchError) {
      updateAlertState({
        message: "Something Went Wrong.",
        severity: "error",
      });
    }
  }, [savedSearchDataLoading, savedSearchData]);

  return {
    handleCopyLinkClick,
    savedSearchDataLoading,
  };
};

export default useShareSearch;
