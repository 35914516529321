import { GridSortModel } from "@mui/x-data-grid-premium";

import { RecordType } from "../../types/panels/searchPanel/search";

import { sortRequestFormat } from "../../utils/datagrid";

import useSearchStore from "../search/useSearchStore";
import useDataGridRecordStore from "./useDataGridRecordStore";
import { useGridColumn } from "./useGridColumn";
import { useGridData } from "./useGridData";

export const useGridSort = (searchRecordType: RecordType) => {
  const searchStore = useSearchStore({
    searchRecordType: searchRecordType,
  });
  const searchCriteria = searchStore((state) => state.searchCriteria);

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: searchRecordType,
  });
  const updateSortPayload = dataGridRecordStore(
    (state) => state.updateSortPayload
  );
  const updateSortModel = dataGridRecordStore((state) => state.updateSortModel);
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );
  const { searchGridData } = useGridData(searchRecordType);
  const { getColumnsPayloadData } = useGridColumn();

  const sortGridCallback = (newSortModel: GridSortModel) => {
    updateSortModel(newSortModel);
    const columns = getColumnsPayloadData();

    if (newSortModel?.length) {
      const sort = sortRequestFormat(newSortModel);

      searchGridData({
        ...searchCriteria,
        columns: columns,
        sort: sort,
        optimizeColumns: true,
      });

      updateSortPayload(sort);
    } else {
      if (sortPayload.length) {
        updateSortPayload([]);
      }

      searchGridData({
        ...searchCriteria,
        columns: columns,
        sort: [],
        optimizeColumns: true,
      });
    }

    updateGridRecordDataTrigger("sort");
  };

  return {
    sortGridCallback,
  };
};
