import * as constants from "../../../attributes";
import { EQ, IN } from "./opTypes";
import { ATTRIBUTE_ALPHA_ASC } from "./sortAttributes";

export const defaultVarcharInfo = {
  options: [],
  operationType: IN.key,
  values: [],
  input: "",
  sortKey: ATTRIBUTE_ALPHA_ASC,
  hasError: false,
  isUpdated: false,
} as const;

export const defaultDateNumericInfo = {
  ...defaultVarcharInfo,
  operationType: "",
} as const;

export const initialOperationType = {
  [constants.TEXT]: IN.key,
  [constants.TEXT_SELECT]: IN.key,
  [constants.FLOAT]: "",
  [constants.INTEGER]: "",
  [constants.BOOLEAN]: EQ.key,
  [constants.DATE]: "",
  [constants.SPECIAL]: IN.key,
} as const;
