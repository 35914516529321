import { ATTRIBUTES_TAB } from "../../../constants/panels/searchPanel/common/accordion";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";

import useAttributeActionButtons from "../attribute/useAttributeActionButtons";
import useIdentifierActionButtons from "../identifier/useIdentifierActionButtons";

const useMergedActionButtons = () => {
  const searchWellsStore = useSearchWellsStore();
  const searchPermitsStore = useSearchPermitsStore();

  const wellAttributeActions = useAttributeActionButtons({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const wellIdentifierActions = useIdentifierActionButtons({
    searchRecordType: RECORD_TYPES.WELL,
  });

  const permitAttributeActions = useAttributeActionButtons({
    searchRecordType: RECORD_TYPES.PERMIT,
  });
  const permitIdentifierActions = useIdentifierActionButtons({
    searchRecordType: RECORD_TYPES.PERMIT,
  });

  const mergeBool = (boolean1: boolean, boolean2: boolean) => {
    return boolean1 || boolean2;
  };

  const mergeFunc = (func1: () => void, func2: () => void) => {
    return () => {
      func1();
      func2();
    };
  };

  // check if wells/permits are toggled
  // check if attribute/identifier tab is toggled for both wells and permits
  const getMergedActionButtons = () => {
    const isWellsToggled = searchWellsStore.isSearchAccordionToggledOn;
    const isPermitsToggled = searchPermitsStore.isSearchAccordionToggledOn;
    const mergedProps = {
      isResetDisabled: true,
      isSaveDisabled: true,
      isSearchDisabled: true,
      handleResetClick: () => null,
      handleSearchClick: () => null,
    };

    if (!isWellsToggled && !isPermitsToggled) return mergedProps;

    const wellActionProps =
      searchWellsStore.searchTypeTab === ATTRIBUTES_TAB
        ? wellAttributeActions
        : wellIdentifierActions;
    const permitActionProps =
      searchPermitsStore.searchTypeTab === ATTRIBUTES_TAB
        ? permitAttributeActions
        : permitIdentifierActions;

    if (isWellsToggled && isPermitsToggled) {
      return {
        isResetDisabled: mergeBool(
          wellActionProps.isResetDisabled,
          permitActionProps.isResetDisabled
        ),
        isSaveDisabled: mergeBool(
          wellActionProps.isSaveDisabled,
          permitActionProps.isSaveDisabled
        ),
        isSearchDisabled: mergeBool(
          wellActionProps.isSearchDisabled,
          permitActionProps.isSearchDisabled
        ),
        handleResetClick: mergeFunc(
          wellActionProps.handleResetClick,
          permitActionProps.handleResetClick
        ),
        handleSearchClick: mergeFunc(
          wellActionProps.handleSearchClick,
          permitActionProps.handleSearchClick
        ),
      };
    }
    if (isPermitsToggled) return permitActionProps;
    return wellActionProps;
  };

  return getMergedActionButtons();
};

export default useMergedActionButtons;
