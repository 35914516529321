import { useCallback } from "react";

import {
  RecordType,
  SearchTypes,
} from "../../../types/panels/searchPanel/search";

import {
  ATTRIBUTES_TAB,
  IDENTIFIER_TAB,
} from "../../../constants/panels/searchPanel/common/accordion";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";

import useSearchStore from "../useSearchStore";

const useSearchTypeByRecordType = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const searchStore = useSearchStore({ searchRecordType });
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const searchTypeTab = searchStore((state) => state.searchTypeTab);

  const getSearchTypeByTab = useCallback(() => {
    if (searchTypeTab === ATTRIBUTES_TAB) {
      return SEARCH_TYPES.ATTRIBUTE_SEARCH;
    } else {
      let searchType = SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH;
      if (identifierSearchUploadedFile && identifierSearchPastedValueText) {
        searchType = SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH;
      } else if (
        identifierSearchUploadedFile &&
        !identifierSearchPastedValueText
      ) {
        searchType = SEARCH_TYPES.IDENTIFIER_FILE_SEARCH;
      }
      return searchType;
    }
  }, [
    searchTypeTab,
    identifierSearchPastedValueText,
    identifierSearchUploadedFile,
  ]);

  const getTabBySearchType = useCallback((searchType: SearchTypes) => {
    if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
      return ATTRIBUTES_TAB;
    } else {
      return IDENTIFIER_TAB;
    }
  }, []);

  return { getSearchTypeByTab, getTabBySearchType };
};

export default useSearchTypeByRecordType;
