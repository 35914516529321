import { FC } from "react";

import { Box, Typography } from "@mui/material";
import {
  gridColumnMenuSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import { RecordType } from "../../../../../../types/panels/searchPanel/search";

import useFilterCheckbox from "../../../../../../customHooks/grid/useFilterCheckBox";

import { dateFields } from "../../../../../../utils/datagrid/columnsIdentifier";

import Loading from "../../../../../common/Loading";
import FilterFooter from "../FilterFooter";
import CheckboxDateTree from "./CheckboxDateTree";
import CheckboxList from "./CheckboxList";
import CheckboxSelectAll from "./CheckboxSelectAll";
import SearchField from "./SearchField";

//FILTER CHECKBOX PANEL USES TWO OPERATORS -> ANY and IS_EMPTY
//use the OR logicalOperator when requesting for both the operators
const FilterCheckbox: FC<{ searchRecordType: RecordType }> = ({
  searchRecordType,
}) => {
  const apiRef = useGridApiContext();
  const openColumnMenuInfo = useGridSelector(apiRef, gridColumnMenuSelector);

  const {
    isLoading,
    disableFilterButton,
    disableClearButton,
    allSelected,
    searchText,
    checkboxLists,
    checked,
    filterLists,
    handleSelectAll,
    handleCheckBoxToggle,
    handleClearFilter,
    applyFilter,
    handleResetSearchValue,
  } = useFilterCheckbox(apiRef, searchRecordType);

  return (
    <div className="grid-filter-container">
      <SearchField
        searchItem={searchText}
        onSearchItemChange={(text) => filterLists(text, searchRecordType)}
        disabled={isLoading}
        onResetSearch={handleResetSearchValue}
      />

      {isLoading ? (
        <div className="filter-list-loader-container">
          <Loading />
        </div>
      ) : checkboxLists?.length ? (
        <>
          <CheckboxSelectAll
            selectedAll={allSelected}
            onSelectAll={(action) => handleSelectAll(action)}
          />
          {openColumnMenuInfo.field && (
            <>
              {dateFields(searchRecordType).includes(
                openColumnMenuInfo.field
              ) ? (
                <CheckboxDateTree
                  checked={checked}
                  listItems={checkboxLists}
                  onToggleSwitch={(val) => handleCheckBoxToggle(val)}
                  field={openColumnMenuInfo.field}
                />
              ) : (
                <CheckboxList
                  checked={checked}
                  listItems={checkboxLists}
                  onToggleSwitch={(val) => handleCheckBoxToggle(val)}
                  field={openColumnMenuInfo.field}
                />
              )}
            </>
          )}
        </>
      ) : (
        <Box className={"filter-list-loader-container"}>
          <Typography variant={"body1"} className="no-filter-result-text">
            No Filter Results
          </Typography>
        </Box>
      )}

      <FilterFooter
        buttons={[
          {
            text: "Clear",
            disabled: disableClearButton,
            variant: "text",
            onClick: handleClearFilter,
          },
          {
            text: "Apply Filter",
            disabled: disableFilterButton,
            variant: "outlined",
            onClick: applyFilter,
          },
        ]}
        padding={"8px 12px"}
      />
    </div>
  );
};

export default FilterCheckbox;
