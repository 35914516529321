import { useState } from "react";

import axios from "axios";

import {
  BaseSearchRequestNoPaginationPayload,
  SearchRequestPayload,
  SearchRequestWithShapes,
} from "../../../types/common/api";
import { RecordData } from "../../../types/common/records";
import { BaseSearchCriteria } from "../../../types/common/search";
import { RecordDataResponse } from "../../../types/grid";
import { GetWellCountResponse } from "../../../types/map/customHooks/useWellCount";
import { GetSelectionData } from "../../../types/map/selection/drawToSelect";
import { RecordType } from "../../../types/panels/searchPanel/search";

import config from "../../../configs/appSettings";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { settledPromise } from "../../../utils/api/request";

import { callServiceAPI } from "../../../action/callServiceAPI";
import { getGeometryFromPolygonArray } from "../../../data/map/mapUtils";
import useSearchRequest from "../../common/useSearchRequest";
import useUnitOfMeasure from "../../common/useUnitOfMeasure";
import { useGridColumn } from "../../grid";
import useIdentifierFileUpload from "../../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../../search/useSearchStore";

const useSelectionData = (searchRecordType: RecordType = RECORD_TYPES.WELL) => {
  const { getRequiredColumns } = useGridColumn(searchRecordType);
  const { buildSearchRequestByParam } = useSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<RecordData[]>([]);
  const [error, setError] = useState<unknown>(null);

  const searchStore = useSearchStore({ searchRecordType });
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);

  const getSelectionData = async ({
    searchCriteria,
    polygonSelect,
    viewport,
  }: GetSelectionData) => {
    setIsLoading(true);
    setError(null);

    const productionWellsUrl = `${config.endpoints.wellService}api/records/search/`;
    const totalCountUrl = `${config.endpoints.wellService}api/records/count`;

    const { currentBounds, identifier, filters } = searchCriteria;

    //shapeId is not sent in payload, since shapeId and geometry
    //are OR-ed together, which will result always selecting all wells
    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons: polygonSelect,
      currentBounds,
      shapeId: "",
      identifier,
      filters,
    };

    let searchRequestBody = buildSearchRequestByParam({
      pSearchCriteria: requestBodySearchCriteria,
      viewport,
    });

    //make sure that there is geometry on body
    if (
      searchRequestBody &&
      polygonSelect.length &&
      !("geometry" in searchRequestBody)
    ) {
      const geo = getGeometryFromPolygonArray(polygonSelect, viewport);

      if (geo) {
        searchRequestBody = {
          ...searchRequestBody,
          geometry: geo,
        } as SearchRequestWithShapes<BaseSearchRequestNoPaginationPayload>;
      }
    }

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: 1,
      columns: getRequiredColumns([]),
      ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
      recordType: searchRecordType,
    };

    try {
      // Need to get totalCount first for query

      const initialResponse = await callServiceAPI<GetWellCountResponse>(
        totalCountUrl,
        body,
        getIdentifierFileIdWithRetry,
        identifierSearchUploadedFile,
        Boolean(identifier.fileId)
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const totalCount = initialResponse.data.totalCount;
      const totalIterations = Math.ceil(totalCount / 10000);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((num, i) => {
          return axios.post<RecordDataResponse>(
            productionWellsUrl,
            {
              ...body,
              offset: i * 10000,
              pageLimit: 10000,
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const gridDatas = values.flatMap((response) => response.data.records);
          setData(gridDatas);
          setIsLoading(false);
        });
      } else {
        setData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.debug("getSelectionData error", error);
      setError(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getSelectionData,
  };
};

export default useSelectionData;
