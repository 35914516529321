import { useCallback } from "react";

import { ArrowLeft as ArrowLeftIcon } from "@mui/icons-material";
import { styled } from "@mui/material";

import {
  AnchorPosition,
  PanelControlProps,
  StyledPanelControlProps,
} from "../../../types/panels/common/panelControl";

const PanelControlButton = styled("button")(
  ({
    orientation,
    reverse,
    isReverseByDefault,
    anchorPosition,
  }: StyledPanelControlProps) => ({
    position: "absolute",
    height: 28.22,
    zIndex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: 20,
    backgroundColor: "#121212",
    border: 0,
    borderRadius: "0px 4px 4px 0px",
    transform: `rotate(${orientation === "vertical" ? 0 : -90}deg) 
                scaleX(${!isReverseByDefault ? 1 : -1})`,
    top: 0,
    ...anchorPosition,

    transition: "left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    svg: {
      transform: `scaleX(${!reverse ? 1 : -1})`,
      color: "#FFFFFF",
      opacity: 0.7,
    },
  })
);

const PanelControl = ({
  orientation = "vertical",
  open,
  reverse = false,
  anchorEl,
  anchorOrigin = {
    horizontal: "right",
  },
  clickCallback,
}: PanelControlProps) => {
  const getPosition = useCallback(() => {
    const position: AnchorPosition = {};
    if (anchorEl) {
      if (anchorOrigin.horizontal === "right") {
        position.right = -20;
      }
      if (anchorOrigin.horizontal === "left") {
        position.left = -20;
      }
      if (anchorOrigin.vertical === "top") {
        position.top = -24;
        position.left = 9;
      }
      if (anchorOrigin.vertical === "bottom") {
        position.bottom = -24;
        position.left = 9;
      }
    } else {
      if (anchorOrigin.horizontal === "right") {
        position.left = 0;
      }
      if (anchorOrigin.horizontal === "left") {
        position.right = 0;
      }
      if (anchorOrigin.vertical === "top") {
        position.top = 0;
      }
      if (anchorOrigin.vertical === "bottom") {
        position.bottom = 0;
      }
    }
    return position;
  }, [anchorEl, anchorOrigin]);

  return (
    <PanelControlButton
      orientation={orientation}
      reverse={!open}
      isReverseByDefault={reverse}
      anchorPosition={getPosition()}
      onClick={() => clickCallback()}
    >
      <ArrowLeftIcon fontSize="small" />
    </PanelControlButton>
  );
};

export default PanelControl;
