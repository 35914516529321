import { useCallback, useMemo } from "react";

import { cloneDeep } from "lodash";

import {
  SaveSearchData,
  SavedAssetEconomicsData,
  SavedCashFlowChartData,
  SavedChartData,
  SavedScatterPlotChartData,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { CHART_TYPES } from "../../../constants/charts/charts";
import { CREATE_SAVED_SEARCH } from "../../../constants/panels/savedSearchPanel/hooks";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useChartStore from "../../../store/chart/chartStore";
import useAppModeStore from "../../../store/common/appModeStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import useModularityStore from "../../../store/modularity/modularityStore";

import useSaveRecordConfig from "./useSaveRecordConfig";
import useSaveShapesConfig from "./useSaveShapesConfig";

const useBuildSaveSearchData = () => {
  const layers = useMapStore((state) => state.layers);
  const baseMapSelected = useMapStore((state) => state.baseMapSelected);

  const chartData = useChartStore((state) => state.chartData);
  const dashboardChartData = useChartStore((state) => state.dashboardChartData);

  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const modules = useModularityStore((state) => state.modules);

  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const applyWellColorToPermits = useMapSettingsStore(
    (state) => state.applyWellColorToPermits
  );
  const mapOverlayOpened = useMapSettingsStore(
    (state) => state.mapOverlayOpened
  );
  const layerLegends = useMapSettingsStore((state) => state.layerLegends);
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );
  const stratModelsInfo = useMapSettingsStore((state) => state.stratModelsInfo);

  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );

  const shapeId = useMapDrawStore((state) => state.shapeId);

  const { getShapesConfig } = useSaveShapesConfig();
  const {
    getRecordSearchData: getWellsSearchData,
    getRecordGridConfigData: getWellsGridConfigData,
  } = useSaveRecordConfig(RECORD_TYPES.WELL);
  const {
    getRecordSearchData: getPermitsSearchData,
    getRecordGridConfigData: getPermitsGridConfigData,
  } = useSaveRecordConfig(RECORD_TYPES.PERMIT);

  const modulesConfig = useCallback(() => {
    if (!chartData.length) return {};

    // only save chart configs
    const copiedChartData: (
      | SavedChartData
      | SavedScatterPlotChartData
      | SavedAssetEconomicsData
      | SavedCashFlowChartData
    )[] = cloneDeep(chartData);
    const mappedChartData = copiedChartData.map((data) => {
      if (data.objectType !== CHART_TYPES.ASSET_ECONOMICS) {
        data.chartData = [];
        data.chartRawData = [];
        if (
          data.objectType === CHART_TYPES.PRODUCTION_PLOT ||
          data.objectType === CHART_TYPES.TYPE_CURVE
        ) {
          data.chartDisplayedDataFields = data.chartDisplayedDataFields.map(
            ({ name, accumMethod }) => ({ name, accumMethod })
          );
        } else if (data.objectType === CHART_TYPES.SCATTER_PLOT) {
          data.chartYAxisDataFields = data.chartYAxisDataFields.map(
            ({ name, axis }) => ({ name, axis })
          );
          if (data.chartXAxisDataFields) {
            data.chartXAxisDataFields = (({ name }) => ({ name }))(
              data.chartXAxisDataFields
            );
          }

          if (data.chartColorBy) {
            data.chartColorBy = (({ toggle, attributeKey }) => ({
              toggle,
              attributeKey,
            }))(data.chartColorBy);
          }
        }

        const { objectType, ...modifiedData } = data;

        return modifiedData;
      } else {
        return data;
      }
    });

    return {
      modulesConfiguration: {
        layout: {
          default: chartDefaultLayout,
          fullScreen: chartFullScreenLayout,
        },
        modules,
        chartData: mappedChartData,
      },
    };
  }, [chartData, chartDefaultLayout, chartFullScreenLayout, modules]);

  const dashboardChartConfig = useCallback(() => {
    if (!dashboardChartData.length) return {};

    const copiedDashboardChartData = cloneDeep(dashboardChartData);
    const mappedDashboardChartData = copiedDashboardChartData.map(
      ({ chartId, chartType, groupBy }) => ({ chartId, chartType, groupBy })
    );
    return {
      dashboardChartConfiguration: {
        dashboardChartData: mappedDashboardChartData,
      },
    };
  }, [dashboardChartData]);

  const mapConfig = useMemo(() => {
    if (!mapOverlayOpened.length) return {};
    return { mapOverlayOpened };
  }, [mapOverlayOpened]);

  const buildSaveSearchData = useCallback(
    ({ action = CREATE_SAVED_SEARCH, hasLoadedAndUpdatedSearch = false }) => {
      const wellsSearchData = getWellsSearchData();
      const permitsSearchData = getPermitsSearchData();

      const savedSearchData: SaveSearchData = {
        ver: "v2",
        unitOfMeasureConfiguration: UoMValueOnSelection,
        mapConfiguration: {
          layers: layers.map(({ name, isSelected }) => ({ name, isSelected })),
          baseMap: baseMapSelected,
          layerStyles,
          applyWellColorToPermits,
          carbonStorageInfo,
          stratModelsInfo,
          layerLegendsOpened: layerLegends,
          ...mapConfig,
        },
        recordConfiguration: {
          wells: {
            ...wellsSearchData.searchState,
            gridConfiguration: {
              ...getWellsGridConfigData(action, hasLoadedAndUpdatedSearch),
            },
          },
          permits: {
            ...permitsSearchData.searchState,
            gridConfiguration: {
              ...getPermitsGridConfigData(action, hasLoadedAndUpdatedSearch),
            },
          },
        },
        shapesConfiguration: {
          geometry: getShapesConfig(),
          shapeId,
        },
        // ...modulesConfig(),
        // ...dashboardChartConfig(),
      };

      // Old save search data
      // const savedSearchData: LoadSearchDataV1 = {
      //   unitOfMeasureConfiguration: UoMValueOnSelection,
      //   gridConfiguration: {
      //     columnsModel: columnVisibilityModel,
      //     columnsOrder,
      //     sortModel,
      //     filterModel,
      //     ...gridConfig(action, hasLoadedAndUpdatedSearch),
      //   },
      //   mapConfiguration: {
      //     layers: layers.map(({ name, isSelected }) => ({ name, isSelected })),
      //     baseMap: baseMapSelected,
      //     layerStyles,
      //     applyWellColorToPermits,
      //     carbonStorageInfo,
      //     stratModelsInfo,
      //     layerLegendsOpened: layerLegends,
      //     ...mapConfig,
      //   },
      //   ...searchState,
      //   ...modulesConfig(),
      //   ...dashboardChartConfig(),
      // };

      return {
        searchData: savedSearchData,
      };
    },
    [
      layers,
      baseMapSelected,
      layerStyles,
      applyWellColorToPermits,
      // columnVisibilityModel,
      // columnsOrder,
      // sortModel,
      // filterModel,
      layerLegends,
      mapConfig,
      UoMValueOnSelection,
      carbonStorageInfo,
      stratModelsInfo,
      shapeId,
      getShapesConfig,
      // gridConfig,
      getWellsSearchData,
      getWellsGridConfigData,
      getPermitsSearchData,
      getPermitsGridConfigData,
      // modulesConfig,
      // dashboardChartConfig,
    ]
  );

  return { buildSaveSearchData };
};

export default useBuildSaveSearchData;
