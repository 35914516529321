import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { DashboardChartState } from "../../types/charts/dashboardChartType/dashboardChartType";
import {
  BaseSearchRequestNoPaginationPayload,
  ChartsSearchRequestPayload,
} from "../../types/common/api";
import { Bounds } from "../../types/common/attributes";
import { SearchCriteria } from "../../types/common/search";
import { RecordType } from "../../types/panels/searchPanel/search";

import { CHART_DATA_STATE } from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchPermitsStore from "../../store/search/permits/searchPermitsStore";
import useSearchWellsStore from "../../store/search/wells/searchWellsStore";
import usePermitSelectionStore from "../../store/selection/permits/permitSelectionStore";
import useWellSelectionStore from "../../store/selection/wells/wellSelectionStore";

import { clone } from "../../utils/helper";

import useSearchRequest from "../common/useSearchRequest";
import { useSelectedRecordByRecordType } from "../grid/useSelectedRecordByRecordType";

const useChartSearchRequest = () => {
  //Wells
  const wellRecordSearchCriteria = useSearchWellsStore(
    (state) => state.searchCriteria
  );
  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedWellIdsKeys = useWellSelectionStore(
    (state) => state.deselectedIdsKeys
  );
  const isSearchedRecordTypeWells = useSearchWellsStore(
    (state) => state.isSearchedRecordType
  );

  const {
    isSelectedAllGrid: isSelectedAllWellRecords,
    isDeselectedIds: isDeselectedWellRecords,
  } = useSelectedRecordByRecordType({
    searchRecordType: RECORD_TYPES.WELL,
  });

  //Permits
  const permitRecordSearchCriteria = useSearchPermitsStore(
    (state) => state.searchCriteria
  );
  const selectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedPermitIdsKeys = usePermitSelectionStore(
    (state) => state.deselectedIdsKeys
  );
  const {
    isSelectedAllGrid: isSelectedAllPermitRecords,
    isDeselectedIds: isDeselectedPermitRecords,
  } = useSelectedRecordByRecordType({
    searchRecordType: RECORD_TYPES.PERMIT,
  });

  const { buildSearchRequestByParam } = useSearchRequest();

  const isDashboardChartEmpty = useCallback(
    (chartDataState: DashboardChartState) =>
      !isSearchedRecordTypeWells &&
      chartDataState !== CHART_DATA_STATE.PRE_SEARCH,
    [isSearchedRecordTypeWells]
  );

  const getIsSelectedAllRecordByRecordType = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) return isSelectedAllWellRecords;
      if (recordType === RECORD_TYPES.PERMIT) return isSelectedAllPermitRecords;
    },
    [isSelectedAllPermitRecords, isSelectedAllWellRecords]
  );

  const getIsDeselectedRecordByRecordType = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) return isDeselectedWellRecords;
      if (recordType === RECORD_TYPES.PERMIT) return isDeselectedPermitRecords;
    },
    [isDeselectedPermitRecords, isDeselectedWellRecords]
  );

  const getDeselectedIdsByRecordType = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) return deselectedWellIdsKeys;
      if (recordType === RECORD_TYPES.PERMIT) return deselectedPermitIdsKeys;
    },
    [deselectedPermitIdsKeys, deselectedWellIdsKeys]
  );

  const getSelectedIdsByRecordType = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) return selectedWellIdsKeys;
      if (recordType === RECORD_TYPES.PERMIT) return selectedPermitIdsKeys;
    },
    [selectedPermitIdsKeys, selectedWellIdsKeys]
  );

  const geSearchCriteriaByRecordType = useCallback(
    (recordType: RecordType) => {
      if (recordType === RECORD_TYPES.WELL) return wellRecordSearchCriteria;
      if (recordType === RECORD_TYPES.PERMIT) return permitRecordSearchCriteria;
    },
    [permitRecordSearchCriteria, wellRecordSearchCriteria]
  );

  const buildSearchRequestIdsByRecordType = (
    recordType: RecordType,
    ids: number[]
  ) => {
    if (recordType === RECORD_TYPES.WELL) return { wellIds: ids };
    if (recordType === RECORD_TYPES.PERMIT) return { permitIds: ids };
  };

  const buildSearchRequest = (recordType: RecordType) => {
    const searchCriteria = geSearchCriteriaByRecordType(recordType);

    if (searchCriteria) {
      const copiedSearchCriteria: SearchCriteria = clone(searchCriteria);

      const request = {
        recordType: recordType,
        ...buildSearchRequestByParam({
          pSearchCriteria: copiedSearchCriteria,
        }),
      } as ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload>;

      return request;
    }
  };

  const buildChartRequestByRecordType = (recordType: RecordType) => {
    if (getIsSelectedAllRecordByRecordType(recordType)) {
      return buildSearchRequest(recordType);
    } else {
      if (getIsDeselectedRecordByRecordType(recordType)) {
        let deselectedSearchRequest = buildSearchRequest(recordType);
        const deselectedRecordIds = getDeselectedIdsByRecordType(recordType);

        if (deselectedRecordIds?.length) {
          deselectedSearchRequest = {
            ...deselectedSearchRequest,
            ...buildSearchRequestIdsByRecordType(
              recordType,
              deselectedRecordIds
            ),
            isDeselected: true,
          };
        }

        return deselectedSearchRequest;
      } else {
        let selectedSearchRequest: ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload> =
          {};

        const selectedRecordIds = getSelectedIdsByRecordType(recordType);

        if (selectedRecordIds?.length) {
          selectedSearchRequest = {
            recordType: recordType,
            ...selectedSearchRequest,
            ...buildSearchRequestIdsByRecordType(recordType, selectedRecordIds),
          };
        }
        return selectedSearchRequest;
      }
    }
  };
  const buildFilterByPostRequest = useCallback(
    (chartType: ChartType) => {
      if (isSelectedAllWellRecords) {
        return buildSearchRequest(RECORD_TYPES.WELL);
      } else {
        if (deselectedWellIdsKeys.length > 0) {
          let deSelectedSearchRequest = buildSearchRequest(RECORD_TYPES.WELL);

          if (deselectedWellIdsKeys.length) {
            deSelectedSearchRequest = {
              ...deSelectedSearchRequest,
              wellIds: deselectedWellIdsKeys,
              isDeselected: true,
            };
          }

          return deSelectedSearchRequest;
        } else {
          const selectedSearchRequest: ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload> =
            {};
          if (selectedWellIdsKeys.length) {
            selectedSearchRequest.wellIds = selectedWellIdsKeys;
          }

          return selectedSearchRequest;
        }
      }
    },
    [isSelectedAllWellRecords, selectedWellIdsKeys, deselectedWellIdsKeys]
  );

  return {
    isDashboardChartEmpty,
    buildSearchRequest,
    buildChartRequestByRecordType,
    buildFilterByPostRequest,
    getSelectedIdsByRecordType,
  };
};

export default useChartSearchRequest;
