import { useState } from "react";

import axios from "axios";

import {
  CreateSavedSearchFunction,
  CreateSavedSearchRequestBody,
  DuplicateSavedSearchFunction,
  DuplicateSavedSearchRequestbody,
  UpdateSavedSearch,
} from "../../../types/panels/savedSearchPanel/hooks";
import { GetSaveSearchDataResponseLatest } from "../../../types/panels/savedSearchPanel/savedSearchData";

import config from "../../../configs/appSettings";

import {
  CREATE_SAVED_SEARCH,
  SAVED_SEARCH_VISIBILITY_PRIVATE,
  UPDATE_SAVED_SEARCH,
} from "../../../constants/panels/savedSearchPanel/hooks";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useSaveSearchStore from "../../../store/saveSearch/saveSearchStore";
import useStore from "../../../store/useStore";

import useSavedSearchHelper from "../useSavedSearchHelper";
import useBuildSaveSearchData from "./useBuildSaveSearchData";
import useSavedSearchExportData from "./useSavedSearchExportData";

const useCreateUpdateSavedSearch = () => {
  const authUser = useStore((state) => state.authUser);

  const savedSearchData = useSaveSearchStore((state) => state.savedSearchData);
  const updateSavedSearchData = useSaveSearchStore(
    (state) => state.updateSavedSearchData
  );
  const { error, setError, catchError } = useSavedSearchHelper();
  const { buildSaveSearchData } = useBuildSaveSearchData();
  const { savedSearchExportData } = useSavedSearchExportData();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetSaveSearchDataResponseLatest | null>(
    null
  );

  const createSavedSearch: CreateSavedSearchFunction = (
    saveName,
    isFilterIncluded,
    isAutoSave,
    visibility,
    onClickSaveCallback
  ) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const sapCustomerId = authUser.SapCustomerId ?? null;
    const { searchData } = buildSaveSearchData({
      action: CREATE_SAVED_SEARCH,
      hasLoadedAndUpdatedSearch: !isFilterIncluded,
    });

    // Note: Temporarily set to attribute search
    const searchExportData = config.isScheduledExportsEnabled
      ? savedSearchExportData(SEARCH_TYPES.ATTRIBUTE_SEARCH)
      : {};

    const body: CreateSavedSearchRequestBody = {
      userId: `${authUser.uid}`,
      projectId: null,
      applicationName: "Saga-Analytics",
      name: saveName,
      templateType: "WithData",
      searchName: saveName,
      autoSave: isAutoSave,
      visibility,
      activeFlag: "true",
      creationTime: "",
      updatedTime: "",
      searchData: searchData,
      sapCustomerId,
      ...searchExportData,
    };

    axios
      .post<GetSaveSearchDataResponseLatest>(
        `${config.endpoints.wellService}api/searches`,
        body
      )
      .then(({ data }) => {
        setData(data);
        onClickSaveCallback?.();

        if (visibility === SAVED_SEARCH_VISIBILITY_PRIVATE) {
          updateSavedSearchData(data);
        }
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const createDuplicateSavedSearch: DuplicateSavedSearchFunction = (
    info,
    onClickSaveCallback
  ) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const body: DuplicateSavedSearchRequestbody = {
      id: info.id,
      userId: `${authUser.uid}`,
      applicationName: info.applicationName,
      templateType: info.templateType,
      searchName: info.searchName,
      visibility: info.visibility,
      activeFlag: info.activeFlag,
      sapCustomerId: info.sapCustomerId,
    };

    axios
      .post<GetSaveSearchDataResponseLatest>(
        `${config.endpoints.wellService}api/searches`,
        body
      )
      .then(({ data }) => {
        setData(data);
        onClickSaveCallback?.();
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const updateSavedSearch: UpdateSavedSearch = () => {
    if (!savedSearchData) return;
    setIsLoading(true);
    setData(null);
    setError(null);

    const { searchData } = buildSaveSearchData({
      action: UPDATE_SAVED_SEARCH,
    });

    // Saving for Reference
    // const searchExportData = config.isScheduledExportsEnabled
    //   ? savedSearchExportData(searchType)
    //   : {};

    const body = {
      userId: `${authUser.uid}`,
      searchName: savedSearchData.searchName,
      searchData,
      lastRunTime: "true",
      // ...searchExportData,
    };

    axios
      .post<GetSaveSearchDataResponseLatest>(
        `${config.endpoints.wellService}api/searches/update/${savedSearchData.id}`,
        body,
        {
          params: {
            applicationName: "Saga-Analytics",
          },
        }
      )
      .then(({ data }) => {
        setData(data);
        updateSavedSearchData(data);
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    data,
    error,
    createSavedSearch,
    createDuplicateSavedSearch,
    updateSavedSearch,
  };
};

export default useCreateUpdateSavedSearch;
