import { RecordData } from "../../types/common/records";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useSearchStore from "../search/useSearchStore";
import useDataGridRecordStore from "./useDataGridRecordStore";
import { useGridColumn } from "./useGridColumn";
import { useGridData } from "./useGridData";

export const useGridInfiniteLoader = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const pageLimit = config.searchPageLimit;
  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const updateGridRecordDataTrigger = dataGridRecordStore(
    (state) => state.updateGridRecordDataTrigger
  );
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);

  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const { searchGridData } = useGridData(searchRecordType);
  const { getColumnsPayloadData } = useGridColumn(searchRecordType);

  const getNextBatchWellData = (newOffset: number) => {
    const columns = getColumnsPayloadData();
    updateGridRecordDataTrigger("pagination");
    searchGridData({
      ...searchCriteria,
      offset: newOffset,
      pageLimit: pageLimit,
      columns: columns,
      sort: sortPayload,
      optimizeColumns: true,
    });
  };

  const getNext500WellData = (
    currentRows: number,
    rowSize: number,
    totalFetchedRows: number,
    mapGridData: RecordData[]
  ) => {
    const next500rows = currentRows + rowSize;
    let nextRowsCount = next500rows;
    if (next500rows > totalFetchedRows) {
      const remainingCount = totalFetchedRows - currentRows;
      nextRowsCount = remainingCount + currentRows;
    }

    const nextSetOfData = mapGridData.slice(currentRows, next500rows);

    return {
      nextRowsCount,
      nextSetOfData,
    };
  };

  return {
    getNextBatchWellData,
    getNext500WellData,
  };
};
