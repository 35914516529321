import {
  ChartAxisDataFields,
  ChartColorByInfo,
} from "../../types/charts/chartType/chartType";
import { ChartAxis } from "../../types/charts/chartTypeSettings/chartTypeSettings";
import { RecordType } from "../../types/panels/searchPanel/search";

import {
  COMPLETION_DATE,
  CUM_BOE,
  PERMIT_APPROVAL_DATE,
  PERMIT_TOTAL_DEPTH,
} from "../../constants/attributes";
import { CHART_AXIS, CHART_SCALING_TYPE } from "../../constants/charts/charts";
import {
  SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES,
} from "../../constants/charts/scatterPlotDataFields";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

interface DefaultChart {
  xAxis: ChartAxisDataFields;
  yAxis: ChartAxisDataFields[];
  colorBy: ChartColorByInfo;
  axis: ChartAxis[];
}
const useScatterPlotChartReset = () => {
  const colorByDefault: ChartColorByInfo = {
    toggle: false,
    attributesList: [],
    minMax: {
      min: null,
      max: null,
      hasNull: false,
    },
  };

  const axisDefault: ChartAxis[] = [
    {
      name: CHART_AXIS.LEFT,
      scale: CHART_SCALING_TYPE.LINEAR,
      title: "",
    },
  ];

  const buildFormDefaultVal = (
    xAxisField: ChartAxisDataFields | undefined,
    yAxisField: ChartAxisDataFields | undefined
  ) => {
    if (xAxisField && yAxisField) {
      const data: DefaultChart = {
        xAxis: xAxisField,
        yAxis: [{ ...yAxisField, axis: CHART_AXIS.LEFT }],
        colorBy: colorByDefault,
        axis: axisDefault,
      };

      return data;
    } else {
      return undefined;
    }
  };

  const scatterPlotWellDefaultVal = () => {
    const xAxisField =
      SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === COMPLETION_DATE.key
      ) ?? undefined;
    const yAxisField =
      SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === CUM_BOE.key
      ) ?? undefined;

    return buildFormDefaultVal(xAxisField, yAxisField);
  };

  const scatterPlotPermitDefaultVal = () => {
    const xAxisField =
      SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === PERMIT_APPROVAL_DATE.key
      ) ?? undefined;
    const yAxisField =
      SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES.find(
        (dataField) => dataField.name === PERMIT_TOTAL_DEPTH.key
      ) ?? undefined;

    return buildFormDefaultVal(xAxisField, yAxisField);
  };

  const getScatterPlotDefaultByRecordType = (recordType: RecordType) => {
    switch (recordType) {
      case RECORD_TYPES.WELL:
        return scatterPlotWellDefaultVal();
      case RECORD_TYPES.PERMIT:
        return scatterPlotPermitDefaultVal();
      default:
        return scatterPlotWellDefaultVal();
    }
  };

  return { getScatterPlotDefaultByRecordType };
};

export default useScatterPlotChartReset;
