import { FC } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { RecordType } from "../../../../types/panels/searchPanel/search";

import { RECORD_TYPES_LIST } from "../../../../constants/panels/searchPanel/search";

interface RecordPickerProps {
  selectedRecordType: RecordType;
  handleOnChange: (e: SelectChangeEvent<RecordType>) => void;
}

const RecordPicker: FC<RecordPickerProps> = ({
  selectedRecordType,
  handleOnChange,
}) => {
  return (
    <Grid xs={2} item>
      <FormControl fullWidth>
        <InputLabel id="record-type-select-label" variant="outlined" shrink>
          Record Type
        </InputLabel>
        <Select
          id="record-type-select"
          label="Scale"
          value={selectedRecordType}
          onChange={(e: SelectChangeEvent<RecordType>) => {
            handleOnChange(e);
          }}
        >
          {RECORD_TYPES_LIST.map((type) => {
            return (
              <MenuItem key={type.key} value={type.key}>
                {type.displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default RecordPicker;
