import { useCallback } from "react";

import { RecordStylingDataSegment } from "../../../types/map/mapSettings/store";
import { RecordStylingData } from "../../../types/map/mapSettings/useRecordSpotStyling";

import {
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../../constants/constants";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

const useLayerStylingInfo = () => {
  const compressedSurfaceData = useMapSettingsStore(
    (state) => state.compressedSurfaceData
  );
  const compressedBottomData = useMapSettingsStore(
    (state) => state.compressedBottomData
  );
  const compressedPermitData = useMapSettingsStore(
    (state) => state.compressedPermitData
  );
  const surfaceWellSpotValues = useMapSettingsStore(
    (state) => state.surfaceWellSpotValues
  );
  const bottomWellSpotValues = useMapSettingsStore(
    (state) => state.bottomWellSpotValues
  );
  const permitValues = useMapSettingsStore((state) => state.permitValues);
  const partitionMinMax = useMapSettingsStore((state) => state.partitionMinMax);
  const permitMinMax = useMapSettingsStore((state) => state.permitMinMax);

  const getLayerStylingInfo = useCallback(
    (layerName: string) => {
      let layerData: RecordStylingData = [];
      let layerColorValues: RecordStylingDataSegment = {};
      let minMax: {
        min: number | null;
        max: number | null;
      } = { min: null, max: null };
      if (layerName === WELL_SPOTS) {
        layerData = compressedSurfaceData;
        layerColorValues = surfaceWellSpotValues.data;
        minMax = partitionMinMax;
      } else if (
        layerName === BOTTOM_WELL_SPOTS ||
        layerName === WELL_PATH_AND_STICKS
      ) {
        layerData = compressedBottomData;
        layerColorValues = bottomWellSpotValues.data;
        minMax = partitionMinMax;
      } else if (layerName === PERMIT_SPOTS) {
        layerData = compressedPermitData;
        layerColorValues = permitValues.data;
        minMax = permitMinMax;
      }

      const compressedWellSpotInfo = {
        data: layerColorValues,
        min: minMax.min,
        max: minMax.max,
      };

      return { compressedWellSpotData: layerData, compressedWellSpotInfo };
    },
    [
      compressedSurfaceData,
      compressedBottomData,
      compressedPermitData,
      surfaceWellSpotValues.data,
      bottomWellSpotValues.data,
      permitValues.data,
      partitionMinMax,
      permitMinMax,
    ]
  );

  return { getLayerStylingInfo };
};

export default useLayerStylingInfo;
