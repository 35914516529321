import {
  GetSaveSearchDataResponse,
  GetSaveSearchDataResponseV1,
} from "../../types/panels/savedSearchPanel/loadSearchData";
import { GetSaveSearchDataResponseLatest } from "../../types/panels/savedSearchPanel/savedSearchData";

// NOTE: This file is to support backwards compatibility with
// old version of save searches. (WIP)
const transformV1ToLatest = (
  data: GetSaveSearchDataResponse
): GetSaveSearchDataResponseLatest => {
  const dataV1 = data as GetSaveSearchDataResponseV1;
  const { searchType, searchData, ...rest } = dataV1;
  const {
    attributeSearch,
    sortPerAttribute,
    uwiSearch,
    fileUpload,
    gridConfiguration,
    unitOfMeasureConfiguration,
    mapConfiguration,
    modulesConfiguration,
    dashboardChartConfiguration,
  } = searchData;

  return {
    ...rest,
    isAutoCreated: false,
    searchData: {
      ver: "v2",
      recordConfiguration: {
        wells: {
          searchType,
          isToggleOn: true,
          ...(attributeSearch ? { bounds: attributeSearch?.bounds } : {}),
          ...(sortPerAttribute ? { ...sortPerAttribute } : {}),
          ...(uwiSearch ? { ...uwiSearch } : {}),
          ...(fileUpload ? { ...fileUpload } : {}),
          gridConfiguration,
        },
        permits: {
          searchType,
          isToggleOn: true,
          ...(attributeSearch ? { bounds: attributeSearch?.bounds } : {}),
          ...(sortPerAttribute ? { ...sortPerAttribute } : {}),
          ...(uwiSearch ? { ...uwiSearch } : {}),
          ...(fileUpload ? { ...fileUpload } : {}),
          gridConfiguration,
        },
      },
      unitOfMeasureConfiguration,
      mapConfiguration,
      ...(modulesConfiguration ?? {}),
      ...(dashboardChartConfiguration ?? {}),
      ...(attributeSearch
        ? {
            shapesConfiguration: {
              geometry: attributeSearch.geometry,
              shapeId: attributeSearch.shapeId,
            },
          }
        : {}),
    },
  };
};

export const transformOldToNewSaveSearches = (
  data: GetSaveSearchDataResponse
): GetSaveSearchDataResponseLatest => {
  const { searchData } = data;
  if (searchData.ver === "v2") {
    return data as GetSaveSearchDataResponseLatest;
  } else {
    return transformV1ToLatest(data);
  }
};
