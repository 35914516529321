import { FC } from "react";

import { Typography } from "@mui/material";
import {
  gridColumnMenuSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";

import { RecordType } from "../../../../types/panels/searchPanel/search";

import { columnByType } from "../../../../utils/datagrid/columnsIdentifier";

//Note: always import the component within grid customized components (inside)
//otherwise useGridApiContext will fail
const MenuHeader: FC<{ searchRecordType: RecordType }> = ({
  searchRecordType,
}) => {
  const apiRef = useGridApiContext();
  const openColumnMenuInfo = useGridSelector(apiRef, gridColumnMenuSelector);
  const headerName =
    columnByType(searchRecordType).filter(
      (column) => column.field === openColumnMenuInfo.field
    )?.[0]?.headerName ?? "";

  return (
    <div className="grid-menu-header">
      <Typography variant={"body1"}>{headerName}</Typography>
    </div>
  );
};

export default MenuHeader;
