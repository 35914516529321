import { FC, SyntheticEvent, useRef, useState } from "react";

import { RecordCounts } from "../../../../../../types/common/records";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { IN } from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useSearchStore from "../../../../../../customHooks/search/useSearchStore";

import QBTypeAheadComboBox from "./QBAutocomplete";

const QBVarcharAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  searchRecordType,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentQBInfo = searchStore((state) => state.updateCurrentQBInfo);

  const [inputValue, setInputValue] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onAttributeSelect = (
    selected: RecordCounts[],
    isMultipleSelecting?: boolean
  ) => {
    const selectedValues = selected.map((item) => item.attributeValue);
    updateCurrentQBInfo(attribute.key, "values", selectedValues);
    modifyAttributeBounds(selectedValues, IN.key);
    if (!isMultipleSelecting) setInputValue("");
  };

  return (
    <QBTypeAheadComboBox
      inputRef={inputRef}
      options={attributeQBInfo.options}
      isOptionsLoading={isOptionsLoading}
      value={attributeQBInfo.values.map((val) => ({
        attributeValue: val.toString(),
        count: 0,
      }))}
      label={attribute.label}
      onSelect={(_, value, isMultipleSelecting) =>
        onAttributeSelect(value as RecordCounts[], isMultipleSelecting)
      }
      onClick={(sort) => getOptionsWithWellCounts(sort)}
      onBlur={() => setInputValue("")} // Replaces `clearOnBlur`
      onInputChange={(e: SyntheticEvent<Element, Event>, value, reason) =>
        reason !== "reset" && setInputValue(value)
      }
      inputValue={inputValue}
      attribute={attribute}
      sortKey={attributeQBInfo.sortKey}
      hasOnInputEvents
      isMultiple
    />
  );
};

export default QBVarcharAttribute;
