import { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { GridFilterItem, useGridApiContext } from "@mui/x-data-grid-premium";

import {
  BtwValues,
  ExpressionBuilderProps,
  NumberFilterItem,
} from "../../../../../../../types/grid";

import {
  BETWEEN,
  FILTER_EQUALS_OPERATOR,
  FILTER_NUMBER_EXPRESSION_BUILDER_OPERATORS,
  FILTER_TYPE_EXPRESSION_BUILDER,
  IS_ANY_OF,
  IS_EMPTY,
} from "../../../../../../../constants/grid";
import { RECORD_TYPES } from "../../../../../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../../../../../customHooks/grid/useDataGridRecordStore";
import usePrevious from "../../../../../../../customHooks/usePrevious";

import { fieldFormattedDisplay } from "../../../../../../../utils/datagrid/columnsIdentifier";
import { defaultBetweenValues } from "../../../../../../../utils/datagrid/defaults";

import { generate5DigitUniqueRandomNumber } from "../../../../../../../utils";
import FilterFooter from "../../FilterFooter";
import FilterOperatorField from "./FilterOperatorField";
import MultipleTextFields from "./MultipleTextFields";

const NumberFilterExpression: FC<ExpressionBuilderProps> = ({
  field,
  cancelPanel,
  searchRecordType,
}) => {
  const apiRef = useGridApiContext();
  const regex =
    searchRecordType && fieldFormattedDisplay(searchRecordType).includes(field)
      ? /^-?[0-9]+(\.[0-9]*)?$/
      : /^-?[0-9\b]+$/;
  const [disableFilterButton, setDisableFilterButton] = useState(true);
  const filterItemDefault = {
    operator: FILTER_EQUALS_OPERATOR.key,
    payload: FILTER_EQUALS_OPERATOR.payload,
    value: null,
    field: field,
  };

  const [filterItem, setFilterItem] =
    useState<NumberFilterItem>(filterItemDefault);
  const [btwValue, setBtwValue] = useState<BtwValues>(defaultBetweenValues);
  const prevFilterItem = usePrevious(filterItem);

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: searchRecordType ?? RECORD_TYPES.WELL,
  });
  const filterModel = dataGridRecordStore((state) => state.filterModel);
  const updateColumnsFilterType = dataGridRecordStore(
    (state) => state.updateColumnsFilterType
  );
  const columnsFilterType = dataGridRecordStore(
    (state) => state.columnsFilterType
  );

  const handleInputValueChange = (value: string) => {
    if (regex.test(value)) {
      setFilterItem({
        ...filterItem,
        value,
      });
    } else if (!value) {
      setFilterItem({
        ...filterItem,
        value: null,
      });
    }
  };

  const handleFilterOperatorChange = (operator: string) => {
    const filterOperator = FILTER_NUMBER_EXPRESSION_BUILDER_OPERATORS.find(
      (item) => item.key === operator
    );

    if (filterOperator) {
      setFilterItem((prevItem) => ({
        ...prevItem,
        operator,
        payload: filterOperator.payload,
        value: operator === BETWEEN ? null : prevItem.value,
      }));
    }

    if (operator === BETWEEN) setDisableFilterButton(true);
  };

  const handleCancel = () => {
    cancelPanel();
  };

  const applyFilter = () => {
    if (filterItem.value) {
      const newFilterItem: GridFilterItem = {
        field: "",
        operator: "",
      };
      if (filterItem.id) {
        newFilterItem.id = filterItem.id;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = filterItem.value;
      } else {
        const filterIds = filterModel.items.map((value) => value.id);
        const newFilterId = generate5DigitUniqueRandomNumber(filterIds);
        newFilterItem.id = newFilterId;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = filterItem.value;

        setFilterItem({
          ...filterItem,
          id: newFilterId,
        });
      }

      //this only updates the filter model in grid, will not trigger api call
      //datagrid will call the api once changes are detected
      if (columnsFilterType[field] !== FILTER_TYPE_EXPRESSION_BUILDER) {
        const newFilterItems = filterModel.items.filter(
          (item) => item.field !== field
        );
        newFilterItems.push(newFilterItem);

        const newFilterModel = {
          ...filterModel,
          items: newFilterItems,
        };

        apiRef.current.setFilterModel(newFilterModel);
      } else {
        apiRef.current.upsertFilterItem(newFilterItem);
      }
    } else {
      if (filterItem.id) {
        apiRef.current.deleteFilterItem(filterItem);
        setFilterItem(filterItemDefault);
      }
    }

    updateColumnsFilterType(field, FILTER_TYPE_EXPRESSION_BUILDER);
    handleCancel();
    apiRef.current.hideColumnMenu();
  };

  useEffect(() => {
    if (filterItem.value !== prevFilterItem?.["value"]) {
      if (filterItem.operator === BETWEEN) {
        if (
          Array.isArray(filterItem.value) &&
          filterItem.value[0] &&
          filterItem.value[1]
        ) {
          setDisableFilterButton(false);
        } else {
          setDisableFilterButton(true);
        }
      } else {
        if (filterItem.value !== null) {
          setDisableFilterButton(false);
        } else {
          setDisableFilterButton(true);
        }
      }
    } else {
      if (
        filterItem.operator !== prevFilterItem["operator"] &&
        filterItem.value !== null
      ) {
        setDisableFilterButton(false);
      } else {
        setDisableFilterButton(true);
      }
    }
  }, [filterItem]);

  useEffect(() => {
    if (filterModel?.items.length) {
      const item = filterModel.items.find(
        (item) =>
          item.field === field &&
          item.operator !== IS_ANY_OF &&
          item.operator !== IS_EMPTY
      );

      if (item) {
        const operatorValue = FILTER_NUMBER_EXPRESSION_BUILDER_OPERATORS.find(
          (op) => op.key === item.operator
        );

        if (operatorValue) {
          setFilterItem((prevItem) => ({
            ...prevItem,
            id: item.id,
            payload: operatorValue.payload,
            value: item.value,
            operator: operatorValue.key,
          }));
        }

        if (item.operator === BETWEEN) {
          setBtwValue({
            value1: item.value[0],
            value2: item.value[1],
          });
        }
      }
    }
    setDisableFilterButton(true);
  }, []);

  useEffect(() => {
    if (filterItem.operator === BETWEEN) {
      const filterItemValue = [
        btwValue.value1 !== null ? btwValue.value1 : null,
        btwValue.value2 !== null ? btwValue.value2 : null,
      ];

      setFilterItem((prevItem) => ({
        ...prevItem,
        value: filterItemValue,
      }));
    }
  }, [btwValue]);

  return (
    <div
      className="grid-filter-number-panel-container"
      key={`${field}-exp-builder-box`}
    >
      <FilterOperatorField
        operators={FILTER_NUMBER_EXPRESSION_BUILDER_OPERATORS}
        value={filterItem.operator}
        onChange={(val: string) => handleFilterOperatorChange(val)}
      />
      {filterItem.operator === BETWEEN ? (
        <MultipleTextFields
          textFields={[
            {
              label: "Start",
              value: btwValue.value1,
              type: "number",
              onChange: (event) => {
                const val = event.target.value;
                if (regex.test(val)) {
                  setBtwValue((prevVal) => ({
                    ...prevVal,
                    value1: val,
                  }));
                } else if (!val) {
                  setBtwValue((prevVal) => ({
                    ...prevVal,
                    value1: null,
                  }));
                }
              },
            },
            {
              label: "End",
              value: btwValue.value2,
              type: "number",
              onChange: (event) => {
                const val = event.target.value;
                if (regex.test(val)) {
                  setBtwValue((prevVal) => ({
                    ...prevVal,
                    value2: val,
                  }));
                } else if (!val) {
                  setBtwValue((prevVal) => ({
                    ...prevVal,
                    value2: null,
                  }));
                }
              },
            },
          ]}
        />
      ) : (
        <TextField
          fullWidth
          className={"filter-number-value-input"}
          variant="standard"
          type={"number"}
          label={"Value"}
          value={filterItem.value}
          onChange={(event) => {
            handleInputValueChange(event.target.value);
          }}
          onFocus={(e) => {
            e.stopPropagation();
          }}
        />
      )}

      <FilterFooter
        buttons={[
          {
            text: "Cancel",
            disabled: false,
            variant: "text",
            onClick: handleCancel,
          },
          {
            text: "Apply Filter",
            disabled: disableFilterButton,
            variant: "outlined",
            onClick: applyFilter,
          },
        ]}
        paddingTop="20px"
      />
    </div>
  );
};

export default NumberFilterExpression;
