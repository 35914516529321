import { useEffect, useMemo } from "react";

import { ProcessColors } from "../../../../types/map/mapSettings/layerColoring";

import { PERMIT_SPOTS } from "../../../../constants/constants";
import * as mapSettings from "../../../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/search";

import useMapStore from "../../../../store/map/mapStore";
import useMapSettingsStore from "../../../../store/map/settings/mapSettingsStore";
import useSearchPermitsStore from "../../../../store/search/permits/searchPermitsStore";

import { isLayerSelected } from "../../../../utils/map/mapSettings";

import useRecordSpotStyling from "../useRecordSpotStyling";

const useProcessPermitColors = ({
  initialGridSearchMade,
  shouldApplyColorToPermits,
  shouldApplyColorToLayer,
}: ProcessColors) => {
  const searchCriteria = useSearchPermitsStore((state) => state.searchCriteria);
  const layers = useMapStore((state) => state.layers);
  const compressedPermitData = useMapSettingsStore(
    (state) => state.compressedPermitData
  );
  const updatePermitDataInfo = useMapSettingsStore(
    (state) => state.updatePermitDataInfo
  );
  const updatePermitMinMax = useMapSettingsStore(
    (state) => state.updatePermitMinMax
  );
  const layerColorsLoading = useMapSettingsStore(
    (state) => state.layerColorsLoading
  );
  const updateLayerColorsLoading = useMapSettingsStore(
    (state) => state.updateLayerColorsLoading
  );

  const {
    isLoading: isPermitValuesLoading,
    data: permitValues,
    getRecordSpotAttribValues: getPermitValues,
  } = useRecordSpotStyling(RECORD_TYPES.PERMIT);

  const isPermitSelected = useMemo(
    () => isLayerSelected(PERMIT_SPOTS, layers) && shouldApplyColorToPermits,
    [layers, shouldApplyColorToPermits]
  );

  useEffect(() => {
    if (isPermitValuesLoading || !initialGridSearchMade) return;

    updatePermitDataInfo(permitValues);
    updateLayerColorsLoading(PERMIT_SPOTS, false);
  }, [isPermitValuesLoading, permitValues]);

  const callPermitStylingApi = (attributeKey: string) => {
    if (
      isLayerSelected(PERMIT_SPOTS, layers) &&
      shouldApplyColorToLayer(PERMIT_SPOTS, attributeKey)
    ) {
      updateLayerColorsLoading(PERMIT_SPOTS, true);
      getPermitValues({
        attribute: attributeKey,
        searchCriteria,
        layer: mapSettings.STYLING_API_KEY_MAPPER[PERMIT_SPOTS].request,
      });
    } else {
      updatePermitDataInfo([]);
    }
  };

  return {
    isPermitSelected,
    isPermitColorsLoading: layerColorsLoading[PERMIT_SPOTS],
    compressedPermitData,
    callPermitStylingApi,
  };
};

export default useProcessPermitColors;
