import {
  ChartAxisDataFields,
  ChartColorByInfo,
  ScatterPlotWellChartData,
} from "../../types/charts/chartType/chartType";
import { AttributeDataType } from "../../types/common/attributes";

import {
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
} from "../../constants/attributes";
import {
  SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES,
  allOthersKey,
} from "../../constants/charts/scatterPlotDataFields";

import { convertStringToDate } from "../../utils/charts/aggregator";

export const useScatterPlotExport = () => {
  const generateExportFileName = (
    chartType: string,
    chartId: string,
    title?: string
  ) => {
    let fileName = "";

    if (title) {
      fileName = title;
    } else {
      fileName = `${chartType}_${chartId}`;
    }

    return fileName;
  };

  const formatData = (dataType: AttributeDataType, data?: string) => {
    if (!data) {
      return undefined;
    }

    switch (dataType) {
      case DATE:
        return convertStringToDate(data);
      case INTEGER:
        return parseInt(data);
      case FLOAT:
        return parseFloat(data);
      default:
        return data;
    }
  };
  const parseDataForExport = (
    data?: ScatterPlotWellChartData[] | null,
    xAxisAttributes?: ChartAxisDataFields,
    yAxisAttributes?: ChartAxisDataFields[],
    colorByInfo?: ChartColorByInfo
  ) => {
    const parsedData: { [key: string]: string | number | Date | undefined }[] =
      [];

    if (data?.length && xAxisAttributes && yAxisAttributes?.length) {
      const colorByDisplayName =
        colorByInfo?.toggle && colorByInfo.attributeKey
          ? SCATTER_PLOT_RECORDS_COLOR_BY_ATTRIBUTES.find(
              (data) => data.key === colorByInfo.attributeKey
            )?.label
          : "";

      data?.forEach((obj) => {
        const objFields = obj.fields;
        const parsedDataInfo: {
          [key: string]: string | number | Date | undefined;
        } = {};

        //get x
        const xAxisFieldValue = objFields.find(
          (data) => data.name === xAxisAttributes.name && data.val
        );

        //check if x axis field has value bec if none then it is automatic that plotly can't plot it on the chart
        if (xAxisFieldValue) {
          //add x atribute value to parsedDataInfo
          parsedDataInfo[xAxisAttributes.displayName] = formatData(
            xAxisAttributes.dataType,
            xAxisFieldValue?.val
          );

          //get y attributes
          yAxisAttributes.forEach((attr) => {
            const yAxisFieldValue = objFields.find(
              (data) => data.name === attr.name && data.val
            );

            if (yAxisFieldValue) {
              parsedDataInfo[attr.displayName] = formatData(
                attr.dataType,
                yAxisFieldValue?.val
              );
            }
          });

          //get color by attribute
          if (
            colorByInfo?.toggle &&
            colorByInfo.attributeKey &&
            colorByDisplayName &&
            colorByInfo.attibuteDataType
          ) {
            let colorByFieldVal = objFields.find(
              (data) => data.name === colorByInfo.attributeKey
            )?.val;

            if (
              (colorByInfo.attibuteDataType === SPECIAL ||
                colorByInfo.attibuteDataType === TEXT) &&
              (!colorByFieldVal ||
                !colorByInfo.attributesList?.includes(colorByFieldVal))
            ) {
              colorByFieldVal = allOthersKey;
            }

            //add color by atribute value to parsedDataInfo
            parsedDataInfo[colorByDisplayName] = formatData(
              colorByInfo.attibuteDataType,
              colorByFieldVal
            );
          }

          //check if ALL attribute(x axis, y axis, color by) values are not undefined AND
          //check if a y axis attribute value exists
          if (
            !Object.values(parsedDataInfo).every((el) => el === undefined) &&
            Boolean(
              yAxisAttributes.filter(
                (attr) => parsedDataInfo[attr.displayName] !== undefined
              ).length
            )
          ) {
            parsedData.push(parsedDataInfo);
          }
        }
      });
    }

    return parsedData;
  };
  return { parseDataForExport, generateExportFileName };
};
