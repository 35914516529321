import { FC, useEffect, useState } from "react";

import { Box, Divider } from "@mui/material";

import classNames from "classnames";

import { GridPlotHeaderProps } from "../../../../types/grid";

import useMapSettingsStore from "../../../../store/map/settings/mapSettingsStore";
import useSaveSearchStore from "../../../../store/saveSearch/saveSearchStore";
import useStore from "../../../../store/useStore";

import useUnitOfMeasure from "../../../../customHooks/common/useUnitOfMeasure";
import useDataGridRecordStore from "../../../../customHooks/grid/useDataGridRecordStore";
import useSearchStore from "../../../../customHooks/search/useSearchStore";
import useRecordCountData from "../../../../customHooks/useRecordCountData";

import { getNumberWithComma } from "../../../../utils/formatters/numberFormatter";

import AnalysisChartToggle from "../../../panels/analysisChartsPanel/AnalysisChartToggle";
import GridToolBarControls from "./GridToolBarControls";
import ResultCount from "./ResultCount";
import SelectedForAnalysis from "./SelectedForAnalysis";

const GridPlotHeader: FC<GridPlotHeaderProps> = ({
  searchRecordType,
  exportAsExcel,
  resetFilters,
}) => {
  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const updateToastMessage = useStore((state) => state.updateToastMessage);

  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const gridTotalCount = dataGridRecordStore((state) => state.gridTotalCount);
  const updateGridTotalCount = dataGridRecordStore(
    (state) => state.updateGridTotalCount
  );
  const gridFilteredCount = dataGridRecordStore(
    (state) => state.gridFilteredCount
  );

  const batchWellGridDataLoading = dataGridRecordStore(
    (state) => state.batchWellGridDataLoading
  );
  const allWellGridDataLoading = dataGridRecordStore(
    (state) => state.allWellGridDataLoading
  );
  const gridFilteredLoading = dataGridRecordStore(
    (state) => state.gridFilteredLoading
  );
  const isFullScreenGrid = dataGridRecordStore(
    (state) => state.isFullScreenGrid
  );

  const isProcessingStyles = useMapSettingsStore(
    (state) => state.isProcessingStyles
  );

  // Loading states for DVT
  const DVTProcessing = useStore((state) => state.DVTProcessing);

  const isLoadingDrawToSelect = useStore(
    (state) => state.isDrawToSelectLoading
  );

  const fetchedSavedSearchTrigger = useSaveSearchStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  const [shouldCallRecordCount, setShouldCallRecordCount] = useState(false);

  const {
    data: recordCountData,
    isLoading,
    getExpectedRecordCount,
  } = useRecordCountData({
    searchRecordType,
  });

  const { isMetricOnSearch } = useUnitOfMeasure();

  useEffect(() => {
    if (
      !allWellGridDataLoading &&
      !DVTProcessing &&
      !isLoadingDrawToSelect &&
      !isProcessingStyles &&
      gridTotalCount > 250000
    ) {
      updateToastMessage("Please zoom to see all wells in your search area ");
    }
  }, [
    allWellGridDataLoading,
    DVTProcessing,
    isLoadingDrawToSelect,
    isProcessingStyles,
    gridTotalCount,
  ]);

  // Update search result count when api response is ready
  useEffect(() => {
    if (isLoading || !recordCountData) return;
    updateGridTotalCount(recordCountData);
  }, [isLoading, recordCountData]);

  // Update flag for calling the well count api when loading saved search
  useEffect(() => {
    if (!fetchedSavedSearchTrigger) return;
    setShouldCallRecordCount(true);
  }, [fetchedSavedSearchTrigger]);

  // Call search api without filters to get the search result count
  // when loading saved search with filters
  useEffect(() => {
    if (
      !shouldCallRecordCount ||
      fetchedSavedSearchTrigger ||
      !searchCriteria.filters?.length
    )
      return;
    const { drawnPolygons, currentBounds, identifier, shapeId } =
      searchCriteria;
    getExpectedRecordCount({
      drawnPolygons,
      bounds: currentBounds,
      shapeId,
      identifier,
      fetchedSavedSearchTrigger,
      isMetric: isMetricOnSearch,
    });
    setShouldCallRecordCount(false);
  }, [
    shouldCallRecordCount,
    fetchedSavedSearchTrigger,
    searchCriteria.drawnPolygons,
    searchCriteria.currentBounds,
    searchCriteria.identifier,
    searchCriteria.shapeId,
  ]);

  return (
    <Box
      data-testid="grid-header-container"
      className={classNames("grid-header-section", {
        "full-screen-mode": isFullScreenGrid,
      })}
    >
      <div className="grid-analysis-button">
        <AnalysisChartToggle />
      </div>
      <div className="grid-count-container">
        <ResultCount
          title="FILTERED"
          value={
            !batchWellGridDataLoading && !gridFilteredLoading
              ? getNumberWithComma(gridFilteredCount)
              : "..."
          }
        />
        <Divider orientation="horizontal" />
        <SelectedForAnalysis searchRecordType={searchRecordType} />
      </div>

      <GridToolBarControls
        componentType={"grid"}
        dropdown={{
          export: {
            showMenu: false,
            onClickExportExcel: exportAsExcel,
          },
          filter: {
            onClickResetFilter: resetFilters,
          },
        }}
        searchRecordType={searchRecordType}
      />
    </Box>
  );
};

export default GridPlotHeader;
