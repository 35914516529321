import { useMemo } from "react";

import {
  SAVED_SEARCH_VISIBILITY_PRIVATE,
  SAVED_SEARCH_VISIBILITY_PUBLIC,
} from "../../constants/panels/savedSearchPanel/hooks";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useDataGridPermitsStore from "../../store/grid/permits/dataGridPermitsStore";
import useDataGridWellsStore from "../../store/grid/wells/dataGridWellsStore";
import useSaveSearchStore from "../../store/saveSearch/saveSearchStore";

import useSearchCriteria from "./useSearchCriteria";

const useSearchPanel = () => {
  const savedSearchData = useSaveSearchStore((state) => state.savedSearchData);

  const allWellGridData = useDataGridWellsStore((state) => state.allGridData);
  const allPermitGridData = useDataGridPermitsStore(
    (state) => state.allGridData
  );

  const { hasSearchCriteria: hasWellsSearchCriteria } = useSearchCriteria(
    RECORD_TYPES.WELL
  );

  const { hasSearchCriteria: hasPermitsSearchCriteria } = useSearchCriteria(
    RECORD_TYPES.PERMIT
  );

  const searchTitle = useMemo(() => {
    // Saving for Reference
    // const searchType = nonAutoSaveSearchType;
    // if (
    //   (savedSearchFileName &&
    //     searchTypeTab === ATTRIBUTES_TAB &&
    //     searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) ||
    //   (savedSearchFileName &&
    //     searchTypeTab === IDENTIFIER_TAB &&
    //     (searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH ||
    //       searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH ||
    //       searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH))
    // ) {
    //   return savedSearchFileName;
    // } else {
    //   return "SEARCH";
    // }
    if (savedSearchData?.searchName) {
      return savedSearchData?.searchName;
    }
    return "SEARCH";
  }, [savedSearchData?.searchName]);

  const disableSharedSearch = useMemo(
    () => !Boolean(allWellGridData.length),
    [allWellGridData]
  );

  //TODO: Update to Include Permits record
  const isFilterIncluded = useMemo(() => {
    const checkSearchTitle = Boolean(
      (savedSearchData &&
        savedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE &&
        searchTitle !== "SEARCH") ||
        (savedSearchData?.visibility === SAVED_SEARCH_VISIBILITY_PUBLIC &&
          searchTitle === "SEARCH")
    );

    // Saving old logic for reference
    // return !selectedSavedSearchData?.searchType || checkSearchTitle;
    return checkSearchTitle;
  }, [searchTitle, savedSearchData]);

  const hasSearchedRecords = useMemo(
    () => hasWellsSearchCriteria || hasPermitsSearchCriteria,
    [hasWellsSearchCriteria, hasPermitsSearchCriteria]
  );

  return {
    searchTitle,
    disableSharedSearch,
    isFilterIncluded,
    hasSearchedRecords,
  };
};

export default useSearchPanel;
