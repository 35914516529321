import { FC, useEffect, useMemo } from "react";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  SaveAs as SaveAsIcon,
  Save as SaveIcon,
  SaveOutlined as SaveOutlinedIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Fade, MenuItem, MenuProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Button } from "component-library";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
  bindHover,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { SaveSearchButtonProps } from "../../../../types/panels/common/saveSearchButton";

import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../../constants/panels/savedSearchPanel/hooks";

import useSaveSearchStore from "../../../../store/saveSearch/saveSearchStore";
import useStore from "../../../../store/useStore";

import useSavedSearchData from "../../../../customHooks/savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";

import { useSearchPanelStyles } from "../../styles";

// TODO: Needs Cleanup
const StyledMenu = styled((props: MenuProps) => (
  <HoverMenu
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    TransitionProps={{
      timeout: 350,
    }}
    TransitionComponent={Fade}
    {...props}
  />
))(({ theme }) => useSearchPanelStyles(theme));

const SaveSearchButton: FC<SaveSearchButtonProps> = ({
  disabled,
  closeAttributesPanel,
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "save-menu",
  });

  const savedSearchData = useSaveSearchStore((state) => state.savedSearchData);
  const toggleIsOpenSaveSearchDialog = useSaveSearchStore(
    (state) => state.toggleIsOpenSaveSearchDialog
  );
  const updateToastMessage = useStore((state) => state.updateToastMessage);

  // TODO: Update this to be dynamic (Check if updateIsQBUpdated is needed)
  // const searchStore = useSearchStore({
  //   searchRecordType: RECORD_TYPES.WELL,
  // });
  // const updateIsQBUpdated = searchStore((state) => state.updateIsQBUpdated);

  const showSaveDropdown = useMemo(
    () =>
      !!savedSearchData &&
      savedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE,
    [savedSearchData]
  );

  const {
    isLoading: updateSavedSearchLoading,
    data: updateSavedSearchData,
    error: updateSavedSearchError,
    updateSavedSearch,
  } = useSavedSearchData();

  const handleSaveClick = () => {
    closeAttributesPanel();
    updateSavedSearch();
    // updateIsQBUpdated(false);
    popupState.close();
  };

  const handleSearchSaveDialog = () => {
    closeAttributesPanel();

    if (
      !!savedSearchData &&
      savedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE
    ) {
      // TODO: Check if still needed this (might not be)
      // if yes, update it with updateSavedSearchData
      // updateSavedSearchFileName(selectedSavedSearchData.searchName);
      handleSaveClick();
    } else {
      toggleIsOpenSaveSearchDialog();
    }
  };

  const handleSearchSaveAsDialog = () => {
    closeAttributesPanel();
    toggleIsOpenSaveSearchDialog();
    popupState.close();
  };

  useEffect(() => {
    if (updateSavedSearchError || !updateSavedSearchData) return;
    updateToastMessage("Saved successfully");
  }, [updateSavedSearchData]);

  return (
    <>
      {showSaveDropdown ? (
        <>
          <LoadingButton
            {...bindHover(popupState)}
            disabled={updateSavedSearchLoading || disabled}
            startIcon={<SaveOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            className="save-loading-btn"
          >
            Save
          </LoadingButton>
          <StyledMenu {...bindMenu(popupState)}>
            <MenuItem onClick={handleSaveClick} disableRipple>
              <SaveIcon />
              Save
            </MenuItem>
            <MenuItem onClick={handleSearchSaveAsDialog} disableRipple>
              <SaveAsIcon />
              Save As
            </MenuItem>
          </StyledMenu>
        </>
      ) : (
        <Button
          text="Save"
          iconLeft="save"
          state={disabled ? "disabled" : "enabled"}
          type="tertiary"
          onClick={() => handleSearchSaveDialog()}
        />
      )}
    </>
  );
};

export default SaveSearchButton;
