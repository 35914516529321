import { useCallback } from "react";

import {
  SaveSearchCriteria,
  SavedFileUploadRequest,
} from "../../../types/panels/savedSearchPanel/savedSearchData";
import { SearchTypes } from "../../../types/panels/searchPanel/search";

import { FILE_UPLOAD_FOLDER } from "../../../constants/common/fileUploadMetadata";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useStore from "../../../store/useStore";

import { filterRequestFormat } from "../../../utils/datagrid";

import { getGeometryFromPolygonArray } from "../../../data/map/mapUtils";
import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useSearchStore from "../../search/useSearchStore";

const useSavedSearchExportData = () => {
  const authUser = useStore((state) => state.authUser);

  // TEMP: temporarily set to well
  // TODO: Update this to be dynamic
  const searchStore = useSearchStore({ searchRecordType: RECORD_TYPES.WELL });
  const currentBounds = searchStore((state) => state.currentBounds);
  const selectedIdentifier = searchStore((state) => state.selectedIdentifier);
  const identifierSearchPastedValueList = searchStore(
    (state) => state.identifierSearchPastedValueList
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  // END TEMP: temporarily set to well

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  // TEMP: temporarily set to well
  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: RECORD_TYPES.WELL,
  });
  const filterModel = dataGridRecordStore((state) => state.filterModel);

  const savedSearchExportData = useCallback(
    (searchType: SearchTypes) => {
      const searchExportData: {
        searchCriteria?: SaveSearchCriteria;
        fileUploadRequest?: SavedFileUploadRequest;
      } = {};

      if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        searchExportData.searchCriteria = {};

        if (currentBounds.length) {
          searchExportData.searchCriteria.bounds = currentBounds;
        }

        const geometry = getGeometryFromPolygonArray(drawnPolygons);
        if (geometry) {
          searchExportData.searchCriteria.geometry = geometry;
        }
        if (shapeId) {
          searchExportData.searchCriteria.shapeId = shapeId;
        }

        //TODO::second parameter, to change to dynamic searchRecordType
        const filters = filterRequestFormat(filterModel, RECORD_TYPES.WELL);
        if (filters.length) {
          searchExportData.searchCriteria.filters = filters;
        }
      } else {
        if (searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH) {
          searchExportData.searchCriteria = {};

          if (identifierSearchPastedValueList.length) {
            searchExportData.searchCriteria.identifier = {
              type: selectedIdentifier,
              identifiers: identifierSearchPastedValueList,
              fileId: "",
            };
          }

          //TODO::second parameter, to change to dynamic searchRecordType
          const filters = filterRequestFormat(filterModel, RECORD_TYPES.WELL);
          if (filters.length) {
            searchExportData.searchCriteria.filters = filters;
          }
        } else if (
          searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH ||
          searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH
        ) {
          searchExportData.fileUploadRequest = {
            fileName: identifierSearchUploadedFile?.name ?? "",
            folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
          };
        }
      }

      return searchExportData;
    },
    [
      currentBounds,
      drawnPolygons,
      shapeId,
      identifierSearchPastedValueList,
      selectedIdentifier,
      identifierSearchUploadedFile,
      authUser,
      filterModel,
    ]
  );

  return { savedSearchExportData };
};

export default useSavedSearchExportData;
