import { Attribute } from "../../../types/common/attributes";
import { Feature } from "../../../types/map/layers/carto";
import {
  RecordStylingDataSegment,
  RecordStylingInfo,
} from "../../../types/map/mapSettings/store";
import { RecordStylingData } from "../../../types/map/mapSettings/useRecordSpotStyling";

import {
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
} from "../../../constants/constants";
import { DEFAULT_BUBBLE_MAP_SIZES_IN_PIXELS } from "../../../constants/map/mapSettings";

const scale = (
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) => {
  const result =
    ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

  if (result < outMin) {
    return outMin;
  } else if (result > outMax) {
    return outMax;
  }

  return result;
};

const getFeatureData = (
  feature: Feature,
  wellSpotAttribValues: RecordStylingDataSegment | undefined,
  layerName: string
) => {
  const { ParentWellID, BottomWellboreID } = feature.properties;

  let wellId: number | undefined = 0;
  if (layerName === DYNAMIC_WELL_SPOTS) {
    wellId = ParentWellID ?? 0;
  } else if (layerName === DYNAMIC_BOTTOM_WELL_SPOTS) {
    wellId = BottomWellboreID ?? 0;
  }

  if (!wellSpotAttribValues?.[wellId] && wellSpotAttribValues?.[wellId] !== 0)
    return null;

  return wellSpotAttribValues[wellId];
};

export const getSize = (
  featureData: any,
  min: number | null = null,
  max: number | null = null,
  defaultSize = 0
) => {
  if (!featureData || min === null || max === null) return defaultSize;
  const sizes = DEFAULT_BUBBLE_MAP_SIZES_IN_PIXELS;

  return scale(
    featureData,
    min === max ? 0 : min,
    max,
    sizes[0],
    sizes[sizes.length - 1]
  );
};

export const getSizeByAttribute = (
  sizeByAttribute: Attribute,
  compressedBubbleMapData: RecordStylingData,
  compressedBubbleMapInfo: RecordStylingInfo,
  feature: Feature,
  layerName: string,
  defaultSize: number
) => {
  if (!sizeByAttribute || !compressedBubbleMapData?.length) return defaultSize;

  const { data, min, max } = compressedBubbleMapInfo;
  const featureData = getFeatureData(feature, data, layerName);

  return getSize(featureData, min, max, defaultSize);
};
