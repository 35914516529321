import { useCallback, useState } from "react";

import { GetTopAttributesResponse } from "../../types/map/mapSettings/useTopAttributeValues";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { CUM_BOE } from "../../constants/attributes";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useChartSearchRequest from "./useChartSearchRequest";

export const useChartTopAttribute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<GetTopAttributesResponse | undefined>(
    undefined
  );
  const {
    getFileIdReferenceByRecordType,
    getFileReferenceByRecordType,
    getIdentifierFileRetry,
  } = useSearchRequest();

  const { buildChartRequestByRecordType } = useChartSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getChartTopAttributesSortBy = (recordType: RecordType) => {
    if (recordType === RECORD_TYPES.PERMIT) {
      return "PermitID";
    }
    return CUM_BOE.key;
  };

  const getChartTopAttributes = useCallback(
    async (attribute: string, selectedRecordType: RecordType) => {
      setIsLoading(true);

      try {
        setIsError(false);
        const body = {
          ...buildChartRequestByRecordType(selectedRecordType),
          ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
        };
        const sortBy = getChartTopAttributesSortBy(selectedRecordType);
        const topAttributesUtl = `${config.endpoints.wellService}api/records/top/${attribute}/by/${sortBy}`;

        const response = await callServiceAPI<GetTopAttributesResponse>(
          topAttributesUtl,
          body ?? {},
          getIdentifierFileRetry(selectedRecordType),
          getFileReferenceByRecordType(selectedRecordType),
          !!getFileIdReferenceByRecordType(selectedRecordType)
        );

        if (!response || !("data" in response)) return;

        setData(response.data);
      } catch (e) {
        setIsError(true);
        setData([]);
        console.log("getChartData error", e);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [
      getFileIdReferenceByRecordType,
      getFileReferenceByRecordType,
      getIdentifierFileRetry,
    ]
  );
  return {
    isLoading,
    data,
    isError,
    getChartTopAttributes,
  };
};
