import * as attribute from "../../../constants/attributes";

export const permitsColumnVisibilityList = {
  __check__: true,
  UWI: true,
  [attribute.PERMIT_NUMBER.key]: true,
  [attribute.PERMIT_APPROVAL_DATE.key]: true,
  [attribute.COUNTRY.key]: true,
  [attribute.STATE_NAME.key]: true,
  [attribute.COUNTY.key]: true,
  [attribute.BASIN_NAME.key]: true,
  [attribute.FIELD_NAME.key]: true,
  [attribute.WELL_NAME.key]: true,
  [attribute.WELL_NUMBER.key]: true,
  [attribute.ULTIMATE_OWNER.key]: true,
  [attribute.WELL_TYPE.key]: true,
  [attribute.PERMIT_TYPE.key]: true,
  [attribute.WELL_STATUS.key]: true,
  [attribute.PERMIT_TOTAL_DEPTH.key]: true,
  ProducingFormation: true,
  [attribute.PERMIT_FORMATION_DEPTH.key]: true,
  [attribute.SLANT.key]: true,
  SurfaceLatitudeWGS84: true,
  SurfaceLongitudeWGS84: true,
  [attribute.OPERATOR_NAME.key]: false,
  BottomLatitudeWGS84: false,
  BottomLongitudeWGS84: false,
  [attribute.OPERATOR_CONTACT.key]: false,
  [attribute.OPERATOR_PHONE_NUMBER.key]: false,
  [attribute.OPERATOR_ADDRESS.key]: false,
  [attribute.OPERATOR_CITY.key]: false,
  [attribute.OPERATOR_STATE.key]: false,
  [attribute.OPERATOR_ZIP_CODE.key]: false,
};
