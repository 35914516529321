import {
  GetAllSavedSearchesResponse,
  SavedSearchInfo,
} from "../../types/panels/savedSearchPanel/savedSearchData";

import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../constants/panels/savedSearchPanel/hooks";
import { SEARCH_TYPES } from "../../constants/panels/searchPanel/search";

import { formatCurrentDateTime } from "../../utils";
import useSavedSearchData from "../savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";
import useSearchPanel from "../search/useSearchPanel";
import useSearchPanelActions from "../search/useSearchPanelActions";

const useSaveExportSearch = () => {
  const { searchTitle } = useSearchPanel();
  const { saveCallback } = useSearchPanelActions();

  const isFilterIncluded = searchTitle !== "SEARCH";

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchError,
    createSavedSearch,
  } = useSavedSearchData();

  // Note: Temporarily set to attribute search
  const savedSearchName =
    SEARCH_TYPES.ATTRIBUTE_SEARCH + " " + formatCurrentDateTime();

  const getExportSaveSearch = (
    currentSavedSearch?: GetAllSavedSearchesResponse,
    loadedSearchName?: string
  ) => {
    if (!loadedSearchName) {
      createSavedSearch(
        savedSearchName,
        isFilterIncluded,
        true,
        SAVED_SEARCH_VISIBILITY_PRIVATE,
        saveCallback
      );
    } else {
      let modifyCurrentSearchName: string;
      let checkSearchIteration: string[];
      let setSearchIteration = 0;

      if (loadedSearchName.includes("_")) {
        checkSearchIteration = loadedSearchName.split("_");
        currentSavedSearch?.forEach((search: SavedSearchInfo) => {
          if (search.searchName.includes(checkSearchIteration[0])) {
            setSearchIteration = setSearchIteration + 1;
          }
        });
        modifyCurrentSearchName =
          checkSearchIteration[0] + "_" + setSearchIteration;
      } else {
        currentSavedSearch?.forEach((search: SavedSearchInfo) => {
          if (search.searchName.includes(loadedSearchName)) {
            setSearchIteration = setSearchIteration + 1;
          }
        });
        modifyCurrentSearchName = loadedSearchName + "_" + setSearchIteration;
      }
      createSavedSearch(
        modifyCurrentSearchName,
        isFilterIncluded,
        true,
        SAVED_SEARCH_VISIBILITY_PRIVATE,
        saveCallback
      );
    }
  };
  return {
    getExportSaveSearch,
    savedSearchDataLoading,
    savedSearchData,
    savedSearchError,
  };
};

export default useSaveExportSearch;
