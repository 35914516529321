import { useCallback, useMemo } from "react";

import { CHART_TYPES } from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useChartStore from "../../store/chart/chartStore";
import useModularityStore from "../../store/modularity/modularityStore";

export const useChartModule = () => {
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataRefresh = useModularityStore(
    (state) => state.updateModuleDataRefresh
  );
  const chartData = useChartStore((state) => state.chartData);

  const scatterPlotChartIdList = useMemo(
    () =>
      modules
        .filter((module) => module.chartType === CHART_TYPES.SCATTER_PLOT)
        .map((filtered) => filtered.module),
    [modules]
  );

  const handleWellChartUpdate = useCallback(() => {
    const chartIdList = modules
      .filter(
        (module) =>
          module.chartType === CHART_TYPES.PRODUCTION_PLOT ||
          module.chartType === CHART_TYPES.TYPE_CURVE ||
          module.chartType === CHART_TYPES.ASSET_ECONOMICS ||
          module.chartType === CHART_TYPES.CASH_FLOW
      )
      .map((filtered) => filtered.module);

    const wellScatterPlotChartIdList = chartData
      .filter(
        (chart) =>
          chart.objectType === CHART_TYPES.SCATTER_PLOT &&
          chart.chartType === CHART_TYPES.SCATTER_PLOT &&
          chart.recordType === RECORD_TYPES.WELL &&
          scatterPlotChartIdList.includes(chart.chartId)
      )
      .map((filtered) => filtered.chartId);

    if (chartIdList.length || wellScatterPlotChartIdList.length)
      updateModuleDataRefresh([...chartIdList, ...wellScatterPlotChartIdList]);
  }, [modules, chartData, scatterPlotChartIdList]);

  const handlePermitChartUpdate = useCallback(() => {
    const permitScatterPlotChartIdList = chartData
      .filter(
        (chart) =>
          chart.objectType === CHART_TYPES.SCATTER_PLOT &&
          chart.chartType === CHART_TYPES.SCATTER_PLOT &&
          chart.recordType === RECORD_TYPES.PERMIT &&
          scatterPlotChartIdList.includes(chart.chartId)
      )
      .map((filtered) => filtered.chartId);

    if (permitScatterPlotChartIdList.length)
      updateModuleDataRefresh(permitScatterPlotChartIdList);
  }, [modules, chartData, scatterPlotChartIdList]);

  return { handleWellChartUpdate, handlePermitChartUpdate };
};
