import { useCallback, useEffect, useRef, useState } from "react";

import { SearchRequestPayload } from "../../types/common/api";
import { setTimeoutType, useRefType } from "../../types/common/general";
import {
  BaseSearchCriteria,
  SearchWellDataPayload,
  SortBySelected,
} from "../../types/common/search";
import { RecordType } from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/search";

import useMapStore from "../../store/map/mapStore";
import useStore from "../../store/useStore";

import { getColumnsModelByRecordType } from "../../utils/datagrid/getRecordSpecificConstants";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useIdentifierFileUpload from "../search/identifier/useIdentifierFileUpload";
import useSearchCriteria from "../search/useSearchCriteria";
import useSearchStore from "../search/useSearchStore";
import usePrevious from "../usePrevious";
import useDataGridRecordStore from "./useDataGridRecordStore";
import useFetchFirstBatch from "./useFetchFirstBatch";
import { useGridColumn } from "./useGridColumn";
import useRecordSelectionStore from "./useRecordSelectionStore";
import { useSelectedRecordByRecordType } from "./useSelectedRecordByRecordType";

interface UseAllRecordData {
  searchRecordType: RecordType;
}

const useAllRecordData = ({ searchRecordType }: UseAllRecordData) => {
  const [error, setError] = useState("");
  const { getRequiredColumns, getColumnsPayloadData } =
    useGridColumn(searchRecordType);

  const map = useMapStore((state) => state.map);
  const updateDVTProcessing = useStore((state) => state.updateDVTProcessing);

  const searchStore = useSearchStore({ searchRecordType });
  const searchCriteria = searchStore((state) => state.searchCriteria);
  const isSearchedRecordType = searchStore(
    (state) => state.isSearchedRecordType
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);
  const { hasSearchCriteria } = useSearchCriteria(searchRecordType);

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType,
  });
  const updateAllWellGridDataLoading = dataGridRecordStore(
    (state) => state.updateAllWellGridDataLoading
  );
  const updateAllWellGridDataSuccess = dataGridRecordStore(
    (state) => state.updateAllWellGridDataSuccess
  );
  const updateAllWellGridDataError = dataGridRecordStore(
    (state) => state.updateAllWellGridDataError
  );
  const updateGridFilteredLoading = dataGridRecordStore(
    (state) => state.updateGridFilteredLoading
  );
  const updateGridTotalCount = dataGridRecordStore(
    (state) => state.updateGridTotalCount
  );
  const updateGridFilteredCount = dataGridRecordStore(
    (state) => state.updateGridFilteredCount
  );
  const updateBatchWellGridDataSuccess = dataGridRecordStore(
    (state) => state.updateBatchWellGridDataSuccess
  );
  const updateBatchWellGridDataLoading = dataGridRecordStore(
    (state) => state.updateBatchWellGridDataLoading
  );
  const sortPayload = dataGridRecordStore((state) => state.sortPayload);
  const sortByAnalysis = dataGridRecordStore((state) => state.sortByAnalysis);

  const recordSelectionStore = useRecordSelectionStore({
    searchRecordType,
  });
  const selectedIdsKeys = recordSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedIdsKeys = recordSelectionStore(
    (state) => state.deselectedIdsKeys
  );

  const { fetchFirstBatch } = useFetchFirstBatch(
    getIdentifierFileIdWithRetry,
    identifierSearchUploadedFile,
    Boolean(searchCriteria.identifier.fileId),
    searchRecordType
  );

  const { isDeselectedIds } = useSelectedRecordByRecordType({
    searchRecordType,
  });
  const { buildSearchRequestByParam } = useSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getAllWellData = useCallback(
    async ({
      drawnPolygons = [],
      currentBounds = [],
      identifier,
      shapeId = "",
      filters = [],
    }: SearchWellDataPayload) => {
      // Loading States
      updateDVTProcessing(true);
      setError("");
      updateAllWellGridDataLoading(true);
      updateBatchWellGridDataLoading(true);
      if (filters?.length) updateGridFilteredLoading(true);

      // Success States
      updateAllWellGridDataSuccess(false);
      updateBatchWellGridDataSuccess(false);

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds,
        identifier,
        filters,
      };

      const searchRequestBody = buildSearchRequestByParam({
        pSearchCriteria: requestBodySearchCriteria,
      });

      const body: SearchRequestPayload = {
        ...searchRequestBody,
        offset: 0,
        pageLimit: 1,
        recordType: searchRecordType,
        ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
      };

      if (sortPayload?.length) {
        body.sort = sortPayload;
      }

      // Grid Columns
      const columns = getColumnsPayloadData();
      if (columns?.length) {
        const finalColumnsPayload = getRequiredColumns(columns);
        body.columns = finalColumnsPayload;
        //remove else condition if the ff are all included in default columns
        // Ultimate Owner
        // First Month
        // TotalVerticalDepth
        // InterpretedProducingFormation
        // CumBOE
      } else {
        const columnsModel = getColumnsModelByRecordType(searchRecordType);
        const columnsWithWellHover = getRequiredColumns(columnsModel);
        body.columns = columnsWithWellHover;
      }

      if (sortByAnalysis) {
        const payloadKey =
          searchRecordType === RECORD_TYPES.WELL ? "wellIds" : "permitIds";
        const sortBySelectedRequest: SortBySelected = {
          prioritizeSelected: !isDeselectedIds,
          [payloadKey]: isDeselectedIds ? deselectedIdsKeys : selectedIdsKeys,
        };

        body.sortBySelected = sortBySelectedRequest;
      }

      const totalCountUrl = `${config.endpoints.wellService}api/records/count`;

      try {
        // Need to get totalCount first for query
        const initialResponse: any = await callServiceAPI(
          totalCountUrl,
          body,
          getIdentifierFileIdWithRetry,
          identifierSearchUploadedFile,
          Boolean(identifier.fileId)
        );

        const totalCount = initialResponse.data.totalCount;
        const pageLimit = config.searchPageLimit;
        const totalIterations = Math.ceil(totalCount / pageLimit);

        if (filters?.length) {
          updateGridFilteredLoading(false);
        } else {
          updateGridTotalCount(totalCount);
        }
        updateGridFilteredCount(totalCount);

        // call first batch
        // will include dynamic columns every first call
        const getfetchFirstBatchProps = {
          body: {
            ...body,
            offset: 0,
            pageLimit,
            recordType: searchRecordType,
          },
          pageLimit,
          totalIterations,
        };
        fetchFirstBatch(getfetchFirstBatchProps);
      } catch (error: any) {
        console.debug("getAllWellData error", error);
        setError(error);
        updateAllWellGridDataError(error);
        updateGridFilteredLoading(false);
        updateBatchWellGridDataLoading(false);
      }
    },
    [
      getIdentifierFileIdWithRetry,
      fetchFirstBatch,
      updateBatchWellGridDataSuccess,
      updateAllWellGridDataError,
      updateAllWellGridDataLoading,
      updateAllWellGridDataSuccess,
      updateBatchWellGridDataLoading,
      updateDVTProcessing,
      updateGridFilteredCount,
      updateGridFilteredLoading,
      updateGridTotalCount,
      identifierSearchUploadedFile,
    ]
  );

  const getAllWellDataTimeout: useRefType<setTimeoutType> = useRef();
  const debouncedGetAllWellData = useCallback(() => {
    clearTimeout(getAllWellDataTimeout.current);
    getAllWellDataTimeout.current = setTimeout(() => {
      getAllWellData(searchCriteria);
    }, 200);
  }, [searchCriteria, getAllWellData]);
  const prevSearchCriteria = usePrevious(searchCriteria);

  useEffect(() => {
    if (!map || !isSearchedRecordType) return;
    if (hasSearchCriteria && searchCriteria !== prevSearchCriteria) {
      debouncedGetAllWellData();
    }
  }, [
    map,
    isSearchedRecordType,
    hasSearchCriteria,
    searchCriteria,
    prevSearchCriteria,
    debouncedGetAllWellData,
  ]);

  return {
    error,
    getAllWellData,
  };
};

export default useAllRecordData;
