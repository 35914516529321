import { FC, useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { GridSlotsComponentsProps } from "@mui/x-data-grid-premium";

import { RecordType } from "../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridRecordStore from "../../../customHooks/grid/useDataGridRecordStore";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    currentRows: number;
    gridFilteredCount: number;
    searchRecordType: RecordType;
  }
}

const Footer: FC = (props: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  const zeroPaginationText = "0 out of 0";
  const [paginationText, setPaginationText] = useState(zeroPaginationText);

  const dataGridRecordStore = useDataGridRecordStore({
    searchRecordType: props.searchRecordType ?? RECORD_TYPES.WELL,
  });

  const batchRecordGridDataLoading = dataGridRecordStore(
    (state) => state.batchWellGridDataLoading
  );
  const batchRecordGridDataSuccess = dataGridRecordStore(
    (state) => state.batchWellGridDataSuccess
  );
  const gridRecordDataTrigger = dataGridRecordStore(
    (state) => state.gridRecordDataTrigger
  );

  useEffect(() => {
    if (batchRecordGridDataLoading) {
      if (["searchPanel", "savedSearch"].includes(gridRecordDataTrigger)) {
        setPaginationText(zeroPaginationText);
      } else {
        setPaginationText(
          `${props.currentRows} out of ${props.gridFilteredCount}`
        );
      }
    } else {
      if (batchRecordGridDataSuccess) {
        setPaginationText(
          `${props.currentRows} out of ${props.gridFilteredCount}`
        );
      } else {
        setPaginationText(zeroPaginationText);
      }
    }
  }, [gridRecordDataTrigger, batchRecordGridDataLoading, props]);

  return (
    <Typography variant="h6" className="grid-custom-footer">
      {paginationText}
    </Typography>
  );
};

export default Footer;
