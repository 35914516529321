import {
  SaveIdentifierSearch,
  SavedAttributeSearch,
  SavedAttributeSort,
  SavedUWIFileUpload,
} from "../../../types/panels/savedSearchPanel/savedSearchData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../store/grid/dataGridStore";

import "../../../utils/common/boundsData";

import useIdentifierFileUpload from "../../search/identifier/useIdentifierFileUpload";
import useSearchStore from "../../search/useSearchStore";

const useLoadSearches = (searchRecordType: RecordType = RECORD_TYPES.WELL) => {
  const searchStore = useSearchStore({ searchRecordType });
  const updateCurrentBounds = searchStore((state) => state.updateCurrentBounds);
  const loadQBInfo = searchStore((state) => state.loadQBInfo);
  const updateIsLoadingSavedSearchFileUpload = searchStore(
    (state) => state.updateIsLoadingSavedSearchFileUpload
  );
  const updateIdentifierSearchUploadedFile = searchStore(
    (state) => state.updateIdentifierSearchUploadedFile
  );
  const updateIdentifierSearchUploadedFileId = searchStore(
    (state) => state.updateIdentifierSearchUploadedFileId
  );
  const loadSavedIdentifiers = searchStore(
    (state) => state.loadSavedIdentifiers
  );
  const { getIdentifierFileIdWithRetry } =
    useIdentifierFileUpload(searchRecordType);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const loadAttributeSearch = (
    attributeSearch?: SavedAttributeSearch,
    sortList?: SavedAttributeSort
  ) => {
    const bounds = attributeSearch?.bounds ?? [];
    loadQBInfo(bounds, sortList);
    updateCurrentBounds(bounds);
    return { currentBounds: bounds };
  };

  const loadUWISearch = (
    identifiers: SaveIdentifierSearch["searchIdentifiers"]
  ) => {
    const searchUWIState: { identifiers: string[] } = {
      identifiers: [],
    };
    if (!identifiers.length) return searchUWIState;

    loadSavedIdentifiers(identifiers);
    searchUWIState.identifiers = identifiers;
    return searchUWIState;
  };

  const loadUWIFileUpload = async (
    uwiFileUpload?: SavedUWIFileUpload,
    identifiers?: SaveIdentifierSearch["searchIdentifiers"]
  ) => {
    if (!uwiFileUpload) return { fileId: "" };
    if (identifiers) loadSavedIdentifiers(identifiers, true);
    updateIsLoadingSavedSearchFileUpload(true);
    updateInitialGridSearchMade(true);
    toggleGrid(true);
    toggleGridHeader(true);
    try {
      const fileId = await getIdentifierFileIdWithRetry(
        uwiFileUpload?.fileName
      );
      updateIdentifierSearchUploadedFile({ name: uwiFileUpload?.fileName });
      updateIdentifierSearchUploadedFileId(fileId);
      updateIsLoadingSavedSearchFileUpload(false);
      return { fileId: fileId ?? "" };
    } catch (error) {
      console.error(error);
      return { fileId: "" };
    }
  };

  return {
    loadAttributeSearch,
    loadUWISearch,
    loadUWIFileUpload,
  };
};

export default useLoadSearches;
