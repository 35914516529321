import { useMemo } from "react";

import { ScatterPlotFormList } from "../../../types/charts/chartHooks/scatterPlotData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import {
  SCATTER_PLOT_PERMIT_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES,
} from "../../../constants/charts/scatterPlotDataFields";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

export const useScatterPlotConfig = (recordType: RecordType) => {
  const formDropdownList = useMemo(() => {
    const dropdownList: ScatterPlotFormList = {
      colorByAttributes: [],
      xAttributes: [],
      yAttributes: [],
    };

    switch (recordType) {
      case RECORD_TYPES.WELL:
        dropdownList.colorByAttributes = SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES;
        dropdownList.xAttributes = SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES;
        dropdownList.yAttributes = SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES;
        break;
      case RECORD_TYPES.PERMIT:
        dropdownList.colorByAttributes =
          SCATTER_PLOT_PERMIT_COLOR_BY_ATTRIBUTES;
        dropdownList.xAttributes = SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES;
        dropdownList.yAttributes = SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES;
        break;
      default:
        dropdownList.colorByAttributes = SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES;
        dropdownList.xAttributes = SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES;
        dropdownList.yAttributes = SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES;
        break;
    }

    return dropdownList;
  }, [recordType]);

  return { formDropdownList };
};
