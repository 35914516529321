import { FC } from "react";

import { Typography } from "@mui/material";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";

import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";

import useMergedActionButtons from "../../../customHooks/search/mergedByRecordType/useMergedActionButtons";
import useSearchPanel from "../../../customHooks/search/useSearchPanel";

import SaveSearchAttributesDialog from "../../modals/saveSearch/SaveSearchAttributesDialog";
import PanelDrawer from "../common/PanelDrawer";
import SearchPermitsAccordion from "./SearchPermitsAccordion";
import SearchWellsAccordion from "./SearchWellsAccordion";
import PolygonSearch from "./attributes/polygons/PolygonSearch";
import ActionButtons from "./common/ActionButtons";

const SearchPanel: FC<PanelDrawerProps> = ({ open, handleCloseMenuClick }) => {
  const openedQBEditorPanel = useSearchPanelStore(
    (state) => state.openedQBEditorPanel
  );

  const { searchTitle, isFilterIncluded } = useSearchPanel();

  const actionButtonProps = useMergedActionButtons();

  return (
    <PanelDrawer
      open={open}
      className="search-panel"
      hidePanelControl={!!openedQBEditorPanel}
      handleCloseMenuClick={handleCloseMenuClick}
    >
      <div className="drawer-container">
        <div className="sub-header">
          <Typography variant="h6" className="header-name">
            {searchTitle}
          </Typography>
        </div>
        <div className="search-panel-accordion-section">
          <SearchWellsAccordion />
          <SearchPermitsAccordion />
        </div>
        <PolygonSearch />
        <div className="search-panel-footer">
          <ActionButtons {...actionButtonProps} />
        </div>
      </div>
      <SaveSearchAttributesDialog isFilterIncluded={isFilterIncluded} />
    </PanelDrawer>
  );
};
export default SearchPanel;
