import { StateCreator } from "zustand";

import { SaveSearchSlice } from "../../types/panels/savedSearchPanel/store";

const sliceResetFns = new Set<() => void>();

const resetSaveSearchSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialSaveSearchStates = {
  savedSearchData: null,
  fetchedSavedSearchTrigger: false,
  isOpenSaveSearchDialog: false,
};

const saveSearchSlice: StateCreator<SaveSearchSlice> = (set) => {
  sliceResetFns.add(() => set(initialSaveSearchStates));

  return {
    ...initialSaveSearchStates,

    updateSavedSearchData: (savedSearchData) =>
      set(() => ({ savedSearchData })),

    updateFetchedSavedSearchTrigger: (fetchedSavedSearchTrigger) =>
      set((state) => ({ fetchedSavedSearchTrigger })),

    toggleIsOpenSaveSearchDialog: () =>
      set((state) => ({
        isOpenSaveSearchDialog: !state.isOpenSaveSearchDialog,
      })),
  };
};

export { resetSaveSearchSlice, saveSearchSlice };
