import { useEffect, useState } from "react";

import { isEqual } from "lodash";

import { ScatterPlotColorByProps } from "../../../types/charts/chartHooks/scatterPlotData";
import {
  ChartAxisDataFields,
  ChartColorByInfo,
  IScatterPlotChartData,
  ITraceData,
} from "../../../types/charts/chartType/chartType";
import { ColorRangeFieldsToggle } from "../../../types/charts/chartTypeSettings/chartTypeSettings";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { SPECIAL, TEXT } from "../../../constants/attributes";
import {
  allOthersKey,
  maxGroup,
  minGroup,
} from "../../../constants/charts/scatterPlotDataFields";

import { clone } from "../../../utils/helper";

import usePrevious from "../../usePrevious";
import { useChartTopAttribute } from "../useChartTopAttributes";
import { useScatterPlotChartData } from "../useScatterPlotChartData";
import { useScatterPlotColorByChartData } from "../useScatterPlotColorByChartData";

export const useColorBy = (
  chartData: IScatterPlotChartData,
  selectedRecordType: RecordType
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [xAxisDataField, setXAxisDataField] = useState<
    ChartAxisDataFields | undefined
  >(undefined);
  const [yAxisDataField, setYAxisDataField] = useState<ChartAxisDataFields[]>(
    []
  );
  const [chartColorBy, setChartColorBy] = useState<
    ChartColorByInfo | undefined
  >(undefined);
  const [colorByTrace, setColorByTrace] = useState<ITraceData[]>([]);
  const [colorRangeFieldsToggle, setColorRangeFieldsToggle] =
    useState<ColorRangeFieldsToggle>();
  const { getChartData, data } = useScatterPlotChartData();
  const { createNumericOrDateTrace, partitions, createVarCharTrace } =
    useScatterPlotColorByChartData();

  const { getChartTopAttributes, data: topAttributes } = useChartTopAttribute();
  const prevSelectedRecordType = usePrevious(selectedRecordType);

  const shouldShowFields = (data: ITraceData[]) => {
    const hasPoints = Boolean(
      data.filter((trace) => trace.x.length && trace.y.length).length
    );
    if (hasPoints) {
      const showAllOthers = Boolean(
        data.filter(
          (trace) =>
            trace.name === allOthersKey && trace.x.length && trace.y.length
        ).length
      );
      const showMin = Boolean(
        data.filter(
          (trace) =>
            trace.legendgroup === minGroup && trace.x.length && trace.y.length
        ).length
      );
      const showMax = Boolean(
        data.filter(
          (trace) =>
            trace.legendgroup === maxGroup && trace.x.length && trace.y.length
        ).length
      );

      return {
        showAllOthers,
        showMax,
        showMin,
      };
    } else {
      return {
        showAllOthers: false,
        showMax: false,
        showMin: false,
      };
    }
  };

  useEffect(() => {
    if (chartData.chartData.length && chartData.chartColorBy?.toggle) {
      const fieldToggle = shouldShowFields(chartData.chartData);
      setColorRangeFieldsToggle(fieldToggle);
    } else {
      setColorRangeFieldsToggle({
        showAllOthers: false,
        showMax: false,
        showMin: false,
      });
    }
  }, [chartData.chartData, chartData.chartColorBy]);

  useEffect(() => {
    //reset showing of color range fields on change of record type
    if (
      !isEqual(selectedRecordType, prevSelectedRecordType) &&
      prevSelectedRecordType
    ) {
      setColorRangeFieldsToggle({
        showAllOthers: false,
        showMax: false,
        showMin: false,
      });
    }
  }, [selectedRecordType]);

  useEffect(() => {
    setIsLoading(false);
  }, [colorByTrace]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (
      data?.length &&
      chartColorBy &&
      (chartColorBy.attibuteDataType === SPECIAL ||
        chartColorBy.attibuteDataType === TEXT) &&
      chartColorBy.attributeKey &&
      topAttributes
    ) {
      const chartDataProps: ScatterPlotColorByProps = {
        data,
        xAxisField: xAxisDataField,
        yAxisFields: yAxisDataField,
        chartColorByInfo: chartColorBy,
      };
      const trace = createVarCharTrace(topAttributes, chartDataProps);
      setColorByTrace(trace);
    }
  }, [topAttributes]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.length && chartColorBy) {
      if (
        (chartColorBy.attibuteDataType === SPECIAL ||
          chartColorBy.attibuteDataType === TEXT) &&
        chartColorBy.attributeKey
      ) {
        getChartTopAttributes(chartColorBy.attributeKey, selectedRecordType);
        return;
      }
      const chartDataProps: ScatterPlotColorByProps = {
        data,
        xAxisField: xAxisDataField,
        yAxisFields: yAxisDataField,
        chartColorByInfo: chartColorBy,
      };

      const trace = createNumericOrDateTrace(chartDataProps);
      const fieldToggle = shouldShowFields(trace);

      setColorRangeFieldsToggle(fieldToggle);
      setColorByTrace(trace);
    } else {
      setColorByTrace([]);
      setIsLoading(false);
    }
  }, [data]);

  const fetchScatterChartData = (
    chartColorByInfo: ChartColorByInfo,
    selectedYAxisDataField: ChartAxisDataFields[],
    selectedXAxisDataField?: ChartAxisDataFields
  ) => {
    //fetch scatter plot data
    setXAxisDataField(selectedXAxisDataField);
    setYAxisDataField(selectedYAxisDataField);
    setChartColorBy(chartColorByInfo);
    const getChartDataParam: IScatterPlotChartData = clone(chartData);
    getChartDataParam.recordType = selectedRecordType;
    getChartDataParam.chartColorBy = chartColorByInfo;
    getChartDataParam.chartXAxisDataFields = selectedXAxisDataField;
    getChartDataParam.chartYAxisDataFields = selectedYAxisDataField;

    setIsLoading(true);
    getChartData(getChartDataParam);
  };

  return {
    fetchScatterChartData,
    isLoading,
    partitions,
    colorRangeFieldsToggle,
    setColorByTrace,
    colorByTrace,
    data,
    topAttributes,
  };
};
