import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Typography, styled } from "@mui/material";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";
import { SavedSearchDeleteState } from "../../../types/panels/savedSearchPanel/component";
import { GetAllSavedSearchesResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { ShareLinkAlertsProps } from "../../../types/shareLink/shareLink";

import { SAVED_SEARCH_DELETE_STATE_ACTIONS } from "../../../constants/panels/savedSearchPanel/component";
import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../constants/panels/savedSearchPanel/hooks";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapStore from "../../../store/map/mapStore";
import useSaveSearchStore from "../../../store/saveSearch/saveSearchStore";
import useStore from "../../../store/useStore";

import useMap from "../../../customHooks/map/useMap";
import useGetSavedSearch from "../../../customHooks/savedSearch/getSavedSearch/useGetSavedSearch";
import useGetAllSavedSearches from "../../../customHooks/savedSearch/useGetAllSavedSearches";
import useSavedSearchStateActions from "../../../customHooks/savedSearch/useSavedSearchStateActions";

import Loading from "../../common/Loading";
import ShareLinkAlerts from "../../modals/saveSearch/ShareLinkAlerts";
import PanelDrawer from "../common/PanelDrawer";
import SavedSearchList from "./SavedSearchList";

const SavedSearchContent = styled("div")(({ height }: { height: string }) => ({
  height,
}));

const SavedSearchPanel: FC<PanelDrawerProps> = ({
  open,
  handleCloseMenuClick,
}) => {
  const [shareAlert, setShareAlert] = useState<ShareLinkAlertsProps>();
  const map = useMapStore((state) => state.map);

  const showGrid = useDataGridStore((state) => state.showGrid);
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );

  const updateFetchedSavedSearchTrigger = useSaveSearchStore(
    (state) => state.updateFetchedSavedSearchTrigger
  );

  const [savedSearchList, setSavedSearchList] =
    useState<GetAllSavedSearchesResponse>([]);

  const { shareSearchId } = useParams();
  const toggleEnableZoomFit = useStore((state) => state.toggleEnableZoomFit);
  const authUser = useStore((state) => state.authUser);

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchDataError,
    getSavedSearch,
  } = useGetSavedSearch();

  const {
    isLoading: savedSearchListLoading,
    data: savedSearchListData,
    error: savedSearchListError,
    getAllSavedSearches,
  } = useGetAllSavedSearches();

  const {
    savedSearchDeleteState,
    savedSearchDeleteStateDispatch,
    handleDeleteClick,
    handleCancelClick,
    deletingCallback,
    deletedCallback,
  } = useSavedSearchStateActions();

  const { hasMap } = useMap();

  const handleDeleteSavedSearch = (deletedSavedId: number) => {
    setSavedSearchList((prev) => [
      ...prev.filter((savedSearch) => savedSearch.id !== deletedSavedId),
    ]);

    deletedCallback(deletedSavedId);
  };

  useEffect(() => {
    if (open) {
      savedSearchDeleteStateDispatch({
        type: SAVED_SEARCH_DELETE_STATE_ACTIONS.CLEAR,
      });
      getAllSavedSearches();
    }
  }, [open]);

  useEffect(() => {
    if (
      savedSearchListLoading ||
      !savedSearchListData ||
      savedSearchListError
    ) {
      return;
    }
    setSavedSearchList(savedSearchListData);

    // generate default states for every saved search item
    const newSavedSearchState: SavedSearchDeleteState = {};
    // TODO: Remove the type after converting the save search hook into ts
    savedSearchListData.forEach((item) => {
      newSavedSearchState[item.id] = {
        onDeleteMode: false,
        isDeleting: false,
      };
    });
    savedSearchDeleteStateDispatch({
      type: SAVED_SEARCH_DELETE_STATE_ACTIONS.SET,
      payload: {
        savedSearchDeleteState: newSavedSearchState,
      },
    });
  }, [savedSearchListLoading, savedSearchListData]);

  useEffect(() => {
    if (!savedSearchDataLoading && !savedSearchDataError && savedSearchData) {
      if (savedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE) {
        // TODO: Check if still needed this (might not be)
        // if yes, update it with updateSavedSearchData
        // updateSavedSearchFileName(savedSearchData.searchName);
      }

      toggleEnableZoomFit(true);
      updateFetchedSavedSearchTrigger(false);
    }
  }, [
    savedSearchDataLoading,
    savedSearchData,
    savedSearchDataError,
    // updateSavedSearchFileName,
    toggleEnableZoomFit,
    updateFetchedSavedSearchTrigger,
  ]);

  useEffect(() => {
    if (savedSearchDataError?.errorCode === 500) {
      setShareAlert({
        open: true,
        message: "The link you are trying to access has expired.",
        actions: [
          {
            text: "Close",
            onClick: () => setShareAlert(undefined),
          },
        ],
      });
    }
  }, [savedSearchDataError]);

  useEffect(() => {
    if (shareSearchId && authUser && hasMap) {
      getSavedSearch(shareSearchId);
      updateFetchedSavedSearchTrigger(true);
    }
  }, [shareSearchId, authUser, hasMap]);

  const getContentHeight: string = useMemo(() => {
    let height = "calc(100vh - 128px)";

    if (isExpandGridWidth) {
      if (showGrid) {
        height = `calc(100vh - (${gridHeight} + 128px))`;
      } else if (!showGrid && showGridHeader) {
        height = "calc(100vh - 210px)";
      }
    }

    return height;
  }, [gridHeight, showGrid, showGridHeader, isExpandGridWidth]);

  return (
    <PanelDrawer
      open={open}
      // keepMounted is true, but hide all save search items items when drawer is closed
      // in SavedSearchList
      keepMounted
      className="saved-search-panel"
      handleCloseMenuClick={handleCloseMenuClick}
    >
      <div className="drawer-container">
        <div className="sub-header">
          <Typography variant="h5">MY SAVED SEARCHES</Typography>
        </div>
        <SavedSearchContent
          className="saved-search-content"
          height={getContentHeight}
        >
          {savedSearchListLoading ? (
            <div className="d-flex justify-content-center py-5">
              <Loading />
            </div>
          ) : (
            <SavedSearchList
              isPanelOpen={open}
              savedSearchList={savedSearchList}
              savedSearchDeleteState={savedSearchDeleteState}
              getSavedSearch={getSavedSearch}
              handleDeleteClick={handleDeleteClick}
              handleCancelClick={handleCancelClick}
              deletingCallback={deletingCallback}
              deletedCallback={handleDeleteSavedSearch}
            />
          )}
        </SavedSearchContent>

        {shareAlert && <ShareLinkAlerts {...shareAlert} />}
      </div>
    </PanelDrawer>
  );
};
export default SavedSearchPanel;
