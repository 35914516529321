import { AttributeSortOptions } from "../../../../types/panels/searchPanel/queryBuilder/sortPerAttribute";

export const ATTRIBUTE_ALPHA_ASC = "alphaASC";
export const ATTRIBUTE_ALPHA_DESC = "alphaDESC";
export const ATTRIBUTE_RECORD_COUNT_ASC = "recordcountASC";
export const ATTRIBUTE_RECORD_COUNT_DESC = "recordcountDESC";

export const ATTRIBUTE_SORT_OPTIONS: AttributeSortOptions = {
  [ATTRIBUTE_ALPHA_ASC]: {
    type: "alpha",
    order: "ASC",
    text: "Sorted by Alpha (A-Z)",
    nextSort: ATTRIBUTE_RECORD_COUNT_DESC,
  },
  [ATTRIBUTE_ALPHA_DESC]: {
    type: "alpha",
    order: "DESC",
    text: "Sorted by Alpha (Z-A)",
    nextSort: ATTRIBUTE_RECORD_COUNT_ASC,
  },
  [ATTRIBUTE_RECORD_COUNT_ASC]: {
    type: "record-count",
    order: "ASC",
    text: "Sorted by Count (Smallest - Largest)",
    nextSort: ATTRIBUTE_ALPHA_ASC,
  },
  [ATTRIBUTE_RECORD_COUNT_DESC]: {
    type: "record-count",
    order: "DESC",
    text: "Sorted by Count (Largest - Smallest)",
    nextSort: ATTRIBUTE_ALPHA_DESC,
  },
};
