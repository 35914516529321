import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { Typography } from "@mui/material";

import { DashboardChartState } from "../../../types/charts/dashboardChartType/dashboardChartType";
import { StackedAreaChartProps } from "../../../types/charts/dashboardChartType/stackedAreaChart";

import {
  CHART_DATA_STATE,
  YYYYTickFormat,
  mmmYYYYTickFormat,
} from "../../../constants/charts/charts";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useSaveSearchStore from "../../../store/saveSearch/saveSearchStore";
import useSearchWellsStore from "../../../store/search/wells/searchWellsStore";
import useWellSelectionStore from "../../../store/selection/wells/wellSelectionStore";

import { useStackedAreaChartData } from "../../../customHooks/charts/useStackedAreaChartData";
import { useSelectedRecordByRecordType } from "../../../customHooks/grid/useSelectedRecordByRecordType";
import useSearchPanel from "../../../customHooks/search/useSearchPanel";
import usePrevious from "../../../customHooks/usePrevious";

import { differenceInMonths } from "../../../utils/charts/dateHelper";
import {
  defaultConfig,
  fontLayout,
  generalLayout,
  hoverLabelLayout,
  legendLayout,
  marginLayout,
  xAxisFont,
  xAxisLayout,
  yAxisLayout,
  yAxisTitle,
} from "../../../utils/charts/layouts/StackedAreaLayout";

import { getLargestSignificantNumber, transformToTick } from "../../../utils";
import LoadingBackDrop from "../../common/LoadingBackDrop";

const StackedAreaChart = ({
  isDefaultChart,
  chartData,
  title,
  defaultXAxisLabel,
  defaultYAxisLabel,
}: StackedAreaChartProps) => {
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);
  const [shouldFormatXTicks, setShouldFormatXTicks] = useState(false);

  const { formattedChartData, data, getChartData, isLoading } =
    useStackedAreaChartData();

  const selectedWellIdsKeys = useWellSelectionStore(
    (state) => state.selectedIdsKeys
  );
  const deselectedWellIdsKeys = useWellSelectionStore(
    (state) => state.deselectedIdsKeys
  );
  const { isDeselectedIds: isDeselectedWellRecords } =
    useSelectedRecordByRecordType({
      searchRecordType: RECORD_TYPES.WELL,
    });

  const fetchedSavedSearchTrigger = useSaveSearchStore(
    (state) => state.fetchedSavedSearchTrigger
  );
  const isLoadingSavedSearchFileUpload = useSearchWellsStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );
  const searchCriteria = useSearchWellsStore((state) => state.searchCriteria);
  const { hasSearchedRecords } = useSearchPanel();

  const prevDeselectedWellIdsKeys = usePrevious(deselectedWellIdsKeys);
  const prevChartDataGroupBy = usePrevious(chartData.groupBy);

  const fetchChartData = async (state: DashboardChartState) => {
    await getChartData({
      chartDataState: state,
      chartType: chartData.chartType,
    });
  };

  useEffect(() => {
    if (
      !chartData ||
      fetchedSavedSearchTrigger ||
      (isDeselectedWellRecords &&
        (!deselectedWellIdsKeys.length ||
          deselectedWellIdsKeys === prevDeselectedWellIdsKeys) &&
        chartData.groupBy === prevChartDataGroupBy) ||
      (!isDeselectedWellRecords && deselectedWellIdsKeys.length)
    ) {
      return;
    }

    if (selectedWellIdsKeys.length) {
      fetchChartData(CHART_DATA_STATE.POST_SEARCH);
    } else {
      if (hasSearchedRecords) {
        fetchChartData(CHART_DATA_STATE.INTER_SEARCH);
      } else {
        fetchChartData(CHART_DATA_STATE.PRE_SEARCH);
      }
    }
  }, [
    chartData,
    chartData.groupBy,
    JSON.stringify(selectedWellIdsKeys),
    JSON.stringify(deselectedWellIdsKeys),
    searchCriteria,
    hasSearchedRecords,
    fetchedSavedSearchTrigger,
  ]);

  useEffect(() => {
    if (!formattedChartData?.length) {
      setCurrentTickData([-Infinity, -Infinity, -Infinity]);
      setShouldFormatXTicks(false);
      return;
    }

    try {
      const dataByMonth: { [key: string]: number } = {};
      const minmaxValueForXTicks: { [key: string]: number } = {};
      formattedChartData.forEach((data) => {
        minmaxValueForXTicks[data.name] = differenceInMonths(
          new Date(data.x[0]),
          new Date(data.x[data.x.length - 1])
        );
        data.x.forEach((date, index) => {
          if (!dataByMonth[date]) {
            dataByMonth[date] = data.y[index];
          } else {
            dataByMonth[date] += data.y[index];
          }
        });
      });

      //For Y Ticks
      const largestNumber = Math.round(Math.max(...Object.values(dataByMonth)));
      const largestSignificantNumber =
        getLargestSignificantNumber(largestNumber);

      const newYData = [];
      const tickDivider = 5;

      const initialTickVal = Math.round(largestSignificantNumber / tickDivider);
      let currentTickVal = initialTickVal;

      for (let i = 0; i < 7; i++) {
        newYData.push(currentTickVal);
        currentTickVal += initialTickVal;
      }

      setCurrentTickData(newYData);

      //For X Ticks
      setShouldFormatXTicks(
        !(
          Object.values(minmaxValueForXTicks).filter((val) => val > 5).length >
          0
        )
      );
    } catch (error) {
      console.debug(error);
    }
  }, [formattedChartData]);

  return (
    <div className="dashboard-chart-main">
      <>
        <div className="chart-header">
          <Typography fontSize="12px" className="chart-title">
            {title ? title : "Bar Chart"}
          </Typography>
        </div>
        <div className="chart-container">
          {!isLoading &&
          chartData !== undefined &&
          !isLoadingSavedSearchFileUpload ? (
            <Plot
              className="plot-chart"
              config={defaultConfig}
              useResizeHandler
              data={formattedChartData}
              layout={{
                ...generalLayout,
                legend: legendLayout,
                hoverlabel: hoverLabelLayout,
                margin: marginLayout,
                font: fontLayout,
                xaxis: {
                  ...xAxisLayout,
                  title: {
                    text: defaultXAxisLabel,
                    ...(data?.length !== 0 && {
                      standoff: 8,
                    }),
                    font: xAxisFont,
                  },
                  ...(data?.length === 0 && {
                    tickmode: "array",
                    tickvals: currentTickData,
                    ticktext: currentTickData?.map((tickData) =>
                      transformToTick(tickData)
                    ),
                  }),
                  ...(shouldFormatXTicks
                    ? {
                        tickformat: mmmYYYYTickFormat,
                        dtick: "M1",
                        tickangle: 45,
                      }
                    : { tickformat: YYYYTickFormat }),
                },
                yaxis: {
                  ...yAxisLayout,
                  tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
                  tickvals: currentTickData,
                  ticktext: currentTickData?.map((tickData) =>
                    transformToTick(tickData)
                  ),
                  title: {
                    ...yAxisTitle,
                    text: defaultYAxisLabel,
                  },
                },
              }}
            />
          ) : (
            <LoadingBackDrop className={"chart-container loader"} isOpen />
          )}
        </div>
      </>
    </div>
  );
};

export default StackedAreaChart;
