import { useCallback, useMemo } from "react";

import { SaveSearchCriteriaData } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { FILE_UPLOAD_FOLDER } from "../../../constants/common/fileUploadMetadata";
import { CREATE_SAVED_SEARCH } from "../../../constants/panels/savedSearchPanel/hooks";
import {
  RECORD_TYPES,
  SEARCH_TYPES,
} from "../../../constants/panels/searchPanel/search";

import useStore from "../../../store/useStore";

import { defaultFilterModel, defaultSortModel } from "../../../utils/datagrid";
import { getSortPerAttribute } from "../../../utils/savedSearch/savedData";

import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useSearchTypeByRecordType from "../../search/common/useSearchTypeByRecordType";
import useSearchStore from "../../search/useSearchStore";

const useSaveRecordConfig = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const authUser = useStore((state) => state.authUser);

  const searchStore = useSearchStore({ searchRecordType });
  const currentBounds = searchStore((state) => state.currentBounds);
  const currentQBInfo = searchStore((state) => state.currentQBInfo);
  const identifierSearchPastedValueText = searchStore(
    (state) => state.identifierSearchPastedValueText
  );
  const identifierSearchUploadedFile = searchStore(
    (state) => state.identifierSearchUploadedFile
  );
  const updateIdentifierFileTimeStamp = searchStore(
    (state) => state.updateIdentifierFileTimeStamp
  );
  const isSearchAccordionToggledOn = searchStore(
    (state) => state.isSearchAccordionToggledOn
  );

  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const sortModel = dataGridRecordStore((state) => state.sortModel);
  const filterModel = dataGridRecordStore((state) => state.filterModel);
  const columnVisibilityModel = dataGridRecordStore(
    (state) => state.columnVisibilityModel
  );
  const columnsOrder = dataGridRecordStore((state) => state.columnsOrder);

  const { getSearchTypeByTab } = useSearchTypeByRecordType(searchRecordType);

  const sortPerAttribute = useMemo(
    () => getSortPerAttribute(currentQBInfo),
    [currentQBInfo]
  );

  const getRecordSearchData = useCallback(() => {
    const searchType = getSearchTypeByTab();
    let searchState: SaveSearchCriteriaData = {
      searchType,
      isToggleOn: isSearchAccordionToggledOn,
    };
    if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
      searchState = {
        ...searchState,
        attributeSearch: { bounds: currentBounds },
        ...(Object.keys(sortPerAttribute).length ? { sortPerAttribute } : {}),
      };
    } else {
      if (
        searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH ||
        searchType === SEARCH_TYPES.IDENTIFIER_PASTED_SEARCH
      ) {
        const uwiList = identifierSearchPastedValueText.split("\n");
        searchState = {
          ...searchState,
          identifierSearch: { searchIdentifiers: uwiList },
        };
      }
      if (
        searchType === SEARCH_TYPES.IDENTIFIER_CONVERTED_FILE_SEARCH ||
        searchType === SEARCH_TYPES.IDENTIFIER_FILE_SEARCH
      ) {
        searchState = {
          ...searchState,
          fileUpload: {
            fileName: identifierSearchUploadedFile?.name ?? "",
            folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
          },
        };
      }
      updateIdentifierFileTimeStamp(null);
    }
    return {
      searchState,
    };
  }, [
    authUser,
    currentBounds,
    identifierSearchUploadedFile,
    identifierSearchPastedValueText,
    sortPerAttribute,
    isSearchAccordionToggledOn,
    updateIdentifierFileTimeStamp,
    getSearchTypeByTab,
  ]);

  const gridConfig = useCallback(
    (action: string, hasLoadedAndUpdatedSearch: boolean) => {
      // TODO: Check if hasLoadedAndUpdatedSearch is still necessary or
      // need to use a different condition
      if (action !== CREATE_SAVED_SEARCH || !hasLoadedAndUpdatedSearch) {
        return {};
      }

      // Do not include the filter and sort when
      // loaded a saved search and updated values on the other tab panel
      // then clicking save w/o searching
      return { sortModel: defaultSortModel, filterModel: defaultFilterModel };
    },
    []
  );

  const getRecordGridConfigData = useCallback(
    (action: string, hasLoadedAndUpdatedSearch: boolean) => {
      return {
        columnsModel: columnVisibilityModel,
        columnsOrder,
        sortModel,
        filterModel,
        ...gridConfig(action, hasLoadedAndUpdatedSearch),
      };
    },
    [columnVisibilityModel, columnsOrder, sortModel, filterModel, gridConfig]
  );

  return { getRecordSearchData, getRecordGridConfigData };
};

export default useSaveRecordConfig;
