import { FC, useEffect, useMemo, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { isEqual } from "lodash";

import {
  IAssetEconomicsData,
  ICashFlowChartData,
} from "../../../../types/charts/chartType/chartType";
import { RecordCounts } from "../../../../types/common/records";

import { WELL_ECONOMICS_FILTER_BY_ATTRIBUTE } from "../../../../constants/charts/economics";

import useChartStore from "../../../../store/chart/chartStore";

import useFilterByConfig from "../../../../customHooks/charts/useFilterByConfig";
import usePrevious from "../../../../customHooks/usePrevious";

import Loading from "../../../common/Loading";

interface AutocompleteSelectorProps {
  chartData: IAssetEconomicsData | ICashFlowChartData;
  attribute: string;
  error?: boolean;
  selectedValues: RecordCounts[];
  onSelectedValues: (values: RecordCounts[]) => void;
}

const AutocompleteSelector: FC<AutocompleteSelectorProps> = ({
  chartData,
  attribute,
  error = false,
  selectedValues,
  onSelectedValues,
}) => {
  const [options, setOptions] = useState<RecordCounts[]>([]);
  const chartFilterByAttributeOptions = useChartStore(
    (state) => state.chartFilterByAttributeOptions[chartData.chartId]
  );
  const updateChartFilterByAttributeOptionsById = useChartStore(
    (state) => state.updateChartFilterByAttributeOptionsById
  );

  const {
    wellAttributes,
    wellCountAttributSuccess,
    isWellCountAttributesLoading,
    getWellCountByAttribute,
  } = useFilterByConfig();

  const prevAttribute = usePrevious(attribute);

  const inputLabel = useMemo(() => {
    const filteredAttribute = WELL_ECONOMICS_FILTER_BY_ATTRIBUTE.find(
      (attr) => attr.name === attribute
    );

    return filteredAttribute ? filteredAttribute.displayName : attribute;
  }, [attribute]);

  const handleOnChange = (value: RecordCounts[]) => {
    onSelectedValues(value);
  };

  useEffect(() => {
    if (wellCountAttributSuccess && !isWellCountAttributesLoading) {
      setOptions(wellAttributes);

      updateChartFilterByAttributeOptionsById(
        chartData.chartId,
        attribute,
        wellAttributes
      );
    }
  }, [wellAttributes, wellCountAttributSuccess, isWellCountAttributesLoading]);

  useEffect(() => {
    if (attribute !== "" && !isEqual(prevAttribute, attribute)) {
      if (
        chartFilterByAttributeOptions &&
        chartFilterByAttributeOptions[attribute]
      ) {
        setOptions(chartFilterByAttributeOptions[attribute]);
      } else {
        getWellCountByAttribute(attribute);
      }
    }
  }, [
    attribute,
    chartFilterByAttributeOptions,
    chartFilterByAttributeOptions?.[attribute],
    chartData.filterBy,
  ]);

  return (
    <Autocomplete
      multiple={true}
      options={options}
      value={selectedValues}
      loading={isWellCountAttributesLoading}
      getOptionLabel={(option) => (option as RecordCounts).attributeValue ?? ""}
      isOptionEqualToValue={(option, value) =>
        (option as RecordCounts).attributeValue ===
        (value as RecordCounts).attributeValue
      }
      onChange={(e, value) => handleOnChange(value)}
      fullWidth
      size="small"
      ChipProps={{ className: "qb-chip selected" }}
      renderInput={(params) => (
        <TextField
          {...params}
          className="autocomplete-list-config"
          error={error}
          helperText={error ? "Select Values" : ""}
          label={inputLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isWellCountAttributesLoading ? <Loading size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <div className="autocomplete-list">
            <span>{(option as RecordCounts).attributeValue}</span>
          </div>
        </li>
      )}
      noOptionsText={isWellCountAttributesLoading ? "Loading ..." : ""}
    />
  );
};

export default AutocompleteSelector;
