import { GridFilterModel } from "@mui/x-data-grid-premium";

import { SavedGridConfiguration } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { RecordType } from "../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import { fetchColumnsList } from "../../../utils/common/fetchColumnsList";
import {
  changeColumnFilterAccordingToType,
  defaultColumnsOrder,
  defaultFilterModel,
  defaultSortModel,
  filterRequestFormat,
  getColumnKeyNames,
  sortRequestFormat,
} from "../../../utils/datagrid";

import useDataGridRecordStore from "../../grid/useDataGridRecordStore";
import useSavedSearchDataReMapping from "./useSavedSearchDataReMapping";

const useLoadRecordGridConfigs = (
  searchRecordType: RecordType = RECORD_TYPES.WELL
) => {
  const dataGridRecordStore = useDataGridRecordStore({ searchRecordType });
  const columnsFilterType = dataGridRecordStore(
    (state) => state.columnsFilterType
  );
  const columnVisibilityModel = dataGridRecordStore(
    (state) => state.columnVisibilityModel
  );
  const updateFilterModel = dataGridRecordStore(
    (state) => state.updateFilterModel
  );
  const updateSortModel = dataGridRecordStore((state) => state.updateSortModel);
  const updateColumnsOrder = dataGridRecordStore(
    (state) => state.updateColumnsOrder
  );
  const updateAllColumnsFilterType = dataGridRecordStore(
    (state) => state.updateAllColumnsFilterType
  );
  const updateColumnVisibilityModel = dataGridRecordStore(
    (state) => state.updateColumnVisibilityModel
  );
  const updateColumnsPayload = dataGridRecordStore(
    (state) => state.updateColumnsPayload
  );

  const { mapSavedGridConfig, mapSavedFilterModel } =
    useSavedSearchDataReMapping();

  const loadGridConfigs = (gridConfig?: SavedGridConfiguration) => {
    if (!gridConfig) return {};

    const { filterModel, sortModel, columnsModel, columnsOrder } =
      mapSavedGridConfig(gridConfig);

    let newFilterModel = filterModel?.items?.length
      ? filterModel
      : defaultFilterModel;

    //check if filterModel contains object types from the old MUI Datagrid Version (v5)
    //if true, change the outdated keys ... (refer: https://mui.com/x/migration/migration-data-grid-v5/#filtering)
    newFilterModel.items = newFilterModel.items.map((val) => {
      if ("columnField" in val && "operatorValue" in val) {
        return {
          id: val.id,
          field: val.columnField,
          operator: val.operatorValue,
          value: val.value,
        };
      } else {
        return val;
      }
    });

    // TODO: Remove "as GridFilterModel"
    newFilterModel = mapSavedFilterModel(newFilterModel as GridFilterModel);
    updateFilterModel(newFilterModel);

    //update columns filter type
    const newColumnsFilterType = changeColumnFilterAccordingToType(
      filterModel as GridFilterModel,
      columnsFilterType
    );
    updateAllColumnsFilterType(newColumnsFilterType);

    const newColumnsOrder = columnsOrder?.length
      ? columnsOrder
      : defaultColumnsOrder;

    const missingColumns = defaultColumnsOrder.filter(
      (e) => !newColumnsOrder.includes(e)
    );

    if (missingColumns.length) {
      const mergedColumns = newColumnsOrder.concat(missingColumns);
      updateColumnsOrder(mergedColumns);
    } else {
      updateColumnsOrder(newColumnsOrder);
    }

    const newSortModel = sortModel?.length ? sortModel : defaultSortModel;
    updateSortModel(newSortModel);

    const newColumnModel = columnsModel ?? columnVisibilityModel;
    const columnsList = fetchColumnsList(columnVisibilityModel, newColumnModel);

    updateColumnVisibilityModel(columnsList);
    updateColumnsPayload(getColumnKeyNames(newColumnModel));

    return {
      filters: filterRequestFormat(
        newFilterModel as GridFilterModel,
        searchRecordType
      ),
      sort: sortRequestFormat(newSortModel),
      columns: getColumnKeyNames(newColumnModel),
    };
  };

  return { loadGridConfigs };
};

export default useLoadRecordGridConfigs;
