import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { GetSavedSearch } from "../../../types/panels/savedSearchPanel/hooks";
import { GetSaveSearchDataResponse } from "../../../types/panels/savedSearchPanel/loadSearchData";
import { GetSaveSearchDataResponseLatest } from "../../../types/panels/savedSearchPanel/savedSearchData";

import config from "../../../configs/appSettings";

import { SIDEBAR_MENU_KEYS } from "../../../constants/panels/menu";
import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../constants/panels/savedSearchPanel/hooks";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../store/grid/dataGridStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useSaveSearchStore from "../../../store/saveSearch/saveSearchStore";
import useSearchPanelStore from "../../../store/search/panel/useSearchPanelStore";
import useStore from "../../../store/useStore";

import { transformOldToNewSaveSearches } from "../../../utils/savedSearch/transformOldToNew";

import useSavedSearchHelper from "../useSavedSearchHelper";
import useLoadConfigs from "./useLoadConfigs";
import useLoadRecordConfigs from "./useLoadRecordConfigs";
import useLoadShapesConfigs from "./useLoadShapesConfigs";

const useGetSavedSearch = () => {
  const authUser = useStore((state) => state.authUser);
  const updateSavedSearchData = useSaveSearchStore(
    (state) => state.updateSavedSearchData
  );

  const updateOpenedSearchAccordion = useSearchPanelStore(
    (state) => state.updateOpenedSearchAccordion
  );

  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const {
    loadModulesConfigs,
    loadMapConfigs,
    loadDashboardChartConfigs,
    loadUnitOfMeasure,
  } = useLoadConfigs();

  const { loadShapes } = useLoadShapesConfigs();
  const { loadConfigByRecordType: loadWellsRecordConfig } =
    useLoadRecordConfigs(RECORD_TYPES.WELL);
  const { loadConfigByRecordType: loadPermitsRecordConfig } =
    useLoadRecordConfigs(RECORD_TYPES.PERMIT);

  const { error, setError, catchError } = useSavedSearchHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetSaveSearchDataResponseLatest | null>(
    null
  );

  const updateLastRunDate: GetSavedSearch = (id, searchName, searchData) => {
    const body = {
      userId: `${authUser.uid}`,
      searchName,
      searchData,
      lastRunTime: "true",
    };

    axios
      .post(`${config.endpoints.wellService}api/searches/update/${id}`, body, {
        params: {
          applicationName: "Saga-Analytics",
        },
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const getSavedSearch = async (id: string) => {
    try {
      setIsLoading(true);
      setData(null);
      setError(null);

      const { data } = await axios.get<GetSaveSearchDataResponse>(
        `${config.endpoints.wellService}api/searches/${id}`
      );
      const formattedData = transformOldToNewSaveSearches(data);
      const { id: searchId, searchName, searchData } = formattedData;

      if (data.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE) {
        updateLastRunDate(searchId, searchName, searchData);
      }

      updateInitialGridSearchMade(true);
      toggleGrid(true);
      toggleGridHeader(true);

      if (searchData.recordConfiguration.wells) {
        updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
      } else if (searchData.recordConfiguration.permits) {
        updateActivePanel(SIDEBAR_MENU_KEYS.SEARCH);
      }

      const shapesData = loadShapes(searchData.shapesConfiguration);
      loadWellsRecordConfig(searchData.recordConfiguration.wells, shapesData);
      loadPermitsRecordConfig(
        searchData.recordConfiguration.permits,
        shapesData
      );

      if (searchData.recordConfiguration.wells.isToggleOn) {
        updateOpenedSearchAccordion(RECORD_TYPES.WELL);
      } else {
        updateOpenedSearchAccordion(RECORD_TYPES.PERMIT);
      }

      loadUnitOfMeasure(searchData.unitOfMeasureConfiguration);
      loadMapConfigs(searchData.mapConfiguration);
      loadModulesConfigs(searchData.modulesConfiguration);
      loadDashboardChartConfigs(searchData.dashboardChartConfiguration);

      setData(formattedData);
      updateSavedSearchData(formattedData);
    } catch (error) {
      catchError(error as AxiosError<APIErrorResponse>);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    updateLastRunDate,
    getSavedSearch,
  };
};

export default useGetSavedSearch;
