import { create } from "zustand";

import { SaveSearchSlice } from "../../types/panels/savedSearchPanel/store";

import { saveSearchSlice } from "./saveSearchSlice";

const useSaveSearchStore = create<SaveSearchSlice>()((...a) => ({
  ...saveSearchSlice(...a),
}));

export default useSaveSearchStore;
