import { GridSortModel } from "@mui/x-data-grid-premium";

import { SortPayload } from "../../types/grid";

import { changeKey } from "./columnKey";

export const sortRequestFormat = (newSortModel: GridSortModel) => {
  const sortPayload: SortPayload[] = [];

  newSortModel.forEach((sort) => {
    sortPayload.push({
      columnName: changeKey(sort.field),
      order: sort?.sort?.toUpperCase() ?? "ASC",
    });
  });

  return sortPayload;
};
